/**

    * Application Name: Zevcore unolo Backend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 17.05.2023
    * Last Modified Date: 17.05.2023
    * Developer Name: Anand_Krishna

**/

import React from "react";
import { notifications } from "@mantine/notifications";
import { AlertCircle, Check, X } from "tabler-icons-react"; // For particular icons that has used in this page from tabler icon

export const notificationHelper = (data) => {
  if (data.color === "red") {
    notifications.show({
      color: data.color,
      title: data.title,
      position: data.position,
      message: data.message,
      icon: <X />,
    });
  } else if (data.color === "green") {
    notifications.show({
      color: data.color,
      position: data.position,
      title: data.title,
      message: data.message,
      icon: <Check />,
    });
  } else if (data.color === "yellow") {
    notifications.show({
      color: data.color,
      position: data.position,
      title: data.title,
      message: data.message,
      onClick: () => {
        localStorage.setItem("depletion", "1");
      },
      icon: <AlertCircle />,
    });
  }
};

export default notificationHelper;
