/**

    * Application Name: Zevcore unolo Backend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 17.05.2023
    * Last Modified Date: 17.05.2023
    * Developer Name: Anand_Krishna

**/

import { url } from "../../../services/url";
import axios from "axios";

export const userImage_Url = `${url.baseUrl}public/images/users/`;

// Token
const fetchCredentials = async () => {
  try {
    // Retrieve the credentials
    const credentials = await localStorage.getItem("token");
    if (credentials) {
      return {
        headers: {
          "x-access-token": credentials,
        },
      };
    } else {
      console.log("No credentials stored");
    }
  } catch (error) {
    console.log("Keychain couldn't be accessed!", error);
  }
  return false;
};

const profileUrl = {
  profile_Url: `${url.apiUrl}/user_one`,
  profile_Update_Url: `${url.apiUrl}/user`,
  profileUpload_Url: `${url.apiUrl}/user_upload`,
  adminprofileUpload_Url: `${url.apiUrl}/admin_upload`,
  adminprofileUpdate_Url: `${url.apiUrl}/adminProfile`,
};

// For handle get Location Type data list
export const handleGetOneUser = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(profileUrl.profile_Url, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit Location Type data list
export const handleEditUser = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      profileUrl.profile_Update_Url,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit Location Type data list
export const handleAdminProfileUpdate = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      profileUrl.adminprofileUpdate_Url,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle user image upload
export const handleUploadUserImage = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      profileUrl.profileUpload_Url,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle user image upload
export const handleUploadAdminImage = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      profileUrl.adminprofileUpload_Url,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
