/**

    * Application Name: Zevcore unolo Backend
    * Application Version: 1.0
    * Author: Zevcore Private LimitedßS
    * Created Date: 17.05.2023
    * Last Modified Date: 17.05.2023
    * Developer Name: Anand_Krishna

**/

// export const url = {
//   baseUrl: "http://192.168.29.114:3009/",
//   apiUrl: "http://192.168.29.114:3009/api",
// };

export const url = {
  baseUrl: "https://ztbk.zevcore.in/",
  apiUrl: "https://ztbk.zevcore.in/api",
};

export const centerLocation = {
  LATITUDE: 12.9716,
  LONGITUDE: 77.5946,
};

// Time Conversion
export function tConvert(time) {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
}
// Time Conversion

// Minutes to hours
export function toHoursAndMinutes(totalMinutes) {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return `${hours}h${minutes > 0 ? ` ${minutes}m` : ""}`;
}
// Minutes to hours
