/**

    * Application Name: Zevcore unolo Backend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 17.05.2023
    * Last Modified Date: 17.05.2023
    * Developer Name: Anand_Krishna

**/

import { url } from "../../../services/url";
import axios from "axios";

// Token
const config = {
  headers: {
    "x-access-token": localStorage.getItem("token"),
  },
};

const fetchCredentials = async () => {
  try {
    // Retrieve the credentials
    const credentials = await localStorage.getItem("token");
    if (credentials) {
      return {
        headers: {
          "x-access-token": credentials,
        },
      };
    } else {
      console.log("No credentials stored");
    }
  } catch (error) {
    console.log("Keychain couldn't be accessed!", error);
  }
  return false;
};

const siteUrl = {
  site_Url: `${url.apiUrl}/sites`,
  field_executive_Url: `${url.apiUrl}/field_executive`,
  fetch_manager_Url: `${url.apiUrl}/manager`,
  location_type_Url: `${url.apiUrl}/location_category`,
};

// For handle get Location Type data list
export const handleGetLocationType = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(siteUrl.location_type_Url, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle get Location Type data list
export const handleGetFieldExecutive = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(siteUrl.field_executive_Url, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle get Location Type data list
export const handleGetAllManager = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(siteUrl.fetch_manager_Url, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle invoice add
export const handleGetOneSite = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(siteUrl.site_Url + "/" + request, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle get Location Type data list
export const handleGetSite = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(siteUrl.site_Url, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add Location Type data list
export const handleAddSite = async (request) => {
  try {
    const config = await fetchCredentials();
    console.log(config);
    const response = await axios.post(siteUrl.site_Url, request, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit Location Type data list
export const handleEditSite = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(siteUrl.site_Url, request, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete Location Type data list
export const handleDeleteSite = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      siteUrl.site_Url + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
