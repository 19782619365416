import React, { useState, useEffect } from "react";
import {
  Skeleton,
  Card,
  Text,
  Grid,
  SimpleGrid,
  Title,
  ScrollArea,
  Group,
  Avatar,
  Anchor,
  Badge,
} from "@mantine/core"; //For import mantine required functions and theme
import { handleGetOneUser, meetingImageUrl } from "./liveTrackingApi";
import {
  DirectionsRenderer,
  GoogleMap,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import { centerLocation } from "../../../services/url";
import { useParams } from "react-router-dom";
import { Battery, Clock, FileDescription } from "tabler-icons-react";
import { imageModal } from "../../../services/common";

const containerStyle = {
  width: "100%",
  height: "766px",
};

const libraries = ["places", "drawing"];

// Time Conversion
function tConvert(time) {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
}
// Time Conversion

// Minutes to hours
function toHoursAndMinutes(totalMinutes) {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return `${hours}h${minutes > 0 ? ` ${minutes}m` : ""}`;
}
// Minutes to hours

function FieldExecutiveRoute() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBMWoaIEUJybmZFkVn7poh96I0d0XhNN4A",
    libraries,
  });
  var directionsDisplay, directionsService, map;
  // Mantine custome style use
  const [token, setToken] = useState(localStorage.getItem("token")); //get saved local storage data
  const [coordinates, setCoordinates] = useState(null);
  const [data, setData] = useState([]);
  const [meetings, setMeetings] = useState([]);
  const [wayPoints, setWayPoints] = useState([]);
  const [scrollPosition, onScrollPositionChange] = useState({ x: 0, y: 0 });
  const [travelArray, setTravelArray] = useState([]);
  const [distance, setDistance] = useState(0);
  const [username, setUsername] = useState("");
  const [loggedInTime, setLoggedInTime] = useState("");
  const [loggedOutTime, setLoggedOutTime] = useState("");
  const [status, setStatus] = useState("");
  const [initialLatitude, setInitialLatitude] = useState();
  const [initialLongitude, setInitialLongitude] = useState();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [opacity, setOpacity] = useState(0.7);
  const [refreshToken, setRefreshToken] = useState(new Date());
  const [duration, setDuration] = useState(0);
  const [active, setActive] = useState(1);
  const params = useParams();
  const [origin, setOrigin] = useState([]);
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  const currentDate = date + "-" + month + "-" + year;
  // Setting the variables data list here
  const [variables, setVariables] = useState({
    skeletonLoading: false,
    submitLoading: false,
    data: [],
    viewDrawer: false,
    deleteIndex: 0,
  });

  const center = {
    lat: initialLatitude ? initialLatitude : centerLocation.LATITUDE,
    lng: initialLongitude ? initialLongitude : centerLocation.LONGITUDE,
  };

  //   For initial setting data
  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      const config = {
        headers: {
          "x-access-token": token,
        },
      };
      const response = await handleGetOneUser(params.value);
      if (response.status === 200) {
        var coords = [];
        var waypts = [];
        setData(response.data.data[0]);
        const array_cords = response.data.data[0].meetings;
        setMeetings(array_cords);
        const mainArr = array_cords.map(
          ({ start_latitude, start_longitude }) => {
            return { lat: start_latitude, lng: start_longitude };
          }
        );
        const wayArr = array_cords.map(
          ({ start_latitude, start_longitude }) => {
            return {
              location: new google.maps.LatLng({
                lat: start_latitude,
                lng: start_longitude,
              }),
            };
          }
        );
        setInitialLatitude(mainArr[0].lat);
        setInitialLongitude(mainArr[0].lng);
        setCoordinates(mainArr);
        setWayPoints(mainArr);
        var coords = mainArr;
        var waypts = wayArr;
        setLoggedInTime(response.data.data[0].attendances[0].start_time);
        setLoggedOutTime(response.data.data[0].attendances[0].end_time);
        setStatus(response.data.data[0].attendances[0].status);
        setUsername(
          response.data.data[0].firstname + "" + response.data.data[0].lastname
        );
        setRefreshToken(new Date());
      }

      const directionsService = new google.maps.DirectionsService();
      new google.maps.DirectionsRenderer({
        suppressMarkers: true,
        polylineOptions: { strokeColor: "#5cb85c" },
      });
      const results = await directionsService.route({
        origin: coords[0],
        destination: coords[1],
        travelMode: google.maps.TravelMode.DRIVING,
        optimizeWaypoints: true,
        waypoints: waypts,
      });
      // console.log(results.routes[0]);
      setTravelArray(results.routes[0].legs);
      setCoordinates(results);
      setDistance(results.routes[0].legs[1].distance.text);
      setDuration(results.routes[0].legs[1].duration.text);
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  const handleActiveGrid = (e) => {
    setSelectedIndex(e);
    setOpacity(1);
  };

  return isLoaded ? (
    <>
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <Grid style={{ height: "480px" }} shadow="xl" radius="md">
          <Grid.Col
            md={4}
            lg={4}
            style={{
              backgroundColor: "#F0F0F0",
            }}
          >
            <Card
              style={{
                backgroundColor: "#FFFFFF",
                borderWidth: 1,
                borderColor: "#B197FC",
              }}
              withBorder
              shadow="md"
              my={20}
            >
              <SimpleGrid cols={2}>
                <Text
                  style={{ fontSize: 14, textAlign: "left", color: "#3d3d3d" }}
                >
                  User Name:
                </Text>
                <Text
                  style={{ fontSize: 14, textAlign: "left", color: "#3d3d3d" }}
                >
                  {username}
                </Text>
              </SimpleGrid>
              <SimpleGrid cols={2}>
                <Text
                  style={{ fontSize: 14, textAlign: "left", color: "#3d3d3d" }}
                >
                  Logged In Time:
                </Text>
                <Text
                  style={{ fontSize: 14, textAlign: "left", color: "#3d3d3d" }}
                >
                  {tConvert(loggedInTime).toString()}
                </Text>
              </SimpleGrid>
              <SimpleGrid cols={2}>
                <Text
                  style={{ fontSize: 14, textAlign: "left", color: "#3d3d3d" }}
                >
                  Logged Out Time:
                </Text>
                <Text
                  style={{ fontSize: 14, textAlign: "left", color: "#3d3d3d" }}
                >
                  {loggedOutTime !== null
                    ? tConvert(loggedOutTime).toString()
                    : "--"}
                </Text>
              </SimpleGrid>
              <SimpleGrid cols={2}>
                <Text
                  style={{ fontSize: 14, textAlign: "left", color: "#3d3d3d" }}
                >
                  Time Tracked:
                </Text>
                <Text
                  style={{ fontSize: 14, textAlign: "left", color: "#3d3d3d" }}
                >
                  {duration}
                </Text>
              </SimpleGrid>
              <SimpleGrid cols={2}>
                <Text
                  style={{ fontSize: 14, textAlign: "left", color: "#3d3d3d" }}
                >
                  Distance Covered:
                </Text>
                <Text
                  style={{ fontSize: 14, textAlign: "left", color: "#3d3d3d" }}
                >
                  {distance}
                </Text>
              </SimpleGrid>
              <SimpleGrid cols={2}>
                <Text
                  style={{ fontSize: 14, textAlign: "left", color: "#3d3d3d" }}
                >
                  Total Meetings:
                </Text>
                <Text
                  style={{ fontSize: 14, textAlign: "left", color: "#3d3d3d" }}
                >
                  {meetings.length}
                </Text>
              </SimpleGrid>
              <SimpleGrid cols={2}>
                <Text
                  style={{ fontSize: 14, textAlign: "left", color: "#3d3d3d" }}
                >
                  Meeting Date:
                </Text>
                <Text
                  style={{ fontSize: 14, textAlign: "left", color: "#3d3d3d" }}
                >
                  {meetings.length}
                </Text>
              </SimpleGrid>
              <SimpleGrid cols={2}>
                <Text
                  style={{ fontSize: 14, textAlign: "left", color: "#3d3d3d" }}
                >
                  Status:
                </Text>
                <Text
                  style={{ fontSize: 14, textAlign: "left", color: "#3d3d3d" }}
                >
                  {status === "Logged In" ? (
                    <Badge color="green">{status}</Badge>
                  ) : (
                    <Badge color="red">{status}</Badge>
                  )}
                </Text>
              </SimpleGrid>
            </Card>
            <>
              <Title align="left" order={4} color="#3d3d3d">
                Meetings List
              </Title>
              <ScrollArea
                h={530}
                onScrollPositionChange={onScrollPositionChange}
              >
                {meetings.map((row, index) => (
                  <div key={index}>
                    <Card
                      key={index}
                      style={{
                        backgroundColor: "#FFFFFF",
                        borderWidth: 1,
                        borderColor: "#B197FC",
                        opacity: selectedIndex === index ? opacity : 0.7,
                      }}
                      withBorder
                      shadow="md"
                      my={10}
                      color="#3d3d3d"
                    >
                      <SimpleGrid key={index} cols={1}>
                        <Group
                          style={{
                            width: "100%",
                            flexDirection: "row",
                          }}
                          spacing="md"
                        >
                          {row.image != "" ? (
                            <Avatar
                              onClick={() => {
                                imageModal({
                                  data: meetingImageUrl + row.image,
                                  title: row.title,
                                });
                              }}
                              size={60}
                              src={meetingImageUrl + row.image}
                            />
                          ) : (
                            <Avatar
                              onClick={() => {
                                imageModal({
                                  data: meetingImageUrl + row.image,
                                  title: row.title,
                                });
                              }}
                              size={20}
                              src={meetingImageUrl + row.image}
                            />
                          )}

                          <div>
                            <Text fz="sm" fw={500}>
                              <Anchor
                                component="button"
                                size="md"
                                color="#3d3d3d"
                              >
                                {row.title}
                              </Anchor>
                            </Text>
                            <Group>
                              <FileDescription size={12} color="#3d3d3d" />
                              <Text c="#3d3d3d" size={10}>
                                {row.description}
                              </Text>
                            </Group>
                            <Group>
                              <Battery size={12} color="#3d3d3d" />
                              <Text c="#3d3d3d" size={10}>
                                {row.start_percentage} %
                              </Text>
                            </Group>
                            <Group>
                              <Clock size={12} color="#3d3d3d" />
                              <Text c="#3d3d3d" size={10}>
                                {row.start_time} - {row.end_time}
                              </Text>
                            </Group>
                          </div>
                        </Group>
                      </SimpleGrid>
                    </Card>
                    {/* <Group>
                      <DirectionSign size={12} color="black" />
                      {travelArray.map((value, index) => (
                        <Text key={index} c="#000000" size={10}>
                          Travel {value.distance.text} - {value.duration.text}
                        </Text>
                      ))}
                    </Group> */}
                  </div>
                ))}
              </ScrollArea>
            </>
          </Grid.Col>
          <Grid.Col md={8} lg={8}>
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={17}
            >
              <DirectionsRenderer
                directions={coordinates}
                options={{
                  polylineOptions: {
                    strokeOpacity: 0.8,
                    strokeWeight: 4,
                    strokeColor: "#59117f",
                  },
                  suppressMarkers: true,
                }}
              />
              {wayPoints.map((points, index) => (
                <Marker
                  key={index}
                  position={{
                    lat: points && Number(points.lat) ? Number(points.lat) : 0,
                    lng: points && Number(points.lng) ? Number(points.lng) : 0,
                  }}
                  onClick={() => handleActiveGrid(index)}
                />
              ))}
            </GoogleMap>
          </Grid.Col>
        </Grid>
      </Skeleton>
    </>
  ) : (
    <></>
  );
}

export default FieldExecutiveRoute;
