/**

    * Application Name: Zevcore unolo Backend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 17.05.2023
    * Last Modified Date: 17.05.2023
    * Developer Name: Anand_Krishna

**/

import { url } from "../../../services/url";
import axios from "axios";

export const LoginImageUrl = `${url.baseUrl}public/images/loggedIn/`;
export const executiveImageUrl = `${url.baseUrl}public/images/executives/`;
export const meetingImageUrl = `${url.baseUrl}public/images/meetings/`;

const fetchCredentials = async () => {
  try {
    // Retrieve the credentials
    const credentials = await localStorage.getItem("token");
    if (credentials) {
      return {
        headers: {
          "x-access-token": credentials,
        },
      };
    } else {
      console.log("No credentials stored");
    }
  } catch (error) {
    console.log("Keychain couldn't be accessed!", error);
  }
  return false;
};

const AttendanceUrl = {
  attendance_Url: `${url.apiUrl}/attendance`,
  attendanceSearch_Url: `${url.apiUrl}/attendance_search`,
  connectLogsToday_URL: `${url.apiUrl}/connection_log_today`,
  connectLogsSearch_URL: `${url.apiUrl}/connection_log_search`,
  connectLogs_URL: `${url.apiUrl}/connection_log`,
  user_Url: `${url.apiUrl}/user`,
};

// For handle get user data list
export const handleGetOneUser = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      AttendanceUrl.user_Url + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle get Location Type data list
export const handleGetAllAttendance = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(AttendanceUrl.attendance_Url, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle get location search data list
export const handleSearchAllAttendance = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      AttendanceUrl.attendanceSearch_Url,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete Location Type data list
export const handleDeleteAttendance = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      AttendanceUrl.attendance_Url + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete Location Type data list
export const handleGetConnectLogsToday = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      AttendanceUrl.connectLogsToday_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete Location Type data list
export const handleSearchConnectLogs = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      AttendanceUrl.connectLogsSearch_URL,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete Location Type data list
export const handleDeleteConnectLogs = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      AttendanceUrl.connectLogs_URL + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
