/**

    * Application Name: Zevcore unolo Backend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 17.05.2023
    * Last Modified Date: 17.05.2023
    * Developer Name: Anand_Krishna

**/

import { Button, Paper, PasswordInput, TextInput } from "@mantine/core";
import React, { useState } from "react";
import { useForm } from "@mantine/form"; // Mantine form import
import { useNavigate } from "react-router-dom";
import { handleLogin } from "./authApi";
import Logo from "../../assets/images/logo2.png";
import useStyles from "../../components/styles/styles"; // Import custom styles
import { notificationHelper } from "../../services/notification";

function Login() {
  const { classes } = useStyles();
  let navigate = useNavigate();

  // Setting the variables data list here
  const [variables, setVariables] = useState({
    submitLoading: false,
    userCount: 0,
  });

  // For form validation
  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },
    validate: {
      email: (value) => (value.length < 1 ? "Email is required" : null),
      password: (value) => (value.length < 1 ? "Password is required" : null),
    },
  });

  const handleSubmit = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const response = await handleLogin(e);
    console.log(response);
    if (response.status === 200 && response.data.roles == "Admin") {
      localStorage.setItem("token", response.data.accessToken);
      localStorage.setItem("role", response.data.roles);
      localStorage.setItem("isAuthenticated", "true");
      setTimeout(() => {
        navigate("/admin/Dashboard");
      }, 300);
      notificationHelper({
        color: "green",
        title: "Success",
        message: "You have logged in successfully",
      });
      setVariables({ ...variables, submitLoading: false });
    } else if (response.status === 200 && response.data.roles == "Manager") {
      localStorage.setItem("token", response.data.accessToken);
      localStorage.setItem("role", response.data.roles);
      localStorage.setItem("isAuthenticated", "true");
      setTimeout(() => {
        navigate("/manager/Dashboard");
      }, 300);
      notificationHelper({
        color: "green",
        title: "Success",
        message: "You have logged in successfully",
      });
      setVariables({ ...variables, submitLoading: false });
    } else {
      notificationHelper({
        color: "red",
        title: "Failed!",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  return (
    <div className={classes.wrapper}>
      <Paper className={classes.form} radius={0} p={30}>
        <div justify="center" align="center" style={{ marginBottom: 25 }}>
          <img
            src={Logo}
            alt=""
            className="zc-pt-3"
            style={{ height: "150px" }}
          />
        </div>
        {/* Login for admin login enter phone number and password */}
        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
          <TextInput
            value={form.values.email}
            label="Email address or Phone No"
            placeholder="Enter Email or Phone No"
            size="md"
            {...form.getInputProps("email")}
          />
          <PasswordInput
            label="Password"
            placeholder="Your password"
            mt="md"
            size="md"
            value={form.values.password}
            {...form.getInputProps("password")}
          />

          <Button
            mt="xl"
            type="submit"
            fullWidth
            color="gold"
            loading={variables.submitLoading}
          >
            Login
          </Button>
        </form>
      </Paper>
    </div>
  );
}

export default Login;
