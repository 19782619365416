/**

    * Application Name: Zevcore unolo Backend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 17.05.2023
    * Last Modified Date: 17.05.2023
    * Developer Name: Anand_Krishna

**/

import { url } from "../../../services/url";
import axios from "axios";

// Token
const config = {
  headers: {
    "x-access-token": localStorage.getItem("token"),
  },
};

export const general_image_Url = `${url.baseUrl}public/images/executives/`;

const fetchCredentials = async () => {
  try {
    // Retrieve the credentials
    const credentials = await localStorage.getItem("token");
    if (credentials) {
      return {
        headers: {
          "x-access-token": credentials,
        },
      };
    } else {
      console.log("No credentials stored");
    }
  } catch (error) {
    console.log("Keychain couldn't be accessed!", error);
  }
  return false;
};

const generalExecutiveUrl = {
  general_executive_Url: `${url.apiUrl}/general_executive`,
  general_executiveStatus_Url: `${url.apiUrl}/general_executive_status`,
  general_executiveReset_Url: `${url.apiUrl}/general_executive_reset`,
  general_executiveUpload_Url: `${url.apiUrl}/general_executive_upload`,
  manager_Url: `${url.apiUrl}/manager`,
};

// For handle get general executive data list
export const handleGetGeneralExecutive = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      generalExecutiveUrl.general_executive_Url,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle get all manager data list
export const handleGetAllManager = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(generalExecutiveUrl.manager_Url, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add general executive data list
export const handleAddGeneralExecutive = async (request) => {
  try {
    const config = await fetchCredentials();
    console.log(config);
    const response = await axios.post(
      generalExecutiveUrl.general_executive_Url,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit general executive data list
export const handleEditGeneralExecutive = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      generalExecutiveUrl.general_executive_Url,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle field executive image upload
export const handleUploadGeneralExecutiveImage = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      generalExecutiveUrl.general_executiveUpload_Url,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle field executive status
export const handleGeneralExecutiveStatus = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      generalExecutiveUrl.general_executiveStatus_Url,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle field executive status
export const handleGeneralExecutiveResetDevice = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      generalExecutiveUrl.general_executiveReset_Url,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete general executive data list
export const handleDeleteGeneralExecutive = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      generalExecutiveUrl.general_executive_Url + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
