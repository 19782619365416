import React, { useState, useEffect, useRef, useCallback } from "react";
import BreadCrumb from "../../../components/breadcrumbs/BreadCrumb"; // For breadcrumbs data import
import useStyles from "../../../components/styles/styles"; // Import the mantine custome styles from the compoents
import {
  Skeleton,
  Card,
  TextInput,
  Group,
  Text,
  Button,
  Table,
  ScrollArea,
  NativeSelect,
  Pagination,
  ActionIcon,
  Modal,
  Loader,
  useMantineTheme,
  Grid,
  Anchor,
  Select,
  Avatar,
  Badge,
  FileButton,
  Paper,
  Tooltip,
} from "@mantine/core"; //For import mantine required functions and theme //
import { Pencil, Photo, Search, Trash } from "tabler-icons-react"; // For particular icons that has used in this page from tabler icon // For import the icons
import { useForm } from "@mantine/form"; // Mantine form import
import {
  handleGetFieldExecutive,
  handleAddFieldExecutive,
  handleEditFieldExecutive,
  handleDeleteFieldExecutive,
  FE_image_Url,
  handleGetAllSites,
  handleGetAllManager,
  handleFieldExecutiveStatus,
  handleUploadFieldExecutiveImage,
  handleFieldExecutiveResetDevice,
} from "./field_trackingApi"; // For connect to the electron backend from this helper apis // Import for axios requests list for this pages
import notificationHelper from "../../../services/notification"; // Import notification for this page
import { dataSearch, print } from "../../../services/tableFunction"; // For table data functions
import { dataSlice, imageModal } from "../../../services/common"; // Common functions uses for applications
import { useModals } from "@mantine/modals"; // Modal from mantine
import excel from "../../../assets/images/excel.png"; // Image for excel exports
import { CSVLink } from "react-csv";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";
import {
  Dropzone,
  DropzoneStatus,
  MIME_TYPES,
  IMAGE_MIME_TYPE,
} from "@mantine/dropzone";
import "react-image-crop/dist/ReactCrop.css";
import { useDebounceEffect } from "../../../components/common/userDebounceEffect.ts";

// RichtextEditor
function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 10, // make it 10 for smaller crop ratio
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

function Executives() {
  // Mantine custome style use
  const { classes } = useStyles();
  const modals = useModals();
  const theme = useMantineTheme();
  // Setting the variables data for table data
  const [sortedData, setSortedData] = useState([]); // For table data
  const [activePage, setPage] = useState(1); // For set table active page
  const [total, setTotal] = useState(10); // For set total list show in page
  const [search, setSearch] = useState(""); // For set the search value name of table
  const [loadingData, setLoadingData] = useState(false);
  const [sortBy, setSortBy] = useState(null); // Setting the sortby table type
  const [refreshTable, setRefreshTable] = useState(Date.now()); // For refresh table
  const [sites, setSites] = useState([]);
  const [manager, setManager] = useState([]);

  // for image upload
  const [id_value, setIdValue] = useState();
  const [imageTitle, setImageTitle] = useState();
  const [profileimage, SetProfileimage] = useState("");
  const [loadingState, setLoadingState] = useState(false);

  // Setting the variables data list here
  const [variables, setVariables] = useState({
    skeletonLoading: true,
    submitLoading: false,
    data: [],
    addDrawer: false,
    bulkDrawer: false,
    openEdit: false,
    deleteIndex: 0,
  });

  // For form validation
  const form = useForm({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      phone_number: "",
      role: "Field_Executive",
      manager_id: "",
      image: "images.png",
      status: "Approved",
    },
    validate: {
      firstname: (value) =>
        value.length < 1 ? "Site Executive name is required" : null,
    },
  });

  //   For edit form data validation
  const formEdit = useForm({
    initialValues: {
      value: "",
      firstname: "",
      lastname: "",
      email: "",
      phone_number: "",
      manager_id: "",
      user_id: "",
    },
    validate: {
      firstname: (value) =>
        value.length < 1 ? "Site Executive name is required" : null,
    },
  });

  // Form values for adding state values
  const formImage = useForm({
    initialValues: {
      value: "",
      image: "",
    },
  });

  //   For initial setting data
  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (mounted) {
        // For get all the tax data list
        const response = await handleGetFieldExecutive();
        // On Response setting the data to variable
        if (response.status === 200) {
          setVariables({
            ...variables,
            data: response.data.data.reverse(),
            skeletonLoading: false,
          });
          const datas = dataSlice({
            data: response.data.data,
            page: 1,
            total: 10,
          });
          setSortedData(datas);
        }

        // For get all the sites data list
        const response1 = await handleGetAllSites();
        // On Response setting the data to variable
        if (response1.status === 200) {
          var data = response1.data.data;
          var clean = data.map((data) => ({
            value: data.value.toString(),
            label: data.name,
          }));
          setSites(clean);
        }

        // For get all the sites data list
        const response2 = await handleGetAllManager();
        // On Response setting the data to variable
        if (response2.status === 200) {
          var data = response2.data.data;
          var clean = data.map((data) => ({
            value: data.value.toString(),
            label: data.firstname,
          }));
          setManager(clean);
        }
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  // Table data arrange by using function and loop throw each data range to table body
  const rows = sortedData.map((row, index) => (
    <tr key={index}>
      <td>{activePage * total - total + index + 1}</td>
      <td style={{ cursor: "pointer" }}>
        <Group spacing="sm">
          {row.image != "" ? (
            <Avatar
              onClick={() => {
                imageModal({
                  data: FE_image_Url + row.user.image,
                  title: row.firstname,
                });
              }}
              radius={30}
              size={30}
              src={FE_image_Url + row.user.image}
            />
          ) : (
            <Avatar
              onClick={() => {
                imageModal({
                  data: FE_image_Url + row.user.image,
                  title: row.firstname,
                });
              }}
              size={20}
              src={FE_image_Url + row.user.image}
            />
          )}

          <div>
            <Text fz="sm" fw={500}>
              <Anchor
                onClick={() => handleEdit(row.value)}
                component="button"
                size="sm"
                color="#59117f"
              >
                {row.firstname} {row.lastname}
              </Anchor>
            </Text>
            <Text c="dimmed" size={10}>
              {row.email} | {row.phone_number}
            </Text>
          </div>
        </Group>
      </td>
      <td>
        {row.site !== null && row.site !== "" && typeof row.site !== "undefined"
          ? row.site.name
          : "Assigned Site: N/A"}
      </td>
      <td>
        {row.manager !== null &&
        row.manager !== "" &&
        typeof row.manager !== "undefined"
          ? row.manager.firstname
          : "Manager: N/A"}
      </td>
      <td>
        {row.status === "Block" ? (
          <Badge color="red" size="lg">
            {row.status}
          </Badge>
        ) : (
          <Badge color="green" size="lg">
            {row.status}
          </Badge>
        )}
      </td>
      {row.status === "Block" ? (
        <td>
          <Button
            onClick={() => handleStatus(row.value)}
            variant="outline"
            color="green"
            radius="md"
            uppercase
          >
            Approved
          </Button>
        </td>
      ) : (
        <td>
          <Button
            onClick={() => handleStatus(row.value)}
            variant="outline"
            color="red"
            radius="md"
            uppercase
          >
            Block
          </Button>
        </td>
      )}
      {row.user.deviceId === null &&
      row.user.deviceId === "" &&
      typeof row.user.deviceId === "undefined" ? (
        <td>
          <Button
            onClick={() => handleDeviceReset(row.value)}
            variant="outline"
            color="green"
            radius="md"
            uppercase
          >
            Reset Device ID
          </Button>
        </td>
      ) : (
        <td>
          <Group position="left">
            <Tooltip label="User yet to register application..! Device id not provided.">
              <Button
                data-disabled
                sx={{ "&[data-disabled]": { pointerEvents: "all" } }}
                onClick={(event) => event.preventDefault()}
              >
                Not Available
              </Button>
            </Tooltip>
          </Group>
        </td>
      )}
      {/* For action drop down edit and delete the data */}
      <td>
        <Group spacing={0} position="left">
          <ActionIcon onClick={() => openUpload(row.value)}>
            <Photo color="#59117f" size={20} />
          </ActionIcon>
          <ActionIcon onClick={() => handleEdit(row.value)}>
            <Pencil size={18} color="gray" />
          </ActionIcon>
          <ActionIcon
            onClick={() => openConfirmModalDelete(row.value)}
            color="red"
          >
            <Trash size={18} />
          </ActionIcon>
        </Group>
      </td>
    </tr>
  ));

  // Add new tax data
  const handleAdd = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const response = await handleAddFieldExecutive(e);
    // Check for response for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        title: "Success",
        message: "Site Executive added successfully",
      });
      form.reset();
      setVariables({
        ...variables,
        submitLoading: false,
        data: response.data.data,
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setSortedData(datas);
    } else {
      notificationHelper({
        color: "red",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  // Editing or the setting the designation value
  const handleEdit = (e) => {
    var datas = variables.data.find((img) => img.value === e);
    formEdit.setFieldValue("value", datas.value);
    formEdit.setFieldValue("firstname", datas.firstname);
    formEdit.setFieldValue("lastname", datas.lastname);
    formEdit.setFieldValue("email", datas.email);
    formEdit.setFieldValue("phone_number", datas.phone_number);
    formEdit.setFieldValue("status", datas.status);
    {
      datas.manager_id !== null
        ? formEdit.setFieldValue("manager_id", datas.manager_id.toString())
        : formEdit.setFieldValue("manager_id", null);
    }
    setVariables({ ...variables, deleteIndex: e, openEdit: true });
  };

  // Edit function for updating designation values
  const handleEdits = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const response = await handleEditFieldExecutive(e);
    // Check for response data for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Site Executive updated successfully",
      });
      setVariables({
        ...variables,
        submitLoading: false,
        data: response.data.data.reverse(),
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setSortedData(datas);
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  //For delete confirm modal show Delete
  const openConfirmModalDelete = (e) => {
    var datas = variables.data.find((img) => img.value === e);
    setVariables({ ...variables, deleteIndex: e });
    modals.openConfirmModal({
      title: "Delete Confirmation",
      children: (
        <Text size="sm">
          Are you sure you want to delete the Site Executive
          <b>{datas.label}</b> ?
        </Text>
      ),
      labels: { confirm: "Confirm", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => handleConfirmDelete(e),
    });
  };

  //   For delete db data from table and db
  const handleConfirmDelete = async (e) => {
    const response = await handleDeleteFieldExecutive(e);
    // Check the response for notification and actions

    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Site Executive deleted successfully",
      });
      setVariables({
        ...variables,
        submitLoading: false,
        addDrawer: false,
        data: response.data.data.reverse(),
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setSortedData(datas);
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  // Crop Image
  const openRef = useRef();
  const [image, setImage] = useState("");
  const [upImg, setUpImg] = useState("");
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(1 / 1);
  const [cropConfig, setCropConfig] = useState({
    unit: "%",
    width: 50,
    aspect: 9 / 16,
  });
  const [crop, setCrop] = useState({
    unit: "%",
    width: 50,
    aspect: 24 / 12,
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const ref = useRef();
  const ref45 = useRef();
  const changeHandler = (e) => {
    if (e) {
      console.log(e);
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e);
      ref.current.value = "";
    }
  };
  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        const TO_RADIANS = Math.PI / 180;
        const image = imgRef.current;
        const crop = completedCrop;
        const canvas = previewCanvasRef.current;
        const scale = 1;
        const rotate = 1;

        const ctx = canvas.getContext("2d");

        if (!ctx) {
          throw new Error("No 2d context");
        }

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        // devicePixelRatio slightly increases sharpness on retina devices
        // at the expense of slightly slower render times and needing to
        // size the image back down if you want to download/upload and be
        // true to the images natural size.
        const pixelRatio = window.devicePixelRatio;
        // const pixelRatio = 1

        canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
        canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

        ctx.scale(pixelRatio, pixelRatio);
        ctx.imageSmoothingQuality = "high";

        const cropX = crop.x * scaleX;
        const cropY = crop.y * scaleY;

        const rotateRads = rotate * TO_RADIANS;
        const centerX = image.naturalWidth / 2;
        const centerY = image.naturalHeight / 2;

        ctx.save();

        // 5) Move the crop origin to the canvas origin (0,0)
        ctx.translate(-cropX, -cropY);
        // 4) Move the origin to the center of the original position
        ctx.translate(centerX, centerY);
        // 3) Rotate around the origin
        ctx.rotate(rotateRads);
        // 2) Scale the image
        ctx.scale(scale, scale);
        // 1) Move the center of the image to the origin (0,0)
        ctx.translate(-centerX, -centerY);
        ctx.drawImage(
          image,
          0,
          0,
          image.naturalWidth,
          image.naturalHeight,
          0,
          0,
          image.naturalWidth,
          image.naturalHeight
        );
        ctx.restore();
        const base64Image = canvas.toDataURL("image/jpeg");
        setImage(base64Image);
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const openUpload = (e) => {
    setVariables({ ...variables, submitLoading: true });
    var datas = variables.data.find((img) => img.value === e);
    console.log(datas);
    setImageTitle(datas.label);
    SetProfileimage(datas.user.image);
    setIdValue(e);
    setVariables({ ...variables, deleteIndex: e, imageDrawer: true });
  };

  // upload Site executive image values
  const handleUpload = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    var req = {
      image: image,
      value: id_value,
    };
    e = { ...e, ...req };
    const response = await handleUploadFieldExecutiveImage(e);
    // Check for response for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Customer image uploaded successfully",
      });
      setUpImg("");
      setImage("");
      setVariables({
        ...variables,
        submitLoading: false,
        imageDrawer: false,
        data: response.data.data.reverse(),
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setSortedData(datas);
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false, addDrawer: false });
    }
  };

  const handleStatus = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const req = {
      value: e,
    };
    const response = await handleFieldExecutiveStatus(req);
    // Check for response data for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Site Executive status updated successfully",
      });
      setVariables({
        ...variables,
        submitLoading: false,
        data: response.data.data.reverse(),
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setSortedData(datas);
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  const handleDeviceReset = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const req = {
      value: e,
    };
    const response = await handleFieldExecutiveResetDevice(req);
    // Check for response data for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Executive device id wiped successfully",
      });
      setVariables({
        ...variables,
        submitLoading: false,
        data: response.data.data.reverse(),
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setSortedData(datas);
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  return (
    <div>
      {/* For breadcrumbs */}
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <BreadCrumb Text="Site Executive" Title="Geo Fencing Sites" />
      </Skeleton>

      {/* Main start here */}
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <Card className="border">
          <ScrollArea>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {/* For search the table data input forms */}
              <TextInput
                variant="filled"
                placeholder="Search by any field"
                mb="md"
                size="xs"
                value={search}
                icon={<Search size={14} />}
                onChange={async (e) => {
                  // On change search ofr the data that is enter
                  setSearch(e.currentTarget.value);
                  setPage(1);
                  const datas = await dataSearch({
                    data: variables.data,
                    value: e.currentTarget.value,
                    activePage: activePage,
                    total: total,
                  });
                  setSortedData(datas);
                  setRefreshTable(new Date());
                }}
                sx={{ width: 250 }}
              />
              <div>
                <Group spacing="xs">
                  {/* For export the the table data to pdf and excels */}
                  <Text>Exports :</Text>
                  <CSVLink
                    data={variables.data}
                    headers={[
                      { label: "value", key: "value" },
                      { label: "firstname", key: "firstname" },
                      { label: "lastname", key: "lastname" },
                      { label: "email", key: "email" },
                      { label: "phone_number", key: "phone_number" },
                      { label: "role", key: "role" },
                      { label: "site_id", key: "site_id" },
                      { label: "manager_id", key: "manager_id" },
                      { label: "image", key: "image" },
                      { label: "status", key: "status" },
                      { label: "createdAt", key: "createdAt" },
                      { label: "updatedAt", key: "updatedAt" },
                    ]}
                    filename="location_category.csv"
                    className={classes.pdfExcel}
                  >
                    <img
                      src={excel}
                      alt="excel"
                      width="25"
                      style={{ margin: "2px" }}
                    />
                  </CSVLink>
                  {/* Drawer open for adding new tax data */}
                  <Button
                    variant="outline"
                    style={{ color: "#59117f", borderColor: "#59117f" }}
                    size="xs"
                    onClick={() =>
                      setVariables({ ...variables, addDrawer: true })
                    }
                  >
                    + Add Site Executive
                  </Button>
                </Group>
              </div>
            </div>
            {/* Table data view */}
            <Table verticalSpacing="sm">
              {/* Table header defines */}
              <thead>
                <tr>
                  <th>Sl.No</th>
                  <th>Site Executive</th>
                  <th>Site Assigned</th>
                  <th>Reporting Manager</th>
                  <th>Status</th>
                  <th>Login Permission</th>
                  <th>DeviceId Reset</th>
                  <th>Action</th>
                </tr>
              </thead>
              {rows.length > 0 ? (
                <tbody>{rows}</tbody>
              ) : (
                <>
                  {loadingData === true ? (
                    <tbody>
                      <tr>
                        <td colSpan={8}>
                          <div style={{ textAlign: "center" }}>
                            <Loader size="md" color="#59117f" variant="bars" />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan={8}>
                          <div style={{ textAlign: "center" }}>
                            <Text weight={500} align="center">
                              Nothing found
                            </Text>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </>
              )}
            </Table>
          </ScrollArea>
          {/* For display the pagination and no of per pages list */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: 15,
            }}
          >
            {/* For number of rows display in table */}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Text size="sm" className="zc-pr-3 zc-pt-1">
                Per Page
              </Text>
              <NativeSelect
                size="xs"
                onChange={async (e) => {
                  setTotal(Number(e.currentTarget.value));
                  setPage(1);
                  const datas = await dataSlice({
                    data: variables.data,
                    page: 1,
                    total: Number(e.currentTarget.value),
                  });
                  setSortedData(datas);
                  setRefreshTable(new Date());
                }}
                data={["10", "20", "50", "100"]}
                rightSectionWidth={20}
                sx={{ width: 70 }}
              />
            </div>
            {/* For pagination */}
            <Pagination
              size="xs"
              page={activePage}
              onChange={async (e) => {
                setPage(Number(e));
                const datas = await dataSlice({
                  data: variables.data,
                  page: Number(e),
                  total: total,
                });
                setSortedData(datas);
                setRefreshTable(new Date());
              }}
              total={Math.ceil(variables.data.length / total)}
            />
          </div>
          {variables.data.length > 0 ? (
            <>
              <Text mt={5} align="right" size="sm" color="#59117f">
                Last updated on:&nbsp;
                {new Date(
                  variables.data.reduce(function (r, a) {
                    return r.updatedAt > a.updatedAt ? r : a;
                  }).updatedAt
                ).toLocaleString("en-UK")}
              </Text>
            </>
          ) : null}
        </Card>
      </Skeleton>

      {/* Add Modal */}
      <Modal
        opened={variables.addDrawer}
        onClose={() => setVariables({ ...variables, addDrawer: false })}
        title={"Add New Site Executive"}
        size="xl"
        overlayProps={{
          color:
            theme.colorScheme === "dark"
              ? theme.primaryColor[9]
              : theme.primaryColor[2],
          opacity: 0.55,
          blur: 3,
        }}
      >
        <div className="zvcr-scroll">
          <div style={{ padding: "10px" }}>
            <form onSubmit={form.onSubmit((values) => handleAdd(values))}>
              <Grid>
                <Grid.Col md={4} lg={4}>
                  <TextInput
                    label="Firstname"
                    withAsterisk
                    required
                    value={form.values.firstname}
                    placeholder="Firstname"
                    {...form.getInputProps("firstname")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <TextInput
                    label="Lastname"
                    value={form.values.lastname}
                    placeholder="Lastname"
                    {...form.getInputProps("lastname")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <TextInput
                    label="Email"
                    withAsterisk
                    required
                    value={form.values.email}
                    placeholder="Email"
                    {...form.getInputProps("email")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <TextInput
                    label="Phone Number"
                    withAsterisk
                    required
                    maxLength={10}
                    value={form.values.phone_number}
                    placeholder="Phone Number"
                    {...form.getInputProps("phone_number")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <Select
                    label="Reporting Manager"
                    placeholder="Reporting Manager"
                    data={manager}
                    searchable
                    required
                    clearable
                    {...form.getInputProps("manager_id")}
                  />
                </Grid.Col>
                <Grid.Col md={12} lg={12}>
                  <Button
                    fullWidth
                    radius="md"
                    type="submit"
                    size="md"
                    color="#59117f"
                  >
                    Add Site Executive
                  </Button>
                </Grid.Col>
              </Grid>
            </form>
          </div>
        </div>
      </Modal>
      {/* Add Modal */}

      {/* Edit Modal */}
      <Modal
        opened={variables.openEdit}
        onClose={() => setVariables({ ...variables, openEdit: false })}
        title={"Update Site Executive Details"}
        size="xl"
        overlayProps={{
          color:
            theme.colorScheme === "dark"
              ? theme.primaryColor[9]
              : theme.primaryColor[2],
          opacity: 0.55,
          blur: 3,
        }}
      >
        <div style={{ padding: "10px" }}>
          <form onSubmit={formEdit.onSubmit((values) => handleEdits(values))}>
            <Grid>
              <Grid.Col md={4} lg={4}>
                <TextInput
                  label="Firstname"
                  withAsterisk
                  required
                  value={formEdit.values.firstname}
                  placeholder="Firstname"
                  {...formEdit.getInputProps("firstname")}
                />
              </Grid.Col>
              <Grid.Col md={4} lg={4}>
                <TextInput
                  label="Lastname"
                  value={formEdit.values.lastname}
                  placeholder="Lastname"
                  {...formEdit.getInputProps("lastname")}
                />
              </Grid.Col>
              <Grid.Col md={4} lg={4}>
                <TextInput
                  label="Email"
                  withAsterisk
                  required
                  value={formEdit.values.email}
                  placeholder="Email"
                  {...formEdit.getInputProps("email")}
                />
              </Grid.Col>
              <Grid.Col md={4} lg={4}>
                <TextInput
                  label="Phone Number"
                  withAsterisk
                  required
                  maxLength={10}
                  value={formEdit.values.phone_number}
                  placeholder="Phone Number"
                  {...formEdit.getInputProps("phone_number")}
                />
              </Grid.Col>
              <Grid.Col md={4} lg={4}>
                <Select
                  label="Reporting Manager"
                  placeholder="Reporting Manager"
                  data={manager}
                  searchable
                  required
                  clearable
                  {...formEdit.getInputProps("manager_id")}
                />
              </Grid.Col>
              <Grid.Col md={12} lg={12}>
                <Button
                  fullWidth
                  radius="md"
                  type="submit"
                  size="md"
                  color="#59117f"
                >
                  Update Site Executive
                </Button>
              </Grid.Col>
            </Grid>
          </form>
        </div>
      </Modal>
      {/* Edit Modal */}
      {/* Image Modal */}
      <Modal
        opened={variables.imageDrawer}
        onClose={() => setVariables({ ...variables, imageDrawer: false })}
        title={imageTitle}
        overlayProps={{
          color:
            theme.colorScheme === "dark"
              ? theme.colors.dark[9]
              : theme.colors.gray[2],
          opacity: 0.55,
          blur: 3,
        }}
      >
        <form onSubmit={formImage.onSubmit((values) => handleUpload(values))}>
          <Grid style={{ padding: "10px" }}>
            <Grid.Col md={12} lg={12} mt={1}>
              {/* <Text>Upload State Image</Text> */}
            </Grid.Col>
            <Grid.Col md={12} lg={12} mt={1}>
              {/* For image cropper */}
              {/* For cropper */}
              {upImg !== "" && upImg !== null ? (
                <>
                  <ReactCrop
                    crop={crop}
                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                    onComplete={(c) => setCompletedCrop(c)}
                    aspect={aspect}
                  >
                    <img
                      ref={imgRef}
                      alt="Crop me"
                      src={upImg}
                      style={{
                        transform: `scale(${scale}) rotate(${rotate}deg)`,
                      }}
                      onLoad={onImageLoad}
                    />
                  </ReactCrop>
                  <div>
                    {previewCanvasRef != null ? (
                      <canvas
                        ref={previewCanvasRef}
                        // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                        style={{
                          width: Math.round(completedCrop?.width ?? 0),
                          height: Math.round(completedCrop?.height ?? 0),
                          marginBottom: 50,
                          display: "none",
                        }}
                      />
                    ) : null}

                    <Group position="right" mt="md" mb={20}>
                      <Button
                        type="submit"
                        color="zevcore"
                        onClick={() => {
                          setUpImg("");
                          setImage("");
                        }}
                      >
                        Clear Image
                      </Button>
                    </Group>
                  </div>
                </>
              ) : (
                // For selecting cropping image dropdown
                <div
                  style={{
                    marginTop: 15,
                    position: "relative",
                    marginBottom: 30,
                  }}
                >
                  <Paper
                    p="lg"
                    sx={(theme) => ({
                      backgroundColor:
                        theme.colorScheme === "dark"
                          ? theme.colors.dark[8]
                          : theme.white,
                    })}
                  >
                    <Avatar
                      src={FE_image_Url + profileimage}
                      size={150}
                      radius={100}
                      withBorder
                      mx="auto"
                    />
                    <Text ta="center" fz="lg" weight={500} mt="md">
                      {imageTitle}
                    </Text>

                    <Group position="center">
                      <FileButton
                        resetRef={ref}
                        onChange={changeHandler}
                        accept={[
                          "image/png",
                          "image/jpeg",
                          "image/sgv+xml",
                          "image/gif",
                        ]}
                      >
                        {(props) => (
                          <Button mt={20} {...props}>
                            Select Image
                          </Button>
                        )}
                      </FileButton>
                    </Group>
                  </Paper>
                </div>
              )}
            </Grid.Col>
            <Button
              loading={loadingState}
              fullWidth
              type="submit"
              size="sm"
              variant="outline"
              color="zevcore"
            >
              Update Photo
            </Button>
          </Grid>
        </form>
      </Modal>
      {/* Image Modal */}
    </div>
  );
}
export default Executives;
