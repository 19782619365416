import React, { useState, useEffect } from "react";
import BreadCrumb from "../../../components/breadcrumbs/BreadCrumb"; // For breadcrumbs data import
import useStyles from "../../../components/styles/styles"; // Import the mantine custome styles from the compoents
import {
  Skeleton,
  Card,
  TextInput,
  Group,
  Text,
  Button,
  Table,
  ScrollArea,
  NativeSelect,
  Pagination,
  ActionIcon,
  Modal,
  Loader,
  useMantineTheme,
  Grid,
  Badge,
  Avatar,
  Anchor,
  Paper,
  Image,
  Select,
} from "@mantine/core"; //For import mantine required functions and theme //
import { Calendar, Eye, Route, Search, Trash } from "tabler-icons-react"; // For particular icons that has used in this page from tabler icon // For import the icons
import { useForm } from "@mantine/form"; // Mantine form import
import { dataSearch } from "../../../services/tableFunction"; // For table data functions
import { dataSlice, imageModal } from "../../../services/common"; // Common functions uses for applications
import { useModals } from "@mantine/modals"; // Modal from mantine
import excel from "../../../assets/images/excel.png"; // Image for excel export // Image for pdf exports
import { CSVLink } from "react-csv";
import { useNavigate } from "react-router-dom";
import { DatePickerInput } from "@mantine/dates";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import {
  handleGetAllGeneralExecutive,
  handleGetAllMeetings,
  handleSearchAllMeetings,
  meetingsImageUrl,
} from "./MeetingReportApi";
import { tConvert, toHoursAndMinutes } from "../../../services/url";

const containerStyle = {
  width: "100%",
  height: "300px",
};

const libraries = ["places", "drawing"];

function WeeklyMeetingReport() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBMWoaIEUJybmZFkVn7poh96I0d0XhNN4A",
    libraries,
  });
  // Mantine custome style use
  const { classes } = useStyles();
  let navigate = useNavigate();
  const modals = useModals();
  const theme = useMantineTheme();
  // Setting the variables data for table data
  const [sortedData, setSortedData] = useState([]); // For table data
  const [activePage, setPage] = useState(1); // For set table active page
  const [total, setTotal] = useState(10); // For set total list show in page
  const [search, setSearch] = useState(""); // For set the search value name of table
  const [executive, setExecutive] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [sortBy, setSortBy] = useState(null); // Setting the sortby table type
  const [refreshTable, setRefreshTable] = useState(Date.now()); // For refresh table
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  const [currentDate, setCurrentDate] = useState(
    date + "-" + month + "-" + year
  );
  // Setting the variables data list here
  const [variables, setVariables] = useState({
    skeletonLoading: false,
    submitLoading: false,
    data: [],
    viewDrawer: false,
    deleteIndex: 0,
  });

  // For form validation
  const formSearch = useForm({
    initialValues: {
      executive_id: "",
    },
  });

  // Modal Constants

  const [modelUsername, setModalUsername] = useState();
  const [modelSitename, setModalSitename] = useState();
  const [modelStatus, setModalStatus] = useState();
  const [modelStartTime, setModalStartTime] = useState();
  const [modelEndTime, setModalEndTime] = useState();
  const [modelTotalDuration, setModalTotalDuration] = useState();
  const [modelStartImage, setModalStartImage] = useState();
  const [modelEndImage, setModalEndImage] = useState();
  const [modelStartLatitude, setModalStartLatitude] = useState();
  const [modelStartLongitude, setModalStartLongitude] = useState();
  const [modelEndLatitude, setModalEndLatitude] = useState();
  const [modelEndLongitude, setModalEndLongitude] = useState();

  // Modal Constants

  //   For initial setting data
  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (mounted) {
        // For get all the tax data list
        const response = await handleGetAllMeetings();
        // On Response setting the data to variable
        if (response.status === 200) {
          setVariables({
            ...variables,
            data: response.data.data.reverse(),
            skeletonLoading: false,
          });
          const datas = dataSlice({
            data: response.data.data,
            page: 1,
            total: 10,
          });
          setSortedData(datas);
        }

        const response2 = await handleGetAllGeneralExecutive();
        console.log(response2.data.data);
        if (response2.status === 200) {
          var data = response2.data.data;
          var clean = data.map((data) => ({
            value: data.user_id.toString(),
            label: data.firstname,
          }));
          setExecutive(clean);
        }
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  // Table data arrange by using function and loop throw each data range to table body
  const rows = sortedData.map((row, index) => (
    <tr key={row.user.firstname}>
      <td>{activePage * total - total + index + 1}</td>
      <td style={{ cursor: "pointer" }}>
        <Group spacing="sm">
          {row.image !== "" ? (
            <Avatar
              onClick={() => {
                imageModal({
                  data: meetingsImageUrl + row.image,
                  title: row.title,
                });
              }}
              radius={30}
              size={30}
              src={meetingsImageUrl + row.image}
            />
          ) : (
            <Avatar
              onClick={() => {
                imageModal({
                  data: meetingsImageUrl + row.image,
                  title: row.title,
                });
              }}
              size={20}
              src={meetingsImageUrl + row.image}
            />
          )}

          <div>
            <Text fz="sm" fw={500}>
              <Anchor component="button" size="sm" color="#59117f">
                {row.title}
              </Anchor>
            </Text>
            <Text c="dimmed" size={10}>
              {row.user.firstname} {row.user.lastname}
            </Text>
          </div>
        </Group>
      </td>
      <td>{new Date(row.meeting_date).toLocaleDateString("en-UK")}</td>
      <td>{tConvert(row.start_time).toString()}</td>
      <td>{tConvert(row.end_time).toString()}</td>
      <td>{toHoursAndMinutes(row.total_duration)}</td>
      <td>
        {row.status === "Completed" ? (
          <Badge color="green">{row.status}</Badge>
        ) : (
          <Badge color="orange">{row.status}</Badge>
        )}
      </td>
      {/* For action drop down edit and delete the data */}
      <td>
        <Group spacing={0} position="left">
          <ActionIcon onClick={() => handleViewModal(row.value)}>
            <Eye size={18} color="#59117f" />
          </ActionIcon>
          <ActionIcon
            onClick={() =>
              navigate("/admin/field_executive_route/" + row.user.value)
            }
          >
            <Route size={18} color="gray" />
          </ActionIcon>
          <ActionIcon
            onClick={() => openConfirmModalDelete(row.value)}
            color="red"
          >
            <Trash size={18} />
          </ActionIcon>
        </Group>
      </td>
    </tr>
  ));

  const handleViewModal = (e) => {
    var datas = variables.data.find((img) => img.value === e);
    setModalUsername(datas.user.firstname + datas.user.lastname);
    setModalSitename("");
    setModalStatus(datas.status);
    setModalStartTime(datas.start_time);
    setModalEndTime(datas.end_time);
    setModalTotalDuration("9:00");
    setModalStartImage(datas.image);
    setModalStartLatitude(datas.start_latitude);
    setModalStartLongitude(datas.start_longitude);
    setModalEndLatitude(datas.end_latitude);
    setModalEndLongitude(datas.end_longitude);
    console.log(Number(modelStartLatitude));
    setVariables({ ...variables, deleteIndex: e, viewDrawer: true });
  };

  const startCenter = {
    lat: modelStartLatitude ? Number(modelStartLatitude) : 0,
    lng: modelStartLongitude ? Number(modelStartLongitude) : 0,
  };

  const endCenter = {
    lat: modelEndLatitude ? Number(modelEndLatitude) : 0,
    lng: modelEndLongitude ? Number(modelEndLongitude) : 0,
  };

  const handleSearchMeeting = async (e) => {
    const req = {
      date1: new Date(dateRange[0]),
      date2: new Date(dateRange[1]),
      executive_id: e.executive_id,
    };
    setVariables({ ...variables, submitLoading: true });
    const response = await handleSearchAllMeetings(req);
    if (response.status === 200) {
      setVariables({
        ...variables,
        data: response.data.data.reverse(),
        skeletonLoading: false,
      });
      const datas = dataSlice({
        data: response.data.data,
        page: 1,
        total: 10,
      });
      setSortedData(datas);
    }
  };

  return (
    <div>
      {/* For breadcrumbs */}
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <BreadCrumb Text="Weekly Meetings Report" Title="Meeting Reports" />
      </Skeleton>

      {/* Main start here */}
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <Paper mb={10} p={15} className="border">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Text weight={600} align="center">
              Search Meetings
            </Text>
          </div>
          <div style={{ paddingBottom: "10px" }}>
            <form
              onSubmit={formSearch.onSubmit((values) =>
                handleSearchMeeting(values)
              )}
            >
              <Grid>
                <Grid.Col md={4} lg={4} style={{ zIndex: 1000 }}>
                  <Select
                    label="Select Executive"
                    placeholder="Select Executive"
                    data={executive}
                    dropdownPosition={"bottom"}
                    zIndex={1000}
                    maxDropdownHeight={100}
                    searchable
                    required
                    clearable
                    {...formSearch.getInputProps("executive_id")}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <DatePickerInput
                    icon={
                      <Calendar size="1.1rem" stroke={1.5} color="#59117f" />
                    }
                    label="Select date"
                    type="range"
                    placeholder="Select date"
                    value={dateRange}
                    dropdownType={"modal"}
                    onChange={setDateRange}
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <Button
                    radius="md"
                    type="submit"
                    size="md"
                    mt={20}
                    color="zevcore"
                  >
                    Search
                  </Button>
                </Grid.Col>
              </Grid>
            </form>
          </div>
        </Paper>
        <Card className="border">
          <ScrollArea>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Text weight={600} align="center">
                {currentDate} Meetings Logs
              </Text>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {/* For search the table data input forms */}
              <TextInput
                variant="filled"
                placeholder="Search by any field"
                mb="md"
                size="xs"
                value={search}
                icon={<Search size={14} />}
                onChange={async (e) => {
                  // On change search ofr the data that is enter
                  setSearch(e.currentTarget.value);
                  setPage(1);
                  const datas = await dataSearch({
                    data: variables.data,
                    value: e.currentTarget.value,
                    activePage: activePage,
                    total: total,
                  });
                  setSortedData(datas);
                  setRefreshTable(new Date());
                }}
                sx={{ width: 250 }}
              />
              <div>
                <Group spacing="xs">
                  {/* For export the the table data to pdf and excels */}
                  <Text>Exports :</Text>
                  <CSVLink
                    data={variables.data}
                    headers={[
                      { label: "value", key: "value" },
                      { label: "name", key: "name" },
                      { label: "createdAt", key: "createdAt" },
                      { label: "updatedAt", key: "updatedAt" },
                    ]}
                    filename="location_category.csv"
                    className={classes.pdfExcel}
                  >
                    <img
                      src={excel}
                      alt="excel"
                      width="25"
                      style={{ margin: "2px" }}
                    />
                  </CSVLink>
                  {/* Drawer open for adding new tax data */}
                </Group>
              </div>
            </div>
            {/* Table data view */}
            <Table verticalSpacing="sm">
              {/* Table header defines */}
              <thead>
                <tr>
                  <th>Sl.No</th>
                  <th>Title</th>
                  <th>Date</th>
                  <th>Start Time</th>
                  <th>End Time </th>
                  <th>Total Duration </th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              {rows.length > 0 ? (
                <tbody>{rows}</tbody>
              ) : (
                <>
                  {loadingData === true ? (
                    <tbody>
                      <tr>
                        <td colSpan={6}>
                          <div style={{ textAlign: "center" }}>
                            <Loader size="md" color="#59117f" variant="bars" />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan={6}>
                          <div style={{ textAlign: "center" }}>
                            <Text weight={500} align="center">
                              Nothing found
                            </Text>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </>
              )}
            </Table>
          </ScrollArea>
          {/* For display the pagination and no of per pages list */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: 15,
            }}
          >
            {/* For number of rows display in table */}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Text size="sm" className="zc-pr-3 zc-pt-1">
                Per Page
              </Text>
              <NativeSelect
                size="xs"
                onChange={async (e) => {
                  setTotal(Number(e.currentTarget.value));
                  setPage(1);
                  const datas = await dataSlice({
                    data: variables.data,
                    page: 1,
                    total: Number(e.currentTarget.value),
                  });
                  setSortedData(datas);
                  setRefreshTable(new Date());
                }}
                data={["10", "20", "50", "100"]}
                rightSectionWidth={20}
                sx={{ width: 70 }}
              />
            </div>
            {/* For pagination */}
            <Pagination
              size="xs"
              page={activePage}
              onChange={async (e) => {
                setPage(Number(e));
                const datas = await dataSlice({
                  data: variables.data,
                  page: Number(e),
                  total: total,
                });
                setSortedData(datas);
                setRefreshTable(new Date());
              }}
              total={Math.ceil(variables.data.length / total)}
              color="zevcore"
            />
          </div>
          {variables.data.length > 0 ? (
            <>
              <Text mt={5} align="right" size="sm" color="green">
                Last updated on:&nbsp;
                {new Date(
                  variables.data.reduce(function (r, a) {
                    return r.updatedAt > a.updatedAt ? r : a;
                  }).updatedAt
                ).toLocaleString("en-UK")}
              </Text>
            </>
          ) : null}
        </Card>
      </Skeleton>
      {/* meetings Details Modal */}
      <Modal
        opened={variables.viewDrawer}
        onClose={() => setVariables({ ...variables, viewDrawer: false })}
        title={"Meeting Details Modal"}
        size="60%"
        overlayProps={{
          color:
            theme.colorScheme === "dark"
              ? theme.primaryColor[9]
              : theme.primaryColor[2],
          opacity: 0.55,
          blur: 3,
        }}
      >
        <div className="zvcr-scroll">
          <div style={{ padding: "10px" }}>
            <Card>
              <Grid>
                <Grid.Col md={4} lg={4}>
                  <Paper shadow="xs" p="md">
                    <Text style={{ color: "black", fontSize: 16 }}>
                      Username:{" "}
                    </Text>
                    <Text style={{ color: "gray", fontSize: 14 }}>
                      {modelUsername}
                    </Text>
                  </Paper>
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <Paper shadow="xs" p="md">
                    <Text style={{ color: "black", fontSize: 16 }}>
                      Site ID:{" "}
                    </Text>
                    <Text style={{ color: "gray", fontSize: 14 }}>
                      {modelSitename !== "" ? modelSitename : "Not Available"}
                    </Text>
                  </Paper>
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <Paper shadow="xs" p="md">
                    <Text style={{ color: "black", fontSize: 16 }}>
                      Status:{" "}
                    </Text>
                    <Text style={{ color: "gray", fontSize: 14 }}>
                      {modelStatus === "Completed" ? (
                        <Badge color="green">{modelStatus}</Badge>
                      ) : (
                        <Badge color="orange">{modelStatus}</Badge>
                      )}
                    </Text>
                  </Paper>
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <Paper shadow="xs" p="md">
                    <Text style={{ color: "black", fontSize: 16 }}>
                      Start Time:{" "}
                    </Text>
                    <Text style={{ color: "gray", fontSize: 14 }}>
                      {modelStartTime !== null ? modelStartTime : "--"}
                    </Text>
                  </Paper>
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <Paper shadow="xs" p="md">
                    <Text style={{ color: "black", fontSize: 16 }}>
                      End Time:{" "}
                    </Text>
                    <Text style={{ color: "gray", fontSize: 14 }}>
                      {modelEndTime !== null ? modelEndTime : "--"}
                    </Text>
                  </Paper>
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <Paper shadow="xs" p="md">
                    <Text style={{ color: "black", fontSize: 16 }}>
                      Total Duration:{" "}
                    </Text>
                    <Text style={{ color: "gray", fontSize: 14 }}>
                      {modelTotalDuration}
                    </Text>
                  </Paper>
                </Grid.Col>
                <Grid.Col md={12} lg={12}>
                  <Paper shadow="xs" p="md">
                    <Text style={{ color: "black", fontSize: 16 }}>
                      Meeting Image:{" "}
                    </Text>
                    <Image
                      width={"100%"}
                      height={300}
                      fit="contain"
                      mx="auto"
                      radius="md"
                      src={meetingsImageUrl + modelStartImage}
                      alt="Random image"
                    />
                  </Paper>
                </Grid.Col>

                <Grid.Col md={12} lg={12}>
                  <Paper shadow="xs" p="md">
                    <Text
                      style={{ color: "black", fontSize: 16, marginBottom: 10 }}
                    >
                      Meeting Location Map View:{" "}
                    </Text>
                    <GoogleMap
                      mapContainerStyle={containerStyle}
                      center={endCenter}
                      zoom={17}
                    >
                      <Marker
                        clickable={true}
                        title={modelUsername}
                        zIndex={1000}
                        // onClick={onClickMarker()}
                        position={endCenter}
                      />
                    </GoogleMap>
                  </Paper>
                </Grid.Col>
              </Grid>
            </Card>
          </div>
        </div>
      </Modal>
      {/* meetings Details Modal */}
    </div>
  );
}
export default WeeklyMeetingReport;
