import React, { useCallback, useRef, useState } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  DrawingManager,
  Polygon,
  Autocomplete,
} from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "500px",
};

const libraries = ["places", "drawing"];

export default function GeoMaps({ setPoints }) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBMWoaIEUJybmZFkVn7poh96I0d0XhNN4A",
    libraries,
  });

  const [coordinates, setCoordinates] = useState([]);
  const [path, setPath] = useState([]);

  const [state, setState] = useState({
    drawingMode: "polygon",
  });

  const [center, setCenter] = useState({
    lat: 12.3484343,
    lng: 76.6301862,
  });

  const options = {
    drawingControl: true,
    drawingControlOptions: {
      drawingMode: ["Polygon"],
    },
    PolygonOptions: {
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
      clickable: true,
      draggable: true,
      editable: true,
      zIndex: 1,
    },
  };

  const onPolygonComplete = React.useCallback(
    function onPolygonComplete(poly) {
      const polyArray = poly.getPath().getArray();
      let paths = [];
      polyArray.forEach(function (path) {
        paths.push({ lat: path.lat(), lng: path.lng() });
      });
      setPath(paths);
      console.log(paths);
      setPoints(paths);
      poly.setMap(null);
    }
    // [point]
  );

  // Define refs for polygon instance and listeners
  const polygonRef = useRef(null);
  const listenerRef = useRef([]);
  const autocompleteRef = useRef();
  const [searchResult, setSearchResult] = useState("");

  // Call set path with new edited paths
  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map((latlng) => {
          return { lat: latlng.lat(), lng: latlng.lng() };
        });
      console.log(nextPath);
      setPath(nextPath);
      //point(nextPath);
      setPoints(nextPath);
    }
  });

  const onLoad = useCallback((polygon) => {
    polygonRef.current = polygon;
    const path = polygon.getPath();
    listenerRef.current.push(
      path.addListener("set_at", onEdit),
      path.addListener("insert_at", onEdit),
      path.addListener("remove_at", onEdit)
    );
  });

  const onUnmount = useCallback(() => {});

  function onLoadAuto(autocomplete) {
    setSearchResult(autocomplete);
  }

  const onPlaceChanged = () => {
    if (searchResult != null) {
      //variable to store the result
      const place = searchResult.getPlace();
      console.log(place.geometry.location.lat());
      console.log(place.geometry.location.lng());
      const data = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      console.log(data);
      setCenter(data);
      //console log all results
      //   console.log(`Name: ${name}`);
      //   console.log(`Business Status: ${status}`);
      //   console.log(`Formatted Address: ${formattedAddress}`);
      //   console.log(place.geometry.location.lat());
      //   console.log(place.geometry.location.lng());
    } else {
      alert("Please enter text");
    }
  };

  return isLoaded ? (
    <>
      <Autocomplete
        onLoad={onLoadAuto}
        onPlaceChanged={(place) => onPlaceChanged(place)}
        label="Search location"
      >
        <input
          type="text"
          label="Search location"
          placeholder="Search location"
          style={{
            boxSizing: `border-box`,
            border: `1px solid transparent`,
            width: `100%`,
            height: `42px`,
            padding: `0 12px`,
            borderRadius: `3px`,
            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
            fontSize: `14px`,
            outline: `none`,
            textOverflow: `ellipses`,
            // position: "absolute",
            // top: "-10%",
            marginLeft: "0px",
            marginBottom: "10px",
          }}
        />
      </Autocomplete>
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={17}>
        {path.length === 0 || path.length === 1 || path.length === 2 ? (
          <DrawingManager
            drawingMode={state.drawingMode}
            editable
            draggable
            options={options}
            onPolygonComplete={onPolygonComplete}
          />
        ) : (
          <Polygon
            options={{
              fillColor: "#d70a84",
              strokeColor: "#59117f",
              fillOpacity: 0.35,
              strokeWeight: 2,
            }}
            editable
            path={path}
            onMouseUp={onEdit}
            onDragEnd={onEdit}
            onLoad={onLoad}
            onUnmount={onUnmount}
          />
        )}
      </GoogleMap>
    </>
  ) : (
    <></>
  );
}
