/**

    * Application Name: Zevcore unolo Backend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 17.05.2023
    * Last Modified Date: 17.05.2023
    * Developer Name: Anand_Krishna

**/

import { url } from "../../services/url";
import axios from "axios";

const authUrl = {
  loginUrl: `${url.apiUrl}/auth/signin`,
};

export const handleLogin = async (request) => {
  try {
    const response = await axios.post(authUrl.loginUrl, request);
    return response;
  } catch (error) {
    return error.response;
  }
};
