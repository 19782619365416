import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Card,
  Grid,
  Skeleton,
  Title,
  Text,
  Badge,
  SimpleGrid,
  Group,
  Avatar,
  Anchor,
  ScrollArea,
} from "@mantine/core";
import { handleGetOneSite } from "./geofencingApi";
import { useParams } from "react-router-dom";
import {
  DrawingManager,
  GoogleMap,
  Marker,
  Polygon,
  useJsApiLoader,
} from "@react-google-maps/api";
import { FE_image_Url } from "../field_executive/field_trackingApi";
import { Battery, Clock, RotateClockwise, Timeline } from "tabler-icons-react";
import { tConvert, toHoursAndMinutes } from "../../../services/url";

const containerStyle = {
  width: "100%",
  height: "750px",
};

const libraries = ["places", "drawing"];

function LiveMap() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBMWoaIEUJybmZFkVn7poh96I0d0XhNN4A",
    libraries,
  });

  const [path, setPath] = useState([]);
  const [centerLatitude, setCenterLatitude] = useState(0);
  const [centerLongitude, setCenterLongitude] = useState(0);
  const [state, setState] = useState({
    drawingMode: "polygon",
  });

  let point = "";
  const [variables, setVariables] = useState({
    skeletonLoading: false,
    loadingState: false,
  });

  const [paths, setPaths] = useState([]);
  const [category, setCategory] = useState();
  const [siteName, setSiteName] = useState();
  const [manager, setManager] = useState();
  const [loggedInTime, setLoggedInTime] = useState();
  const [loggedOutTime, setLoggedOutTime] = useState();
  const [batteryPercentage, setBatteryPercentage] = useState();
  const [status, setStatus] = useState("Logged In");
  const [executivelist, setExecutivelist] = useState([]);
  const [executiveCount, setExecutiveCount] = useState(0);
  const [scrolled, setScrolled] = useState({ x: 0, y: 0 });
  const params = useParams();

  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (mounted) {
        const response = await handleGetOneSite(params.value);
        console.log(response.data.data);
        if (response.status === 200) {
          console.log(response.data.data.site_executives[0]);
          var datas = response.data.data;
          console.log(datas.site_executives);
          setCategory(datas.location_category.name);
          setSiteName(datas.name);
          setManager(datas.manager.firstname);
          // setStatus()
          setExecutivelist(datas.site_executives);
          setExecutiveCount(datas.site_executives.length);
          let paths = [];
          datas.site_coordinates.forEach(function (path) {
            paths.push({
              lat: parseFloat(path.latitude),
              lng: parseFloat(path.longitude),
            });
          });
          setPaths(paths);
          setCenterLatitude(paths[0].lat);
          setCenterLongitude(paths[0].lng);
          console.log(paths[0].lat);
        }
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  const options = {
    drawingControl: true,
    drawingControlOptions: {
      drawingMode: ["Polygon"],
    },
    PolygonOptions: {
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
      clickable: true,
      draggable: true,
      editable: true,
      zIndex: 1,
    },
  };

  const onPolygonComplete = React.useCallback(function onPolygonComplete(poly) {
    const polyArray = poly.getPath().getArray();
    let paths = [];
    polyArray.forEach(function (path) {
      paths.push({ lat: path.lat(), lng: path.lng() });
    });
    setPaths(paths);
    console.log(paths);
    setCoordinates(paths);
    point(path);
    poly.setMap(null);
  });

  // Define refs for polygon instance and listeners
  const polygonRef = useRef(null);
  const listenerRef = useRef([]);
  const autocompleteRef = useRef();
  const [searchResult, setSearchResult] = useState("");

  // Call set path with new edited paths
  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map((latlng) => {
          return { lat: latlng.lat(), lng: latlng.lng() };
        });
      console.log(nextPath);
      setPath(nextPath);
      point(nextPath);
    }
  });

  const onLoad = useCallback((polygon) => {
    polygonRef.current = polygon;
    const path = polygon.getPath();
    listenerRef.current.push(
      path.addListener("set_at", onEdit),
      path.addListener("insert_at", onEdit),
      path.addListener("remove_at", onEdit)
    );
  });

  const onUnmount = useCallback(() => {});

  function onLoadAuto(autocomplete) {
    setSearchResult(autocomplete);
  }

  const onPlaceChanged = () => {
    if (searchResult != null) {
      //variable to store the result
      const place = searchResult.getPlace();
      console.log(place.geometry.location.lat());
      console.log(place.geometry.location.lng());
      const data = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      console.log(data);
      // setCenter(data);
    } else {
      alert("Please enter text");
    }
  };

  return isLoaded ? (
    <>
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <Grid style={{ height: "500px" }} shadow="xl" radius="md">
          <Grid.Col md={4} lg={4} style={{ backgroundColor: "#F0F0F0" }}>
            <Card
              style={{
                backgroundColor: "#FFFFFF",
                borderWidth: 1,
                borderColor: "#B197FC",
              }}
              withBorder
              shadow="md"
              my={20}
            >
              <SimpleGrid cols={2}>
                <Text
                  style={{ fontSize: 14, textAlign: "left", color: "#3d3d3d" }}
                >
                  Site Name:
                </Text>
                <Text
                  style={{ fontSize: 14, textAlign: "left", color: "#3d3d3d" }}
                >
                  {siteName}
                </Text>
              </SimpleGrid>
              <SimpleGrid cols={2}>
                <Text
                  style={{ fontSize: 14, textAlign: "left", color: "#3d3d3d" }}
                >
                  InCharge Manager:
                </Text>
                <Text
                  style={{ fontSize: 14, textAlign: "left", color: "#3d3d3d" }}
                >
                  {manager}
                </Text>
              </SimpleGrid>
              <SimpleGrid cols={2}>
                <Text
                  style={{ fontSize: 14, textAlign: "left", color: "#3d3d3d" }}
                >
                  Total Executives:
                </Text>
                <Text
                  style={{ fontSize: 14, textAlign: "left", color: "#3d3d3d" }}
                >
                  {executiveCount}
                </Text>
              </SimpleGrid>
            </Card>
            <>
              <Title align="left" order={4} color="#3d3d3d">
                Executive List
              </Title>
              <ScrollArea
                h={500}
                onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
              >
                <Card
                  style={{
                    backgroundColor: "#FFFFFF",
                    borderWidth: 1,
                    borderColor: "#B197FC",
                  }}
                  shadow="md"
                  my={10}
                  withBorder
                  color="white"
                >
                  {executivelist.map((row, index) => (
                    <>
                      <SimpleGrid key={index} cols={2}>
                        <Group position="left" spacing="sm">
                          {row.field_executive.image != "" ? (
                            <Avatar
                              radius={30}
                              size={30}
                              src={FE_image_Url + row.field_executive.image}
                            />
                          ) : (
                            <Avatar
                              size={20}
                              src={FE_image_Url + row.field_executive.image}
                            />
                          )}

                          <div>
                            <Text fz="sm" fw={500}>
                              <Anchor
                                component="button"
                                size="sm"
                                color="#3d3d3d"
                              >
                                {row.field_executive.firstname}{" "}
                                {row.field_executive.lastname}
                              </Anchor>
                            </Text>
                            <Text c="#3d3d3d" size={10}>
                              {row.field_executive.email} |{" "}
                              {row.field_executive.phone_number}
                            </Text>
                          </div>
                        </Group>
                        <Group position="right">
                          <Text
                            style={{
                              fontSize: 14,
                              textAlign: "right",
                              color: "#3d3d3d",
                            }}
                          >
                            {row.field_executive.user.attendances[0] !== null &&
                            row.field_executive.user.attendances[0] !== "" &&
                            typeof row.field_executive.user.attendances[0] !==
                              "undefined" ? (
                              row.field_executive.user.attendances[0].status ===
                              "Logged In" ? (
                                <Badge color="green">
                                  {
                                    row.field_executive.user.attendances[0]
                                      .status
                                  }
                                </Badge>
                              ) : (
                                <Badge color="red">
                                  {
                                    row.field_executive.user.attendances[0]
                                      .status
                                  }
                                </Badge>
                              )
                            ) : (
                              <Badge color="red">Logged Out</Badge>
                            )}
                          </Text>
                        </Group>
                        <div>
                          <Group>
                            <Battery size={12} color="#3d3d3d" />
                            <Text c="#3d3d3d" size={10}>
                              {row.field_executive.user.attendances[0] !==
                                null &&
                              row.field_executive.user.attendances[0] !== "" &&
                              typeof row.field_executive.user.attendances[0] !==
                                "undefined"
                                ? (
                                    row.field_executive.user.attendances[0]
                                      .start_percentage * 100
                                  ).toFixed(2)
                                : "0"}{" "}
                              %
                            </Text>
                          </Group>
                          <Group>
                            <Clock size={12} color="#3d3d3d" />
                            <Text c="#3d3d3d" size={10}>
                              {row.field_executive.user.attendances[0] !==
                                null &&
                              row.field_executive.user.attendances[0] !== "" &&
                              typeof row.field_executive.user.attendances[0] !==
                                "undefined"
                                ? tConvert(
                                    row.field_executive.user.attendances[0]
                                      .start_time
                                  )
                                : "00"}{" "}
                              -{" "}
                              {row.field_executive.user.attendances[0] !==
                                null &&
                              row.field_executive.user.attendances[0] !== "" &&
                              typeof row.field_executive.user.attendances[0] !==
                                "undefined"
                                ? tConvert(
                                    row.field_executive.user.attendances[0]
                                      .end_time
                                  )
                                : "00"}
                            </Text>
                          </Group>
                          <Group>
                            <RotateClockwise size={12} color="#3d3d3d" />
                            <Text c="#3d3d3d" size={10}>
                              {row.field_executive.user.attendances[0] !==
                                null &&
                              row.field_executive.user.attendances[0] !== "" &&
                              typeof row.field_executive.user.attendances[0] !==
                                "undefined"
                                ? toHoursAndMinutes(
                                    row.field_executive.user.attendances[0]
                                      .total_duration
                                  )
                                : "0"}
                            </Text>
                          </Group>
                        </div>
                      </SimpleGrid>
                    </>
                  ))}
                </Card>
              </ScrollArea>
            </>
          </Grid.Col>
          <Grid.Col md={8} lg={8}>
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={{
                lat: centerLatitude,
                lng: centerLongitude,
              }}
              zoom={20}
            >
              {paths.length === 0 ||
              paths.length === 1 ||
              paths.length === 2 ? (
                <DrawingManager
                  drawingMode={state.drawingMode}
                  options={options}
                />
              ) : (
                <Polygon
                  options={{
                    fillColor: "#d70a84",
                    strokeColor: "#59117f",
                    fillOpacity: 0.35,
                    strokeWeight: 2,
                  }}
                  path={paths}
                />
              )}
              {/* <Marker
                clickable={true}
                title="username"
                zIndex={1000}
                // onLoad={onLoad}
                position={{
                  lat: centerLatitude,
                  lng: centerLongitude,
                }}
              /> */}
            </GoogleMap>
          </Grid.Col>
        </Grid>
      </Skeleton>
    </>
  ) : (
    <></>
  );
}

export default LiveMap;
