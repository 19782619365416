import React, { useState, useEffect } from "react";
import BreadCrumb from "../../../components/breadcrumbs/BreadCrumb"; // For breadcrumbs data import
import useStyles from "../../../components/styles/styles"; // Import the mantine custome styles from the compoents
import {
  Skeleton,
  Card,
  TextInput,
  Group,
  Text,
  Button,
  Table,
  ScrollArea,
  NativeSelect,
  Pagination,
  ActionIcon,
  Modal,
  Loader,
  useMantineTheme,
  Grid,
  Badge,
  Avatar,
  Anchor,
  Paper,
  Image,
  Tabs,
  Select,
} from "@mantine/core"; //For import mantine required functions and theme //
import {
  Calendar,
  Eye,
  Fence,
  Photo,
  Route,
  Search,
  Trash,
  UserCheck,
  UserCircle,
} from "tabler-icons-react"; // For particular icons that has used in this page from tabler icon // For import the icons
import { useForm } from "@mantine/form"; // Mantine form import
import { dataSearch } from "../../../services/tableFunction"; // For table data functions
import { dataSlice, imageModal } from "../../../services/common"; // Common functions uses for applications
import { useModals } from "@mantine/modals"; // Modal from mantine
import excel from "../../../assets/images/excel.png"; // Image for excel export // Image for pdf exports
import { CSVLink } from "react-csv";
import {
  LoginImageUrl,
  executiveImageUrl,
  handleDeleteAttendance,
  handleGetAllAttendance,
  handleSearchAllAttendance,
} from "./AttendanceReportApi";
import { useNavigate } from "react-router-dom";
import { DatePickerInput } from "@mantine/dates";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { centerLocation } from "../../../services/url";

const containerStyle = {
  width: "100%",
  height: "300px",
};

const libraries = ["places", "drawing"];

// Time Conversion
function tConvert(time) {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
}
// Time Conversion

// Minutes to hours
function toHoursAndMinutes(totalMinutes) {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return `${hours}h${minutes > 0 ? ` ${minutes}m` : ""}`;
}
// Minutes to hours

function MonthlyFieldAttendanceReport() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBMWoaIEUJybmZFkVn7poh96I0d0XhNN4A",
    libraries,
  });
  // Mantine custome style use
  const { classes } = useStyles();
  let navigate = useNavigate();
  const modals = useModals();
  const theme = useMantineTheme();
  // Setting the variables data for table data
  const [sortedData, setSortedData] = useState([]); // For table data
  const [activePage, setPage] = useState(1); // For set table active page
  const [total, setTotal] = useState(10); // For set total list show in page
  const [search, setSearch] = useState(""); // For set the search value name of table
  const [loadingData, setLoadingData] = useState(false);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [sortBy, setSortBy] = useState(null); // Setting the sortby table type
  const [refreshTable, setRefreshTable] = useState(Date.now()); // For refresh table
  let newDate = new Date();
  let date = newDate.getDate();
  let months = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  const [currentDate, setCurrentDate] = useState(
    date + "-" + months + "-" + year
  );
  // Setting the variables data list here
  const [variables, setVariables] = useState({
    skeletonLoading: false,
    submitLoading: false,
    data: [],
    viewDrawer: false,
    deleteIndex: 0,
  });

  // For form validation
  const formSearch = useForm({
    initialValues: {
      date: "",
    },
  });

  // Modal Constants

  const [modelUsername, setModalUsername] = useState();
  const [modelSiteName, setModalSiteName] = useState();
  const [modelManager, setModalManager] = useState();
  const [modelStatus, setModalStatus] = useState();
  const [modelStartTime, setModalStartTime] = useState("");
  const [modelEndTime, setModalEndTime] = useState("");
  const [modelTotalDuration, setModalTotalDuration] = useState();
  const [modelStartImage, setModalStartImage] = useState();
  const [modelEndImage, setModalEndImage] = useState();
  const [modelStartLatitude, setModalStartLatitude] = useState();
  const [modelStartLongitude, setModalStartLongitude] = useState();
  const [modelEndLatitude, setModalEndLatitude] = useState();
  const [modelEndLongitude, setModalEndLongitude] = useState();

  //
  const [totalMeetings, setTotalMeetings] = useState(0);
  // Modal Constants

  const [dateRange2, setDateRange2] = useState(
    "0" + new Date().getMonth().toString()
  );

  const [month, setMonth] = useState([
    { value: "01", label: "Jan" },
    { value: "02", label: "Feb" },
    { value: "03", label: "Mar" },
    { value: "04", label: "Apr" },
    { value: "05", label: "May" },
    { value: "06", label: "Jun" },
    { value: "07", label: "Jul" },
    { value: "08", label: "Aug" },
    { value: "09", label: "Sep" },
    { value: "10", label: "Oct" },
    { value: "11", label: "Nov" },
    { value: "12", label: "Dec" },
  ]);

  //   For initial setting data
  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (mounted) {
        // For get all the tax data list
        const response = await handleGetAllAttendance();
        // On Response setting the data to variable
        // setTotalMeetings(response.data.data);
        if (response.status === 200) {
          setVariables({
            ...variables,
            data: response.data.data.reverse(),
            skeletonLoading: false,
          });
          const datas = dataSlice({
            data: response.data.data,
            page: 1,
            total: 10,
          });
          setSortedData(datas);
        }
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  // Table data arrange by using function and loop throw each data range to table body
  const rows = sortedData.map((row, index) => (
    <tr key={index}>
      {row.user.role === "General_Executive" ? (
        <>
          <td>{activePage * total - total + index + 1}</td>
          <td style={{ cursor: "pointer" }}>
            <Group spacing="sm">
              {row.start_image != "" ? (
                <Avatar
                  onClick={() => {
                    imageModal({
                      data: executiveImageUrl + row.user.image,
                      title: row.user.firstname,
                    });
                  }}
                  radius={30}
                  size={30}
                  src={executiveImageUrl + row.user.image}
                />
              ) : (
                <Avatar
                  onClick={() => {
                    imageModal({
                      data: executiveImageUrl + row.user.image,
                      title: row.user.firstname,
                    });
                  }}
                  size={20}
                  src={executiveImageUrl + row.user.image}
                />
              )}

              <div>
                <Text fz="sm" fw={500}>
                  <Anchor
                    onClick={() => navigate("/admin/live_sites/" + row.value)}
                    component="button"
                    size="sm"
                    color="#59117f"
                  >
                    {row.user.firstname} {row.user.lastname}
                  </Anchor>
                </Text>
                <Text c="dimmed" size={10}>
                  {row.user.email} | {row.user.phone_number}
                </Text>
              </div>
            </Group>
          </td>
          <td>{new Date(row.createdAt).toLocaleDateString("en-UK")}</td>
          <td>
            {row.start_time !== null &&
            row.start_time !== "" &&
            typeof row.start_time !== "undefined"
              ? tConvert(row.start_time).toString()
              : "--"}
          </td>
          <td>
            {row.end_time !== null &&
            row.end_time !== "" &&
            typeof row.end_time !== "undefined"
              ? tConvert(row.end_time).toString()
              : "--"}
          </td>
          <td>
            {row.total_duration !== null &&
            row.total_duration !== "" &&
            typeof row.total_duration !== "undefined"
              ? toHoursAndMinutes(row.total_duration)
              : null}
          </td>
          <td>
            {row.status === "Logged In" ? (
              <Badge color="green">{row.status}</Badge>
            ) : (
              <Badge color="red">{row.status}</Badge>
            )}
          </td>
          {/* For action drop down edit and delete the data */}
          <td>
            <Group spacing={0} position="left">
              <ActionIcon onClick={() => handleViewModal(row.value)}>
                <Eye size={18} color="#59117f" />
              </ActionIcon>
              <ActionIcon
                onClick={() =>
                  navigate("/admin/field_executive_route/" + row.user.value)
                }
              >
                <Route size={18} color="gray" />
              </ActionIcon>
              <ActionIcon
                onClick={() => openConfirmModalDelete(row.value)}
                color="red"
              >
                <Trash size={18} />
              </ActionIcon>
            </Group>
          </td>
        </>
      ) : null}
    </tr>
  ));

  //For delete confirm modal show Delete
  const openConfirmModalDelete = (e) => {
    var datas = variables.data.find((img) => img.value === e);
    setVariables({ ...variables, deleteIndex: e });
    modals.openConfirmModal({
      title: "Delete Confirmation",
      children: (
        <Text size="sm">
          Are you sure you want to delete the attendance of{" "}
          <b>{datas.user.firstname}</b> ?
        </Text>
      ),
      labels: { confirm: "Confirm", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => handleConfirmDelete(e),
    });
  };

  //   For delete db data from table and db
  const handleConfirmDelete = async (e) => {
    const response = await handleDeleteAttendance(e);
    // Check the response for notification and actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Attendance deleted successfully",
      });
      setVariables({
        ...variables,
        submitLoading: false,
        addDrawer: false,
        data: response.data.data.reverse(),
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setSortedData(datas);
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  const handleViewModal = (e) => {
    var datas = variables.data.find((img) => img.value === e);

    setModalUsername(datas.user.firstname + datas.user.lastname);
    setModalManager(datas.user.general_executives[0].manager.firstname);
    setModalStatus(datas.status);
    setModalStartTime(datas.start_time);
    setModalEndTime(datas.end_time);
    setModalTotalDuration(datas.total_duration);
    setModalStartImage(datas.start_image);
    setModalEndImage(datas.end_image);
    setModalStartLatitude(datas.start_latitude);
    setModalStartLongitude(datas.start_longitude);
    setModalEndLatitude(datas.end_latitude);
    setModalEndLongitude(datas.end_longitude);
    console.log(Number(modelStartLatitude));
    setVariables({ ...variables, deleteIndex: e, viewDrawer: true });
  };

  const startCenter = {
    lat: modelStartLatitude
      ? Number(modelStartLatitude)
      : centerLocation.LATITUDE,
    lng: modelStartLongitude
      ? Number(modelStartLongitude)
      : centerLocation.LONGITUDE,
  };

  const endCenter = {
    lat: modelEndLatitude ? Number(modelEndLatitude) : centerLocation.LATITUDE,
    lng: modelEndLongitude
      ? Number(modelEndLongitude)
      : centerLocation.LONGITUDE,
  };

  const handleSearchAttendance = async (e) => {
    const req = {
      date1: new Date(dateRange[0]),
      date2: new Date(dateRange[1]),
    };
    setVariables({ ...variables, submitLoading: true });
    const response = await handleSearchAllAttendance(req);
    console.log(response);
    if (response.status === 200) {
      setVariables({
        ...variables,
        data: response.data.data.reverse(),
        skeletonLoading: false,
      });
      const datas = dataSlice({
        data: response.data.data,
        page: 1,
        total: 10,
      });
      setSortedData(datas);
    }
  };

  return (
    <div>
      {/* For breadcrumbs */}
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <BreadCrumb
          Text="Monthly Attendance Report"
          Title="Attendance Reports"
        />
      </Skeleton>

      {/* Main start here */}
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <Paper mb={10} p={15} className="border">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Text weight={600} align="center">
              Search Attendance
            </Text>
          </div>
          <div style={{ padding: "10px" }}>
            <form
              onSubmit={formSearch.onSubmit((values) =>
                handleSearchAttendance(values)
              )}
            >
              <Grid>
                <Grid.Col md={4} lg={4}>
                  <Select
                    label="Select Month"
                    variant="filled"
                    value={dateRange2}
                    onChange={setDateRange2}
                    placeholder="Select Month"
                    dropdownPosition="down"
                    data={month}
                    {...formSearch.getInputProps("month")}
                  />
                </Grid.Col>
                <Grid.Col md={3} lg={3}>
                  <Button
                    radius="md"
                    type="submit"
                    size="md"
                    mt={20}
                    color="zevcore"
                  >
                    Search
                  </Button>
                </Grid.Col>
              </Grid>
            </form>
          </div>
        </Paper>
        <Card className="border">
          <ScrollArea>
            <Tabs defaultValue="messages">
              <Tabs.List>
                <Tabs.Tab
                  onClick={() =>
                    navigate("/admin/monthly_site_attendance_report/")
                  }
                  value="gallery"
                  icon={<Fence size="1rem" />}
                >
                  Site Executive
                </Tabs.Tab>
                <Tabs.Tab value="messages" icon={<Route size="1rem" />}>
                  Field Executive
                </Tabs.Tab>
              </Tabs.List>

              <Tabs.Panel value="messages" pt="xs">
                Field Executive Attendance List
              </Tabs.Panel>
            </Tabs>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 20,
              }}
            >
              {/* For search the table data input forms */}
              <TextInput
                variant="filled"
                placeholder="Search by any field"
                mb="md"
                size="xs"
                value={search}
                icon={<Search size={14} />}
                onChange={async (e) => {
                  // On change search ofr the data that is enter
                  setSearch(e.currentTarget.value);
                  setPage(1);
                  const datas = await dataSearch({
                    data: variables.data,
                    value: e.currentTarget.value,
                    activePage: activePage,
                    total: total,
                  });
                  setSortedData(datas);
                  setRefreshTable(new Date());
                }}
                sx={{ width: 250 }}
              />
              <div>
                <Group spacing="xs">
                  {/* For export the the table data to pdf and excels */}
                  <Text>Exports :</Text>
                  <CSVLink
                    data={variables.data}
                    headers={[
                      { label: "value", key: "value" },
                      { label: "name", key: "name" },
                      { label: "createdAt", key: "createdAt" },
                      { label: "updatedAt", key: "updatedAt" },
                    ]}
                    filename="location_category.csv"
                    className={classes.pdfExcel}
                  >
                    <img
                      src={excel}
                      alt="excel"
                      width="25"
                      style={{ margin: "2px" }}
                    />
                  </CSVLink>
                  {/* Drawer open for adding new tax data */}
                </Group>
              </div>
            </div>
            {/* Table data view */}
            <Table verticalSpacing="sm">
              {/* Table header defines */}
              <thead>
                <tr>
                  <th>Sl.No</th>
                  <th>Executive Name</th>
                  <th>Date</th>
                  <th>Logged In Time</th>
                  <th>Logged Out Time</th>
                  <th>Total Meetings</th>
                  <th>Total Duration</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              {rows.length > 0 ? (
                <tbody>{rows}</tbody>
              ) : (
                <>
                  {loadingData === true ? (
                    <tbody>
                      <tr>
                        <td colSpan={9}>
                          <div style={{ textAlign: "center" }}>
                            <Loader size="md" color="#59117f" variant="bars" />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan={9}>
                          <div style={{ textAlign: "center" }}>
                            <Text weight={500} align="center">
                              Nothing found
                            </Text>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </>
              )}
            </Table>
          </ScrollArea>
          {/* For display the pagination and no of per pages list */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: 15,
            }}
          >
            {/* For number of rows display in table */}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Text size="sm" className="zc-pr-3 zc-pt-1">
                Per Page
              </Text>
              <NativeSelect
                size="xs"
                onChange={async (e) => {
                  setTotal(Number(e.currentTarget.value));
                  setPage(1);
                  const datas = await dataSlice({
                    data: variables.data,
                    page: 1,
                    total: Number(e.currentTarget.value),
                  });
                  setSortedData(datas);
                  setRefreshTable(new Date());
                }}
                data={["10", "20", "50", "100"]}
                rightSectionWidth={20}
                sx={{ width: 70 }}
              />
            </div>
            {/* For pagination */}
            <Pagination
              size="xs"
              page={activePage}
              onChange={async (e) => {
                setPage(Number(e));
                const datas = await dataSlice({
                  data: variables.data,
                  page: Number(e),
                  total: total,
                });
                setSortedData(datas);
                setRefreshTable(new Date());
              }}
              total={Math.ceil(variables.data.length / total)}
              color="zevcore"
            />
          </div>
          {variables.data.length > 0 ? (
            <>
              <Text mt={5} align="right" size="sm" color="green">
                Last updated on:&nbsp;
                {new Date(
                  variables.data.reduce(function (r, a) {
                    return r.updatedAt > a.updatedAt ? r : a;
                  }).updatedAt
                ).toLocaleString("en-UK")}
              </Text>
            </>
          ) : null}
        </Card>
      </Skeleton>
      {/* Add Modal */}
      <Modal
        opened={variables.viewDrawer}
        onClose={() => setVariables({ ...variables, viewDrawer: false })}
        title={"Attendance Details Modal"}
        size="60%"
        overlayProps={{
          color:
            theme.colorScheme === "dark"
              ? theme.primaryColor[9]
              : theme.primaryColor[2],
          opacity: 0.55,
          blur: 3,
        }}
      >
        <div className="zvcr-scroll">
          <div style={{ padding: "10px" }}>
            <Card>
              <Grid>
                <Grid.Col md={4} lg={4}>
                  <Paper withBorder p="md">
                    <Text style={{ color: "black", fontSize: 16 }}>
                      Username:{" "}
                    </Text>
                    <Text style={{ color: "gray", fontSize: 14 }}>
                      {modelUsername}
                    </Text>
                  </Paper>
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <Paper withBorder p="md">
                    <Text style={{ color: "black", fontSize: 16 }}>
                      Manager:{" "}
                    </Text>
                    <Text style={{ color: "gray", fontSize: 14 }}>
                      {modelManager}
                    </Text>
                  </Paper>
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <Paper withBorder p="md">
                    <Text style={{ color: "black", fontSize: 16 }}>
                      Status:{" "}
                    </Text>
                    <Text style={{ color: "gray", fontSize: 14 }}>
                      {modelStatus === "Logged In" ? (
                        <Badge color="green">{modelStatus}</Badge>
                      ) : (
                        <Badge color="red">{modelStatus}</Badge>
                      )}
                    </Text>
                  </Paper>
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <Paper withBorder p="md">
                    <Text style={{ color: "black", fontSize: 16 }}>
                      Logged In Time:{" "}
                    </Text>
                    <Text style={{ color: "gray", fontSize: 14 }}>
                      {modelStartTime !== null
                        ? tConvert(modelStartTime.toString())
                        : "--"}
                    </Text>
                  </Paper>
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <Paper withBorder p="md">
                    <Text style={{ color: "black", fontSize: 16 }}>
                      Logged Out Time:{" "}
                    </Text>
                    <Text style={{ color: "gray", fontSize: 14 }}>
                      {modelEndTime !== null
                        ? tConvert(modelEndTime.toString())
                        : "--"}
                    </Text>
                  </Paper>
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <Paper withBorder p="md">
                    <Text style={{ color: "black", fontSize: 16 }}>
                      Total Duration:{" "}
                    </Text>
                    <Text style={{ color: "gray", fontSize: 14 }}>
                      {toHoursAndMinutes(modelTotalDuration)}
                    </Text>
                  </Paper>
                </Grid.Col>

                <Grid.Col md={6} lg={6}>
                  <Text style={{ color: "black", fontSize: 16 }}>
                    Logged In Image:
                  </Text>
                  <Image
                    width={300}
                    height={300}
                    fit="contain"
                    mx="auto"
                    radius="md"
                    src={LoginImageUrl + modelStartImage}
                    alt="Random image"
                  />
                </Grid.Col>
                <Grid.Col md={6} lg={6}>
                  <Text style={{ color: "black", fontSize: 16 }}>
                    Logged Out Image:{" "}
                  </Text>
                  <Image
                    width={300}
                    height={300}
                    fit="contain"
                    mx="auto"
                    radius="md"
                    src={LoginImageUrl + modelEndImage}
                    alt="Random image"
                  />
                </Grid.Col>
              </Grid>
            </Card>
          </div>
        </div>
      </Modal>
      {/* Add Modal */}
    </div>
  );
}
export default MonthlyFieldAttendanceReport;
