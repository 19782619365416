/**

    * Application Name: Zevcore unolo Backend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 17.05.2023
    * Last Modified Date: 17.05.2023
    * Developer Name: Anand_Krishna

**/

import { url } from "../../../services/url";
import axios from "axios";

export const meetingsImageUrl = `${url.baseUrl}public/images/meetings/`;

const fetchCredentials = async () => {
  try {
    // Retrieve the credentials
    const credentials = await localStorage.getItem("token");
    if (credentials) {
      return {
        headers: {
          "x-access-token": credentials,
        },
      };
    } else {
      console.log("No credentials stored");
    }
  } catch (error) {
    console.log("Keychain couldn't be accessed!", error);
  }
  return false;
};

const MeetingsUrl = {
  meeting_Url: `${url.apiUrl}/meetings`,
  general_executive_Url: `${url.apiUrl}/general_executive`,
  meetingSearch_Url: `${url.apiUrl}/meetings_search`,
};

// For handle get Location Type data list
export const handleGetAllMeetings = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(MeetingsUrl.meeting_Url, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle get general executive data list
export const handleGetAllGeneralExecutive = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(MeetingsUrl.general_executive_Url, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle get general executive data list
export const handleSearchAllMeetings = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(MeetingsUrl.meetingSearch_Url, config);
    return response;
  } catch (error) {
    return error.response;
  }
};
