/**

    * Application Name: Zevcore unolo Backend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 17.05.2023
    * Last Modified Date: 17.05.2023
    * Developer Name: Anand_Krishna

**/

import React, { useState } from "react";
import { Avatar, Card, Grid, Skeleton, Text } from "@mantine/core";
import ManagerBreadCrumb from "../../../components/breadcrumbs/ManagerBreadCrumb";
import { ReportAnalytics, Table } from "tabler-icons-react";

function ManagerDashboard() {
  const [variables, setVariables] = useState({
    skeletonLoading: false,
    submitLoading: false,
    data: [],
    addDrawer: false,
    bulkDrawer: false,
    openEdit: false,
    deleteIndex: 0,
  });
  return (
    <div>
      {/* For breadcrumbs */}
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <ManagerBreadCrumb Text="Dashboard" />
      </Skeleton>
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <Grid>
          <Grid.Col span={3}>
            <Skeleton radius="md" visible={variables.skeletonLoading}>
              <Card shadow="sm" p="lg" radius="md" withBorder>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ flexDirection: "column", marginTop: 10 }}>
                    <Text size="sm">Total Managers</Text>
                    <div
                      style={{
                        display: "flex",
                        marginTop: 5,
                      }}
                    >
                      <Text size={25} mt={-6}>
                        0
                      </Text>
                    </div>
                  </div>
                  <Avatar color="pink" radius="xl" size={70}>
                    <ReportAnalytics size={36} />
                  </Avatar>
                </div>
              </Card>
            </Skeleton>
          </Grid.Col>
          <Grid.Col span={3}>
            <Skeleton radius="md" visible={variables.skeletonLoading}>
              <Card shadow="sm" p="lg" radius="md" withBorder>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ flexDirection: "column", marginTop: 10 }}>
                    <Text size="sm">Total Geo-Fence Sites</Text>
                    <div
                      style={{
                        display: "flex",
                        marginTop: 5,
                      }}
                    >
                      <Text size={25} mt={-6}>
                        0
                      </Text>
                    </div>
                  </div>
                  <Avatar color="pink" radius="xl" size={70}>
                    <ReportAnalytics size={36} />
                  </Avatar>
                </div>
              </Card>
            </Skeleton>
          </Grid.Col>
          <Grid.Col span={3}>
            <Skeleton radius="md" visible={variables.skeletonLoading}>
              <Card shadow="sm" p="lg" radius="md" withBorder>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ flexDirection: "column", marginTop: 10 }}>
                    <Text size="sm">Total Field Executives</Text>
                    <div
                      style={{
                        display: "flex",
                        marginTop: 5,
                      }}
                    >
                      <Text size={25} mt={-6}>
                        0
                      </Text>
                    </div>
                  </div>
                  <Avatar color="pink" radius="xl" size={70}>
                    <ReportAnalytics size={36} />
                  </Avatar>
                </div>
              </Card>
            </Skeleton>
          </Grid.Col>
          <Grid.Col span={3}>
            <Skeleton radius="md" visible={variables.skeletonLoading}>
              <Card shadow="sm" p="lg" radius="md" withBorder>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ flexDirection: "column", marginTop: 10 }}>
                    <Text size="sm">Total General Executives</Text>
                    <div
                      style={{
                        display: "flex",
                        marginTop: 5,
                      }}
                    >
                      <Text size={25} mt={-6}>
                        0
                      </Text>
                    </div>
                  </div>
                  <Avatar color="pink" radius="xl" size={70}>
                    <ReportAnalytics size={36} />
                  </Avatar>
                </div>
              </Card>
            </Skeleton>
          </Grid.Col>
        </Grid>

        <Grid>
          <Grid.Col span={12}>
            <Skeleton radius="md" visible={variables.skeletonLoading}>
              <Card shadow="sm" p="lg" radius="md" withBorder>
                <Text align="center" mb={10} weight={600}>
                  Latest 10 Meetings List
                </Text>
                <Table verticalSpacing="sm">
                  {/* Table header defines */}
                  <thead>
                    <tr>
                      <th>Sl.No</th>
                      <th>Customer Name</th>
                      <th>Customer GST</th>
                      <th>Invoice Date</th>
                      <th>Invoice Number</th>
                      <th>Invoice Type</th>
                      <th>Total Amount</th>
                      <th>Payment Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                </Table>
              </Card>
            </Skeleton>
          </Grid.Col>
        </Grid>
      </Skeleton>
    </div>
  );
}

export default ManagerDashboard;
