/**

    * Application Name: CJM Frontend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 15.05.2023
    * Last Modified Date: 15.05.2023
    * Developer Name: Anand Krishna

**/

import { Button } from "@mantine/core"; //For import mantine required functions and theme
import React, { Component } from "react";
import { ArrowBackUp } from "tabler-icons-react"; // For particular icons that has used in this page from table icon
import ERROR from "../../assets/images/error.png"; // Error image

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, info) {
    this.setState({ error: error, errorInfo: info });
  }

  // For return back to home page dashboard
  handleClick() {
    try {
      window.location.href = "/admin/Dashboard";
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div className="error-boundary">
          {/* Show all the details of error in dropdown */}

          {/* Custom name and image to display whenever error accord */}
          <div className="text">
            <summary>Something went wrong</summary>
            <details style={{ whiteSpace: "pre-wrap" }}>
              {this.state.error && this.state.error.toString()}
              {this.state.errorInfo.componentStack}
            </details>
            <div>
              <img src={ERROR} width={400}></img>
            </div>
            <h4>Something went wrong on this page</h4>
            <p>
              Some error occurred you can see details and fix it. <br /> Due to
              some error occurred on this page cannot be able to render retry
              again or contact us.
            </p>
            <Button mt={25} mb={50} onClick={this.handleClick}>
              Return to home dashboard &nbsp; &nbsp;
              <ArrowBackUp />
            </Button>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
