import React, { useState, useEffect } from "react";
import BreadCrumb from "../../../components/breadcrumbs/BreadCrumb"; // For breadcrumbs data import
import useStyles from "../../../components/styles/styles"; // Import the mantine custome styles from the compoents
import {
  Skeleton,
  Card,
  TextInput,
  Group,
  Text,
  Button,
  Table,
  ScrollArea,
  NativeSelect,
  Pagination,
  ActionIcon,
  Modal,
  Loader,
  useMantineTheme,
  Grid,
  Badge,
  Avatar,
  Anchor,
  Paper,
} from "@mantine/core"; //For import mantine required functions and theme //
import { Calendar, Search, Trash } from "tabler-icons-react"; // For particular icons that has used in this page from tabler icon // For import the icons
import { useForm } from "@mantine/form"; // Mantine form import
import { dataSearch } from "../../../services/tableFunction"; // For table data functions
import { dataSlice, imageModal } from "../../../services/common"; // Common functions uses for applications
import { useModals } from "@mantine/modals"; // Modal from mantine
import excel from "../../../assets/images/excel.png"; // Image for excel export // Image for pdf exports
import { CSVLink } from "react-csv";
import {
  handleDeleteConnectLogs,
  handleGetConnectLogsToday,
  handleSearchConnectLogs,
} from "./liveTrackingApi";
import { useNavigate } from "react-router-dom";
import { DatePickerInput } from "@mantine/dates";

const containerStyle = {
  width: "100%",
  height: "300px",
};

const libraries = ["places", "drawing"];

// Time Conversion
function tConvert(time) {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
}
// Time Conversion

// Minutes to hours
function toHoursAndMinutes(totalMinutes) {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return `${hours}h${minutes > 0 ? ` ${minutes}m` : ""}`;
}
// Minutes to hours

function DisconnectionLogs() {
  // Mantine custome style use
  const { classes } = useStyles();
  let navigate = useNavigate();
  const modals = useModals();
  const theme = useMantineTheme();
  // Setting the variables data for table data
  const [sortedData, setSortedData] = useState([]); // For table data
  const [activePage, setPage] = useState(1); // For set table active page
  const [total, setTotal] = useState(10); // For set total list show in page
  const [search, setSearch] = useState(""); // For set the search value name of table
  const [loadingData, setLoadingData] = useState(false);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [sortBy, setSortBy] = useState(null); // Setting the sortby table type
  const [refreshTable, setRefreshTable] = useState(Date.now()); // For refresh table
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  const [currentDate, setCurrentDate] = useState(
    date + "-" + month + "-" + year
  );
  // Setting the variables data list here
  const [variables, setVariables] = useState({
    skeletonLoading: false,
    submitLoading: false,
    data: [],
    viewDrawer: false,
    deleteIndex: 0,
  });

  // For form validation
  const formSearch = useForm({
    initialValues: {
      date: "",
    },
  });

  const [user_id, setUser_id] = useState("");
  const [username, setUsername] = useState("");
  const [siteName, setSiteName] = useState("");
  const [managerName, setManagerName] = useState("");
  const [loggedInTime, setLoggedInTime] = useState("");
  const [LoggedOutTime, setLoggedOutTime] = useState("");
  const [totalDuration, setTotalDuration] = useState(0);
  const [status, setStatus] = useState("");

  // For initial setting data
  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (mounted) {
        // For get all the tax data list
        const response = await handleGetConnectLogsToday(6);
        // On Response setting the data to variable
        if (response.status === 200) {
          setVariables({
            ...variables,
            data: response.data.data.reverse(),
            skeletonLoading: false,
          });
          const datas = dataSlice({
            data: response.data.data,
            page: 1,
            total: 10,
          });
          setSortedData(datas);
          setUser_id(response.data.data[0].user.value);
          setUsername(
            response.data.data[0].user.firstname +
              "" +
              response.data.data[0].user.lastname
          );
          setSiteName(response.data.data[0].user.field_executives[0].site.name);
          setManagerName(
            response.data.data[0].user.field_executives[0].manager.firstname
          );
          setLoggedInTime(response.data.data[0].user.attendances[0].start_time);
          setLoggedOutTime(response.data.data[0].user.attendances[0].end_time);
          setTotalDuration(
            response.data.data[0].user.attendances[0].total_duration
          );
          setStatus(response.data.data[0].user.attendances[0].status);
        }
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  // Table data arrange by using function and loop throw each data range to table body
  const rows = sortedData.map((row, index) => (
    <tr key={index}>
      <td>{activePage * total - total + index + 1}</td>
      <td>{(row.start_percentage * 100).toFixed(2)}</td>
      <td>{tConvert(row.start_time).toString()}</td>
      <td>{(row.end_percentage * 100).toFixed(2)}</td>
      <td>{tConvert(row.end_time).toString()}</td>
      <td>{toHoursAndMinutes(row.total_duration)}</td>
      <td>
        {row.status === "Completed" ? (
          <Badge color="green">{row.status}</Badge>
        ) : (
          <Badge color="red">{row.status}</Badge>
        )}
      </td>
      {/* For action drop down edit and delete the data */}
      <td>
        <Group spacing={0} position="left">
          <ActionIcon
            onClick={() => openConfirmModalDelete(row.value)}
            color="red"
          >
            <Trash size={18} />
          </ActionIcon>
        </Group>
      </td>
    </tr>
  ));

  //For delete confirm modal show Delete
  const openConfirmModalDelete = (e) => {
    var datas = variables.data.find((img) => img.value === e);
    setVariables({ ...variables, deleteIndex: e });
    modals.openConfirmModal({
      title: "Delete Confirmation",
      children: (
        <Text size="sm">
          Are you sure you want to delete the attendance of{" "}
          <b>{datas.user.firstname}</b> ?
        </Text>
      ),
      labels: { confirm: "Confirm", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => handleConfirmDelete(e),
    });
  };

  //   For delete db data from table and db
  const handleConfirmDelete = async (e) => {
    const response = await handleDeleteConnectLogs(e);
    // Check the response for notification and actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Logs deleted successfully",
      });
      setVariables({
        ...variables,
        submitLoading: false,
        addDrawer: false,
        data: response.data.data.reverse(),
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setSortedData(datas);
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  const handleSearchAttendance = async (e) => {
    const req = {
      date1: new Date(dateRange[0]),
      date2: new Date(dateRange[1]),
      user_id: user_id,
    };
    setVariables({ ...variables, submitLoading: true });
    setLoadingData(true);
    const response = await handleSearchConnectLogs(req);
    console.log(response.data.data);
    if (response.status === 200) {
      setVariables({
        ...variables,
        data: response.data.data.reverse(),
        skeletonLoading: false,
      });
      const datas = dataSlice({
        data: response.data.data,
        page: 1,
        total: 10,
      });
      setSortedData(datas);
      setLoggedInTime(response.data.data[0].user.attendances[0].start_time);
      setLoggedOutTime(response.data.data[0].user.attendances[0].end_time);
      setTotalDuration(
        response.data.data[0].user.attendances[0].total_duration
      );
      setLoadingData(false);
    }
  };

  return (
    <div>
      {/* For breadcrumbs */}
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <BreadCrumb Text="Disconnected Logs" Title="Attendance" />
      </Skeleton>
      {/* Main start here */}
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <Card className="border">
          <Group mb={10} position="right">
            <Text style={{ color: "#3d3d3d", fontSize: 18 }}>
              {status === "Logged In" ? (
                <Badge size="lg" radius="sm" color="green">
                  {status}
                </Badge>
              ) : (
                <Badge size="lg" radius="sm" color="red">
                  {status}
                </Badge>
              )}
            </Text>
          </Group>
          <Grid>
            <Grid.Col md={4} lg={4}>
              <Paper withBorder p="md">
                <Text style={{ color: "#3d3d3d", fontSize: 16 }}>
                  User Name:{" "}
                </Text>
                <Text style={{ color: "#3d3d3d", fontSize: 14 }}>
                  {username}
                </Text>
              </Paper>
            </Grid.Col>
            <Grid.Col md={4} lg={4}>
              <Paper withBorder p="md">
                <Text style={{ color: "#3d3d3d", fontSize: 16 }}>
                  Site Name:{" "}
                </Text>
                <Text style={{ color: "#3d3d3d", fontSize: 14 }}>
                  {siteName !== "" ? siteName : "Not Available"}
                </Text>
              </Paper>
            </Grid.Col>
            <Grid.Col md={4} lg={4}>
              <Paper withBorder p="md">
                <Text style={{ color: "#3d3d3d", fontSize: 16 }}>
                  Site Manager:{" "}
                </Text>
                <Text style={{ color: "#3d3d3d", fontSize: 14 }}>
                  {managerName}
                </Text>
              </Paper>
            </Grid.Col>
            <Grid.Col md={4} lg={4}>
              <Paper withBorder p="md">
                <Text style={{ color: "#3d3d3d", fontSize: 16 }}>
                  Logged In Time:{" "}
                </Text>
                <Text style={{ color: "#3d3d3d", fontSize: 14 }}>
                  {loggedInTime !== null
                    ? tConvert(loggedInTime.toString())
                    : "--"}
                </Text>
              </Paper>
            </Grid.Col>
            <Grid.Col md={4} lg={4}>
              <Paper withBorder p="md">
                <Text style={{ color: "#3d3d3d", fontSize: 16 }}>
                  Logged Out Time:{" "}
                </Text>
                <Text style={{ color: "#3d3d3d", fontSize: 14 }}>
                  {LoggedOutTime !== null
                    ? tConvert(LoggedOutTime.toString())
                    : "--"}
                </Text>
              </Paper>
            </Grid.Col>
            <Grid.Col md={4} lg={4}>
              <Paper withBorder p="md">
                <Text style={{ color: "#3d3d3d", fontSize: 16 }}>
                  Total Duration:{" "}
                </Text>
                <Text style={{ color: "#3d3d3d", fontSize: 14 }}>
                  {toHoursAndMinutes(totalDuration)}
                </Text>
              </Paper>
            </Grid.Col>
          </Grid>
        </Card>
        <Card className="border">
          <ScrollArea>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Text weight={600} align="center">
                {currentDate} Out of Fence Area Logs
              </Text>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 20,
              }}
            >
              {/* For search the table data input forms */}
              <TextInput
                variant="filled"
                placeholder="Search by any field"
                mb="md"
                size="xs"
                value={search}
                icon={<Search size={14} />}
                onChange={async (e) => {
                  // On change search ofr the data that is enter
                  setSearch(e.currentTarget.value);
                  setPage(1);
                  const datas = await dataSearch({
                    data: variables.data,
                    value: e.currentTarget.value,
                    activePage: activePage,
                    total: total,
                  });
                  setSortedData(datas);
                  setRefreshTable(new Date());
                }}
                sx={{ width: 250 }}
              />
              <div>
                <Group spacing="xs">
                  {/* For export the the table data to pdf and excels */}
                  <Text>Exports :</Text>
                  <CSVLink
                    data={variables.data}
                    headers={[
                      { label: "value", key: "value" },
                      { label: "name", key: "name" },
                      { label: "createdAt", key: "createdAt" },
                      { label: "updatedAt", key: "updatedAt" },
                    ]}
                    filename="location_category.csv"
                    className={classes.pdfExcel}
                  >
                    <img
                      src={excel}
                      alt="excel"
                      width="25"
                      style={{ margin: "2px" }}
                    />
                  </CSVLink>
                  {/* Drawer open for adding new tax data */}
                </Group>
              </div>
            </div>
            {/* Table data view */}
            <Table verticalSpacing="sm">
              {/* Table header defines */}
              <thead>
                <tr>
                  <th>Sl.No</th>
                  <th>FenceOut Battery(%)</th>
                  <th>FenceOut Time</th>
                  <th>FenceIn Battery(%)</th>
                  <th>FenceIn Time</th>
                  <th>Total Duration</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              {rows.length > 0 ? (
                <tbody>{rows}</tbody>
              ) : (
                <>
                  {loadingData === true ? (
                    <tbody>
                      <tr>
                        <td colSpan={6}>
                          <div style={{ textAlign: "center" }}>
                            <Loader size="md" color="#59117f" variant="bars" />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan={6}>
                          <div style={{ textAlign: "center" }}>
                            <Text weight={500} align="center">
                              Nothing found
                            </Text>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </>
              )}
            </Table>
          </ScrollArea>
          {/* For display the pagination and no of per pages list */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: 15,
            }}
          >
            {/* For number of rows display in table */}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Text size="sm" className="zc-pr-3 zc-pt-1">
                Per Page
              </Text>
              <NativeSelect
                size="xs"
                onChange={async (e) => {
                  setTotal(Number(e.currentTarget.value));
                  setPage(1);
                  const datas = await dataSlice({
                    data: variables.data,
                    page: 1,
                    total: Number(e.currentTarget.value),
                  });
                  setSortedData(datas);
                  setRefreshTable(new Date());
                }}
                data={["10", "20", "50", "100"]}
                rightSectionWidth={20}
                sx={{ width: 70 }}
              />
            </div>
            {/* For pagination */}
            <Pagination
              size="xs"
              page={activePage}
              onChange={async (e) => {
                setPage(Number(e));
                const datas = await dataSlice({
                  data: variables.data,
                  page: Number(e),
                  total: total,
                });
                setSortedData(datas);
                setRefreshTable(new Date());
              }}
              total={Math.ceil(variables.data.length / total)}
              color="zevcore"
            />
          </div>
          {variables.data.length > 0 ? (
            <>
              <Text mt={5} align="right" size="sm" color="green">
                Last updated on:&nbsp;
                {new Date(
                  variables.data.reduce(function (r, a) {
                    return r.updatedAt > a.updatedAt ? r : a;
                  }).updatedAt
                ).toLocaleString("en-UK")}
              </Text>
            </>
          ) : null}
        </Card>
      </Skeleton>
    </div>
  );
}
export default DisconnectionLogs;
