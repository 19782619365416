import React, { useState } from "react";
import BreadCrumb from "../../../components/breadcrumbs/BreadCrumb";
import { Space, Skeleton, useMantineTheme } from "@mantine/core"; //For import mantine required functions and theme //for import mantine required functions and theme

function NotificationList() {
  const [skeletonLoading, setSkeletonLoading] = useState(false);
  return (
    <div>
      <Skeleton
        height="100%"
        width="100%"
        radius="md"
        visible={skeletonLoading}
      >
        <BreadCrumb Text="Notifications" Title="Notifications" />
      </Skeleton>
      <Space h="md" />
    </div>
  );
}

export default NotificationList;
