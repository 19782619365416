import {
  Avatar,
  Button,
  Card,
  Grid,
  NumberInput,
  Paper,
  PasswordInput,
  Skeleton,
  Space,
  Text,
  TextInput,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import ManagerBreadCrumb from "../../../components/breadcrumbs/ManagerBreadCrumb";
import { useForm } from "@mantine/form";
import { handleEditUser, handleGetOneUser } from "./settingsApi";
import { showNotification, updateNotification } from "@mantine/notifications";
import { Check, X } from "tabler-icons-react";

function ManagerProfile() {
  const [token, setToken] = useState(localStorage.getItem("token")); //get saved local storage data
  const [username, setUsername] = useState();
  const [email, setEmail] = useState();
  const [variables, setVariables] = useState({
    skeletonLoading: true,
    submitLoading: false,
    data: "",
    bulkDrawer: false,
    imageDrawer: false,
    deleteIndex: 0,
  });

  // useEffect
  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      const config = {
        headers: {
          "x-access-token": token,
        },
      };
      const response = await handleGetOneUser();
      if (response.status == 200) {
        var datas = response.data;
        form.setFieldValue("firstname", datas.firstname);
        form.setFieldValue("email", datas.email);
        form.setFieldValue("phone_number", Number(datas.phone_number));
        form.setFieldValue("value", Number(datas.value));
        setUsername(datas.username);
        setEmail(datas.email);
        setVariables({ ...variables, skeletonLoading: false });
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  const form = useForm({
    initialValues: {
      value: "",
      firstname: "",
      email: "",
      phone_number: "",
      password: "",
      confirmPassword: "",
    },
    validate: {
      firstname: (value) =>
        value.length < 5 ? "Name must have at least 5 letters" : null,
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
      phone_number: (value) =>
        value < 10 ? "phone number must be at least 10 to register" : null,
      confirmPassword: (value, values) =>
        value !== values.password ? "Passwords did not match" : null,
    },
  });

  // Form values for adding state values
  const formImage = useForm({
    initialValues: {
      value: "",
      image: "",
    },
  });

  const handleUpdateUser = async (e) => {
    console.log(e);
    setVariables({ ...variables, submitLoading: true });
    showNotification({
      loading: true,
      id: "load-data",
      title: `Saving...`,
      message: "Waiting for response",
      autoclose: 5000,
      style: { borderRadius: 10 },
    });
    const response = await handleEditUser(e);

    if (response.status == 200) {
      setVariables({ ...variables, submitLoading: false });
      updateNotification({
        id: "load-data",
        color: "teal",
        title: "Data Save",
        message: "User Field Updated Successfully",
        icon: <Check />,
      });
    } else {
      setVariables({ ...variables, submitLoading: false });
      updateNotification({
        id: "load-data",
        color: "red",
        title: "Data Save Error",
        message: "Some error accur",
        icon: <X />,
      });
    }
  };

  // Image Variables
  const [id_value, setIdValue] = useState();
  const [imageTitle, setImageTitle] = useState();
  const [profileimage, SetProfileimage] = useState("");
  const [loadingState, setLoadingState] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const openUpload = (e) => {
    console.log(variables.data);
    setVariables({ ...variables, submitLoading: true });
    var datas = variables.data;
    setImageTitle(datas.firstname);
    SetProfileimage(datas.image);
    setIdValue(e);
    setVariables({ ...variables, deleteIndex: e, imageDrawer: true });
  };

  // upload admin image values
  const handleUpload = async (e) => {};

  return (
    <div>
      <Skeleton
        height="100%"
        width="100%"
        radius="md"
        visible={variables.skeletonLoading}
      >
        <ManagerBreadCrumb
          Text="Profile"
          Title="Settings"
          titleLink="/manager/Profile"
        />
      </Skeleton>
      <Space h="md" />
      {/* Main page start from here */}
      <Skeleton
        height="100%"
        width="100%"
        radius="md"
        sx={(theme) => ({
          boxShadow:
            "0 1px 3px rgb(0 0 0 / 5%), rgb(0 0 0 / 5%) 0px 10px 15px -5px, rgb(0 0 0 / 4%) 0px 7px 7px -5px",
        })}
        visible={variables.skeletonLoading}
      >
        <Card className="border">
          <Grid>
            <Grid.Col xs={12}>
              <form
                onSubmit={formImage.onSubmit((values) => handleUpload(values))}
              >
                <Paper
                  radius="md"
                  withBorder
                  p="lg"
                  sx={(theme) => ({
                    backgroundColor:
                      theme.colorScheme === "dark"
                        ? theme.colors.dark[8]
                        : theme.white,
                  })}
                >
                  <Avatar
                    // src={URL_CONSTANTS.REPRESENTATIVE_IMAGE + profileimage}
                    onClick={() => openUpload(1)}
                    size={120}
                    radius={120}
                    mx="auto"
                  />
                  <Text ta="center" fz="lg" weight={500} mt="md">
                    {username}
                  </Text>
                  <Text ta="center" c="dimmed" fz="sm">
                    {email}
                  </Text>

                  <Button
                    loading={loadingState}
                    mt={10}
                    fullWidth
                    radius="md"
                    onClick={() => openUpload(1)}
                    size="sm"
                    variant="outline"
                    color="#59117f"
                  >
                    Upload New Photo
                  </Button>
                </Paper>
              </form>
            </Grid.Col>
          </Grid>
        </Card>
        <Card className="border" p="lg">
          <form onSubmit={form.onSubmit((values) => handleUpdateUser(values))}>
            <Grid>
              <Grid.Col md={12} lg={12}>
                <Grid>
                  <Grid.Col md={4} lg={4}>
                    <TextInput
                      variant="filled"
                      mt={2}
                      required
                      value={form.values.firstname}
                      label=" Name"
                      placeholder="Enter Name"
                      {...form.getInputProps("firstname")}
                    />
                  </Grid.Col>
                  <Grid.Col md={4} lg={4}>
                    <TextInput
                      variant="filled"
                      required
                      value={form.values.email}
                      label=" Email"
                      placeholder="Enter Email"
                      {...form.getInputProps("email")}
                    />
                  </Grid.Col>
                  <Grid.Col md={4} lg={4}>
                    <NumberInput
                      variant="filled"
                      required
                      maxLength={10}
                      value={form.values.phone_number}
                      label=" Phone Number"
                      placeholder="Enter Phone Number"
                      {...form.getInputProps("phone_number")}
                    />
                  </Grid.Col>
                  <Grid.Col md={4} lg={4}>
                    <PasswordInput
                      value={form.values.password}
                      label=" Password"
                      placeholder="Enter Password"
                      {...form.getInputProps("password")}
                    />
                  </Grid.Col>
                  <Grid.Col md={4} lg={4}>
                    <PasswordInput
                      value={form.values.confirmPassword}
                      label="Confirm Password"
                      placeholder=" Confirm Password"
                      {...form.getInputProps("confirmPassword")}
                    />
                  </Grid.Col>
                  <Grid.Col md={12} lg={12}>
                    <Button
                      type="submit"
                      color="zevcore"
                      loading={variables.submitLoading}
                    >
                      Submit
                    </Button>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
            </Grid>
          </form>
        </Card>
      </Skeleton>
    </div>
  );
}

export default ManagerProfile;
