import React, { useState, useEffect } from "react";
import BreadCrumb from "../../../components/breadcrumbs/BreadCrumb";
import { Card, Grid, Center, Text } from "@mantine/core/cjs";
import {
  AdjustmentsAlt,
  Pencil,
  Percentage,
  TriangleSquareCircle,
  UserExclamation,
  UserPlus,
} from "tabler-icons-react"; // For particular icons that has used in this page from tabler icon
import { Container, Box } from "@mantine/core"; //For import mantine required functions and theme //for import mantine required functions and theme
import useStyles from "../../../components/styles/styles";
import { useNavigate } from "react-router-dom"; // For import the react dom for navigation components // for import react dom navigation components
import packageJson from "../../../../package.json";

function Manage() {
  let navigate = useNavigate();
  const links = [
    { label: "Profile Setting", icon: UserExclamation, link: "/profile" },
  ];

  const { classes } = useStyles();

  return (
    <div>
      <BreadCrumb Text="Setting" />
      <div
        justify="center"
        align="center"
        style={{ marginBottom: 15, marginTop: 40 }}
      ></div>
      <Container style={{ width: "55%", margin: "auto" }}>
        <Grid justify="center" align="center">
          {links.map((item, index) => (
            <Grid.Col key={item.label} span="auto">
              <div
                className={classes.SettingBox2}
                onClick={() => {
                  navigate(item.link);
                }}
              >
                <Pencil className={classes.editIcon2} size={15} />
                <Box>
                  <item.icon size={50} strokeWidth="0.9" />
                  <Text sx={{ fontSize: "12px" }}>{item.label}</Text>
                </Box>
              </div>
            </Grid.Col>
          ))}
        </Grid>
      </Container>
      <div style={{ position: "absolute", bottom: 10, right: 0 }}>
        <Text size="sm" color="dimmed" mt={2}>
          © {new Date().getFullYear()}. Zevcore Private Limited. All Rights
          Reserved. Version - {packageJson.version.toString()} &nbsp;
        </Text>
      </div>
    </div>
  );
}

export default Manage;
