import React, { useState, useEffect, useRef, useCallback } from "react";
import BreadCrumb from "../../../components/breadcrumbs/BreadCrumb";
// Mantine library
import { useForm } from "@mantine/form";
import {
  Space,
  Card,
  Button,
  NumberInput,
  Text,
  TextInput,
  Skeleton,
  Avatar,
  PasswordInput,
  Grid,
  Paper,
  Modal,
  Group,
  FileButton,
  useMantineTheme,
} from "@mantine/core"; //For import mantine required functions and theme //for import mantine required functions and theme
// For image Crop
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useDebounceEffect } from "../../../components/common/userDebounceEffect.ts";
import {
  handleAdminProfileUpdate,
  handleGetOneUser,
  handleUploadAdminImage,
  userImage_Url,
} from "./settingsApi"; // For connect to the electron backend from this helper apis
import notificationHelper from "../../../services/notification";

// RichtextEditor
function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 10, // make it 10 for smaller crop ratio
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

function Profile() {
  const theme = useMantineTheme();
  const [token, setToken] = useState(localStorage.getItem("token")); //get saved local storage data
  const [userRole, setUserRole] = useState(localStorage.getItem("role"));
  const [URL, setURL] = useState(process.env.REACT_APP_BACKEND_URL);
  const [URLFILE, setURLFILE] = useState(process.env.REACT_APP_FILE);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [skeletonLoading, setSkeletonLoading] = useState(true);
  const [username, setUsername] = useState();
  const [email, setEmail] = useState();
  const [variables, setVariables] = useState({
    skeletonLoading: true,
    submitLoading: false,
    data: "",
    bulkDrawer: false,
    imageDrawer: false,
    deleteIndex: 0,
  });

  const [id, setId] = useState("");
  const form = useForm({
    initialValues: {
      value: 1,
      firstname: "",
      email: "",
      phone_number: "",
      password: "",
      image: "profile.png",
      confirmPassword: "",
      status: "Active",
    },
    validate: {
      firstname: (value) =>
        value.length < 5 ? "Name must have at least 5 letters" : null,
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
      phone_number: (value) =>
        value < 10 ? "phone number must be at least 10 to register" : null,
      confirmPassword: (value, values) =>
        value !== values.password ? "Passwords did not match" : null,
    },
  });

  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      const config = {
        headers: {
          "x-access-token": token,
        },
      };
      const response = await handleGetOneUser();
      if (response.status == 200) {
        var datas = response.data;
        form.setFieldValue("firstname", datas.firstname);
        form.setFieldValue("email", datas.email);
        form.setFieldValue("phone_number", Number(datas.phone_number));
        setImage(datas.image);
        setId(datas.value);
        setSkeletonLoading(false);
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  const handleUpdateUser = async (e) => {
    // Set notification of saving and loader effects
    setSubmitLoading(true);
    // showNotification({
    //   loading: true,
    //   id: "load-data",
    //   title: `Saving...`,
    //   message: "Waiting for response",
    //   autoclose: 5000,
    //   style: { borderRadius: 10 },
    // });

    // Main axios part for sending data to backend adding user data
    const response = await handleAdminProfileUpdate(e);

    if (response.status == 200) {
      setSubmitLoading(false);
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Profile uploaded successfully",
      });
    } else {
      setSubmitLoading(false);
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed",
        message: "Profile could not be updated.",
      });
    }
  };

  // Image Variables
  const [id_value, setIdValue] = useState();
  const [imageTitle, setImageTitle] = useState();
  const [profileimage, SetProfileimage] = useState("");
  const [loadingState, setLoadingState] = useState(false);
  const [disabled, setDisabled] = useState(true);

  // Crop Image
  const openRef = useRef();
  const [image, setImage] = useState("");
  const [upImg, setUpImg] = useState("");
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(1 / 1);
  const [cropConfig, setCropConfig] = useState({
    unit: "%",
    width: 50,
    aspect: 9 / 16,
  });
  const [crop, setCrop] = useState({
    unit: "%",
    width: 50,
    aspect: 24 / 12,
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const ref = useRef();
  const ref45 = useRef();
  const changeHandler = (e) => {
    if (e) {
      console.log(e);
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e);
      ref.current.value = "";
    }
  };
  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        const TO_RADIANS = Math.PI / 180;
        const image = imgRef.current;
        const crop = completedCrop;
        const canvas = previewCanvasRef.current;
        const scale = 1;
        const rotate = 1;

        const ctx = canvas.getContext("2d");

        if (!ctx) {
          throw new Error("No 2d context");
        }

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        // devicePixelRatio slightly increases sharpness on retina devices
        // at the expense of slightly slower render times and needing to
        // size the image back down if you want to download/upload and be
        // true to the images natural size.
        const pixelRatio = window.devicePixelRatio;
        // const pixelRatio = 1

        canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
        canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

        ctx.scale(pixelRatio, pixelRatio);
        ctx.imageSmoothingQuality = "high";

        const cropX = crop.x * scaleX;
        const cropY = crop.y * scaleY;

        const rotateRads = rotate * TO_RADIANS;
        const centerX = image.naturalWidth / 2;
        const centerY = image.naturalHeight / 2;

        ctx.save();

        // 5) Move the crop origin to the canvas origin (0,0)
        ctx.translate(-cropX, -cropY);
        // 4) Move the origin to the center of the original position
        ctx.translate(centerX, centerY);
        // 3) Rotate around the origin
        ctx.rotate(rotateRads);
        // 2) Scale the image
        ctx.scale(scale, scale);
        // 1) Move the center of the image to the origin (0,0)
        ctx.translate(-centerX, -centerY);
        ctx.drawImage(
          image,
          0,
          0,
          image.naturalWidth,
          image.naturalHeight,
          0,
          0,
          image.naturalWidth,
          image.naturalHeight
        );
        ctx.restore();
        const base64Image = canvas.toDataURL("image/jpeg");
        setImage(base64Image);
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const openUpload = (e) => {
    console.log(variables.data);
    setVariables({ ...variables, submitLoading: true });
    var datas = variables.data;
    setImageTitle(datas.firstname);
    SetProfileimage(datas.image);
    setIdValue(e);
    setVariables({ ...variables, deleteIndex: e, imageDrawer: true });
  };

  // upload admin image values
  // upload admin image values
  const handleUpload = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    var req = {
      image: image,
      value: id_value,
    };

    e = { ...e, ...req };
    const response = await handleUploadAdminImage(e);
    // Check for response for actions
    console.log(response.data.data.image);
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Image uploaded successfully",
      });
      setImage(response.data.data.image);
      setVariables({ ...variables, submitLoading: false, imageDrawer: false });
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false, imageDrawer: false });
    }
  };

  // Form values for adding state values
  const formImage = useForm({
    initialValues: {
      value: "",
      image: "",
    },
  });

  return (
    <div>
      <Skeleton
        height="100%"
        width="100%"
        radius="md"
        visible={skeletonLoading}
      >
        <BreadCrumb Text="Profile" Title="Settings" titleLink="/manages" />
      </Skeleton>
      <Space h="md" />
      {/* Main page start from here */}
      <Skeleton
        height="100%"
        width="100%"
        radius="md"
        sx={(theme) => ({
          boxShadow:
            "0 1px 3px rgb(0 0 0 / 5%), rgb(0 0 0 / 5%) 0px 10px 15px -5px, rgb(0 0 0 / 4%) 0px 7px 7px -5px",
        })}
        visible={skeletonLoading}
      >
        <Card className="border">
          <Grid>
            <Grid.Col xs={12}>
              <form
                onSubmit={formImage.onSubmit((values) => handleUpload(values))}
              >
                <Paper
                  radius="md"
                  withBorder
                  p="lg"
                  sx={(theme) => ({
                    backgroundColor:
                      theme.colorScheme === "dark"
                        ? theme.colors.dark[8]
                        : theme.white,
                  })}
                >
                  <Avatar
                    src={userImage_Url + image}
                    onClick={() => openUpload(1)}
                    size={120}
                    radius={120}
                    mx="auto"
                  />
                  <Text ta="center" fz="lg" weight={500} mt="md">
                    {username}
                  </Text>
                  <Text ta="center" c="dimmed" fz="sm">
                    {email}
                  </Text>

                  <Button
                    loading={loadingState}
                    mt={10}
                    fullWidth
                    radius="md"
                    onClick={() => openUpload(1)}
                    size="sm"
                    variant="outline"
                    color="#59117f"
                  >
                    Upload New Photo
                  </Button>
                </Paper>
              </form>
            </Grid.Col>
          </Grid>
        </Card>
        <Card p="lg">
          <form onSubmit={form.onSubmit((values) => handleUpdateUser(values))}>
            <Grid>
              <Grid.Col md={12} lg={12}>
                <Grid>
                  <Grid.Col md={4} lg={4}>
                    <TextInput
                      variant="filled"
                      mt={2}
                      required
                      value={form.values.firstname}
                      label=" Name"
                      placeholder="Enter Name"
                      {...form.getInputProps("firstname")}
                    />
                  </Grid.Col>
                  <Grid.Col md={4} lg={4}>
                    <TextInput
                      variant="filled"
                      required
                      value={form.values.email}
                      label=" Email"
                      placeholder="Enter Email"
                      {...form.getInputProps("email")}
                    />
                  </Grid.Col>
                  <Grid.Col md={4} lg={4}>
                    <NumberInput
                      variant="filled"
                      required
                      maxLength={10}
                      value={form.values.phone_number}
                      label=" Phone Number"
                      placeholder="Enter Phone Number"
                      {...form.getInputProps("phone_number")}
                    />
                  </Grid.Col>
                  <Grid.Col md={4} lg={4}>
                    <PasswordInput
                      value={form.values.password}
                      label=" Password"
                      placeholder="Enter Password"
                      {...form.getInputProps("password")}
                    />
                  </Grid.Col>
                  <Grid.Col md={4} lg={4}>
                    <PasswordInput
                      value={form.values.confirmPassword}
                      label="Confirm Password"
                      placeholder=" Confirm Password"
                      {...form.getInputProps("confirmPassword")}
                    />
                  </Grid.Col>
                  <Grid.Col md={12} lg={12}>
                    <Button
                      type="submit"
                      color="zevcore"
                      loading={submitLoading}
                    >
                      Submit
                    </Button>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
            </Grid>
          </form>
        </Card>
      </Skeleton>

      {/* Image Modal */}
      <Modal
        opened={variables.imageDrawer}
        onClose={() => setVariables({ ...variables, imageDrawer: false })}
        title={imageTitle}
        overlayProps={{
          color:
            theme.colorScheme === "dark"
              ? theme.colors.dark[9]
              : theme.colors.gray[2],
          opacity: 0.55,
          blur: 3,
        }}
      >
        <form onSubmit={formImage.onSubmit((values) => handleUpload(values))}>
          <Grid style={{ padding: "10px" }}>
            <Grid.Col md={12} lg={12} mt={1}>
              {/* <Text>Upload State Image</Text> */}
            </Grid.Col>
            <Grid.Col md={12} lg={12} mt={1}>
              {/* For image cropper */}
              {/* For cropper */}
              {upImg !== "" && upImg !== null ? (
                <>
                  <ReactCrop
                    crop={crop}
                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                    onComplete={(c) => setCompletedCrop(c)}
                    aspect={aspect}
                  >
                    <img
                      ref={imgRef}
                      alt="Crop me"
                      src={upImg}
                      style={{
                        transform: `scale(${scale}) rotate(${rotate}deg)`,
                      }}
                      onLoad={onImageLoad}
                    />
                  </ReactCrop>
                  <div>
                    {previewCanvasRef != null ? (
                      <canvas
                        ref={previewCanvasRef}
                        // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                        style={{
                          width: Math.round(completedCrop?.width ?? 0),
                          height: Math.round(completedCrop?.height ?? 0),
                          marginBottom: 50,
                          display: "none",
                        }}
                      />
                    ) : null}

                    <Group position="right" mt="md" mb={20}>
                      <Button
                        type="submit"
                        color="zevcore"
                        onClick={() => {
                          setUpImg("");
                          setImage("");
                        }}
                      >
                        Clear Image
                      </Button>
                    </Group>
                  </div>
                </>
              ) : (
                // For selecting cropping image dropdown
                <div
                  style={{
                    marginTop: 15,
                    position: "relative",
                    marginBottom: 30,
                  }}
                >
                  <Paper
                    p="lg"
                    sx={(theme) => ({
                      backgroundColor:
                        theme.colorScheme === "dark"
                          ? theme.colors.dark[8]
                          : theme.white,
                    })}
                  >
                    <Avatar
                      src={userImage_Url + image}
                      size={150}
                      radius={100}
                      withBorder
                      mx="auto"
                    />
                    <Text ta="center" fz="lg" weight={500} mt="md">
                      {imageTitle}
                    </Text>

                    <Group position="center">
                      <FileButton
                        resetRef={ref}
                        onChange={changeHandler}
                        accept={[
                          "image/png",
                          "image/jpeg",
                          "image/sgv+xml",
                          "image/gif",
                        ]}
                      >
                        {(props) => (
                          <Button mt={20} {...props}>
                            Select Image
                          </Button>
                        )}
                      </FileButton>
                    </Group>
                  </Paper>
                </div>
              )}
            </Grid.Col>
            <Button
              loading={loadingState}
              fullWidth
              type="submit"
              size="sm"
              variant="outline"
              color="zevcore"
            >
              Update Photo
            </Button>
          </Grid>
        </form>
      </Modal>
      {/* Image Modal */}
    </div>
  );
}

export default Profile;
