/**

    * Application Name: Zevcore unolo Backend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 17.05.2023
    * Last Modified Date: 17.05.2023
    * Developer Name: Anand_Krishna

**/

import {
  ActionIcon,
  AppShell,
  Burger,
  Header,
  MediaQuery,
  Navbar,
  ScrollArea,
  useMantineTheme,
  TextInput,
  Menu,
  Text,
  Group,
  rem,
  createStyles,
} from "@mantine/core"; //for import mantine required functions and theme
import React, { useState } from "react";
import {
  Home,
  Search,
  Settings,
  UserExclamation,
  Logout,
  UserCircle,
  UserCheck,
  MapPin,
  Report,
  UserPlus,
  Route,
  ReportAnalytics,
  Bell,
} from "tabler-icons-react";
import Logo from "../../assets/images/logo21.png";
import { LinksGroup } from "./NavLinks";
import { useLocalStorage } from "@mantine/hooks";
import { ModalsProvider } from "@mantine/modals";
import { useNavigate, Outlet } from "react-router-dom"; // for import react dom navigation components
import { SpotlightProvider, useSpotlight } from "@mantine/spotlight";
import { openConfirmModal } from "@mantine/modals";
const useStyles = createStyles((theme) => ({
  navbar: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.gray,
    paddingBottom: 200,
    height: "auto",
  },

  header: {
    padding: theme.spacing.md,
    paddingTop: 0,
    marginLeft: `calc(${theme.spacing.md} * -1)`,
    marginRight: `calc(${theme.spacing.md} * -1)`,
    color: theme.colorScheme === "dark" ? theme.gray : theme.black,
    borderBottom: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  links: {
    marginLeft: `calc(${theme.spacing.md} * -1)`,
    marginRight: `calc(${theme.spacing.md} * -1)`,
  },

  linksInner: {
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
  },

  footer: {
    marginLeft: `calc(${theme.spacing.md} * -1)`,
    marginRight: `calc(${theme.spacing.md} * -1)`,
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
}));
//for   made mantine theme style change and write custome theme here

function SpotlightControl(props) {
  const spotlight = useSpotlight();
  return (
    <Group position="center">
      <TextInput
        icon={<Search size={18} />}
        size="xs"
        radius="xl"
        placeholder="Search "
        sx={{ marginLeft: 60 }}
        onClick={spotlight.openSpotlight}
        rightSectionWidth={42}
        {...props}
      />
    </Group>
  );
}

const mockdata = [
  { label: "Dashboard", icon: Home, link: "/admin/Dashboard" },
  {
    label: "Daily Attendance",
    icon: UserCheck,
    link: "/admin/site_executive_attendance",
  },
  { label: "Daily Meetings", icon: Route, link: "/admin/meetings" },
  {
    label: "Geo-Fence Sites",
    icon: MapPin,
    initiallyOpened: true,
    links: [
      { label: "Location Category", link: "/admin/location_category" },
      { label: "Add Sites", link: "/admin/add_sites" },
      { label: "View Sites", link: "/admin/view_sites" },
    ],
  },
  {
    label: "Attendance Reports",
    icon: Report,
    initiallyOpened: true,
    links: [
      { label: "Weekly Report", link: "/admin/weekly_site_attendance_report" },
      {
        label: "Monthly Reports",
        link: "/admin/monthly_site_attendance_report",
      },
      { label: "Yearly Reports", link: "/admin/yearly_site_attendance_report" },
    ],
  },
  {
    label: "Meeting Reports",
    icon: ReportAnalytics,
    initiallyOpened: true,
    links: [
      { label: "Weekly Report", link: "/admin/weekly_meetings_report" },
      { label: "Monthly Reports", link: "/admin/monthly_meetings_report" },
      { label: "Yearly Reports", link: "/admin/yearly_meetings_report" },
    ],
  },

  {
    label: "Users",
    icon: UserPlus,
    initiallyOpened: true,
    links: [
      { label: "Managers", link: "/admin/managers" },
      { label: "Site Executives", link: "/admin/executives" },
      { label: "Field Executives", link: "/admin/general_executives" },
    ],
  },
  { label: "Settings", icon: Settings, link: "/admin/profile" },
];

function Sidebar({ children }) {
  const [opened, setOpened] = useState(false);
  const theme = useMantineTheme();

  const [colorScheme, setColorScheme] = useLocalStorage({
    key: "mantine-color-scheme",
    defaultValue: "light",
  });

  const toggleColorScheme = (value) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));

  const dark = colorScheme === "dark";
  const { classes } = useStyles();
  const links = mockdata.map((item) => (
    <LinksGroup {...item} key={item.label} />
  ));

  let navigate = useNavigate();

  const actions = [
    {
      title: "Dashboard",
      onTrigger: () => navigate("/admin/Dashboard"),
    },
    {
      title: "Daily Attendance",
      onTrigger: () => navigate("/admin/site_executive_attendance"),
    },
    {
      title: "Daily Meetings",
      onTrigger: () => navigate("/admin/meetings"),
    },
    {
      title: "Location Category",
      onTrigger: () => navigate("/admin/location_category"),
    },
    {
      title: "Add Sites",
      onTrigger: () => navigate("/admin/add_sites"),
    },
    {
      title: "View Sites",
      onTrigger: () => navigate("/admin/view_sites"),
    },
    {
      title: "Weekly Attendance Report",
      onTrigger: () => navigate("/admin/weekly_site_attendance_report"),
    },
    {
      title: "Monthly Attendance Report",
      onTrigger: () => navigate("/admin/monthly_site_attendance_report"),
    },
    {
      title: "Yearly Attendance Report",
      onTrigger: () => navigate("/admin/yearly_site_attendance_report"),
    },
    {
      title: "Weekly Meeting Report",
      onTrigger: () => navigate("/admin/weekly_meetings_report"),
    },
    {
      title: "Monthly Meeting Report",
      onTrigger: () => navigate("/admin/monthly_meetings_report"),
    },
    {
      title: "Yearly Meeting Report",
      onTrigger: () => navigate("/admin/yearly_meetings_report"),
    },
    {
      title: "Managers",
      onTrigger: () => navigate("/admin/managers"),
    },
    {
      title: "Site Executives",
      onTrigger: () => navigate("/admin/executives"),
    },
    {
      title: "Field Executives",
      onTrigger: () => navigate("/admin/general_executives"),
    },
    {
      title: "Notifications",
      onTrigger: () => navigate("/admin/notification_lists"),
    },
    {
      title: "Profile",
      onTrigger: () => navigate("/admin/profile"),
    },
  ];

  return (
    <ModalsProvider>
      <div>
        <AppShell
          styles={(theme) => ({
            main: {
              backgroundColor:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[8]
                  : theme.colors.gray[0],
            },
          })}
          // navbarOffsetBreakpoint controls when navbar should no longer be offset with padding-left
          navbarOffsetBreakpoint="sm"
          // fixed prop on AppShell will be automatically added to Header and Navbar
          fixed
          navbar={
            <Navbar
              p="md"
              // Breakpoint at which navbar will be hidden if hidden prop is true
              hiddenBreakpoint="sm"
              // Hides navbar when viewport size is less than value specified in hiddenBreakpoint
              hidden={!opened}
              // when viewport size is less than theme.breakpoints.sm navbar width is 100%
              // viewport size > theme.breakpoints.sm – width is 300px
              // viewport size > theme.breakpoints.lg – width is 400px
              width={{ sm: 300, lg: 250 }}
            >
              <Navbar.Section
                grow
                className={classes.links}
                component={ScrollArea}
                scrollbarSize={2}
              >
                <div className={classes.linksInner}>{links}</div>
              </Navbar.Section>
            </Navbar>
          }
          header={
            <Header height={70} p="md">
              {/* Handle other responsive styles with MediaQuery component or createStyles function */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <MediaQuery largerThan="sm" styles={{ display: "none" }}>
                  <Burger
                    opened={opened}
                    onClick={() => setOpened((o) => !o)}
                    size="sm"
                    color={theme.colors.gray[6]}
                    mr="xl"
                  />
                </MediaQuery>

                <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
                  <>
                    <img
                      src={Logo}
                      alt=""
                      style={{ height: "60px", marginLeft: 0 }}
                    />
                    <SpotlightProvider
                      actions={actions}
                      searchIcon={<Search size={18} />}
                      searchPlaceholder="Search..."
                      shortcut={["ctrl + k", "mod + K", "⌘ + K"]}
                      nothingFoundMessage="Nothing found..."
                    >
                      <SpotlightControl />
                    </SpotlightProvider>
                  </>
                </MediaQuery>

                <div
                  style={{
                    marginLeft: "auto",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Menu shadow="md" className="no-drag zc-mr-1" width={200}>
                    <Menu.Target>
                      <ActionIcon
                        className="zc-mt-2 zc-mr-2 zc-ml-2"
                        type="button"
                        onClick={() => navigate("/admin/notification_lists")}
                      >
                        <Bell size={25} />
                      </ActionIcon>
                    </Menu.Target>
                  </Menu>
                  <Menu shadow="md" className="no-drag zc-mr-1" width={200}>
                    <Menu.Target>
                      <ActionIcon
                        className="zc-mt-2 zc-mr-2 zc-ml-2"
                        type="button"
                      >
                        <UserCircle size={25} />
                      </ActionIcon>
                    </Menu.Target>
                    <Menu.Dropdown>
                      <Menu.Item
                        icon={<UserExclamation size={14} />}
                        onClick={() => navigate("/admin/profile")}
                      >
                        Profile Settings
                      </Menu.Item>
                      <Menu.Item
                        // Logout Confirm modal
                        onClick={() => {
                          openConfirmModal({
                            title: "Confirm Logout ",
                            children: (
                              <Text size="sm">
                                This action is so important that you are
                                required to confirm logout. Please click one of
                                these buttons to proceed.
                              </Text>
                            ),

                            labels: {
                              confirm: "Confirm",
                              cancel: "Cancel",
                            },
                            confirmProps: { color: "red" },
                            onCancel: () => console.log("Cancel"),
                            onConfirm: () => {
                              localStorage.clear();
                              navigate("/");
                            },
                          });
                        }}
                        color="red"
                        icon={<Logout size={14} />}
                      >
                        Log Out
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                </div>
              </div>
            </Header>
          }
        >
          <Outlet />
        </AppShell>
      </div>
    </ModalsProvider>
  );
}

export default Sidebar;
