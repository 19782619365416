/**

    * Application Name: Zevcore unolo Backend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 17.05.2023
    * Last Modified Date: 17.05.2023
    * Developer Name: Anand_Krishna

**/

import { url } from "../../../services/url";
import axios from "axios";

export const LoginImageUrl = `${url.baseUrl}public/images/loggedIn/`;
export const executiveImageUrl = `${url.baseUrl}public/images/executives/`;
export const meetingsImageUrl = `${url.baseUrl}public/images/meetings/`;

const fetchCredentials = async () => {
  try {
    // Retrieve the credentials
    const credentials = await localStorage.getItem("token");
    if (credentials) {
      return {
        headers: {
          "x-access-token": credentials,
        },
      };
    } else {
      console.log("No credentials stored");
    }
  } catch (error) {
    console.log("Keychain couldn't be accessed!", error);
  }
  return false;
};

const ReportsUrl = {
  attendance_Url: `${url.apiUrl}/attendance`,
  attendanceSearch_Url: `${url.apiUrl}/attendance_search`,
  meeting_Url: `${url.apiUrl}/meetings`,
  general_executive_Url: `${url.apiUrl}/general_executive`,
  meetingSearch_Url: `${url.apiUrl}/meetings_search`,
};

// For handle get Location Type data list
export const handleGetAllAttendance = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(ReportsUrl.attendance_Url, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete Location Type data list
export const handleDeleteAttendance = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      ReportsUrl.attendance_Url + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle get location search data list
export const handleSearchAllAttendance = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      ReportsUrl.attendanceSearch_Url,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle get Location Type data list
export const handleGetAllMeetings = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(ReportsUrl.meeting_Url, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle get general executive data list
export const handleGetAllGeneralExecutive = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(ReportsUrl.general_executive_Url, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle get general executive data list
export const handleSearchAllMeetings = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(
      ReportsUrl.meetingSearch_Url,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
