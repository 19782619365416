import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Button,
  Card,
  Select,
  Grid,
  Skeleton,
  TextInput,
  MultiSelect,
} from "@mantine/core";
import BreadCrumb from "../../../components/breadcrumbs/BreadCrumb";
import { useForm } from "@mantine/form"; // Mantine form import
import {
  handleEditSite,
  handleGetAllManager,
  handleGetFieldExecutive,
  handleGetLocationType,
  handleGetOneSite,
} from "./geofencingApi";
import notificationHelper from "../../../services/notification";
import { useParams } from "react-router-dom";
import {
  DrawingManager,
  GoogleMap,
  Polygon,
  useJsApiLoader,
} from "@react-google-maps/api";
import EditGeoMaps from "./EditGeoMaps";

const containerStyle = {
  width: "100%",
  height: "650px",
};

const libraries = ["places", "drawing"];

function EditSites() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBMWoaIEUJybmZFkVn7poh96I0d0XhNN4A",
    libraries,
  });

  const [coordinates, setCoordinates] = useState([]);
  const [path, setPath] = useState([]);

  const [state, setState] = useState({
    drawingMode: "polygon",
  });

  const [variables, setVariables] = useState({
    skeletonLoading: false,
    loadingState: false,
  });

  let point = [];

  const polygonRef = useRef(null);
  const listenerRef = useRef([]);
  const [category, setCategory] = useState([]);
  const [executive, setExecutive] = useState([]);
  const [manager, setManager] = useState([]);
  const [executivelist, setExecutivelist] = useState([]);

  const [paths, setPaths] = useState([]);
  const [centerLatitude, setCenterLatitude] = useState(0);
  const [centerLongitude, setCenterLongitude] = useState(0);

  // For form validation
  const formEdit = useForm({
    initialValues: {
      value: "",
      location_name: "",
      category_id: "",
      status: "",
      executive_id: [],
      manager_id: "",
      coordinates: "",
    },
    validate: {
      location_name: (value) =>
        value.length < 1 ? "Location name is required" : null,
    },
  });

  const params = useParams();
  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (mounted) {
        const reg = {
          value: params.value,
        };
        // for fetch location category
        const response = await handleGetLocationType();
        console.log(response);
        if (response.status === 200) {
          var data = response.data.data;
          var clean = data.map((data) => ({
            value: data.value.toString(),
            label: data.name,
          }));
          setCategory(clean);
        }

        const response1 = await handleGetFieldExecutive();
        if (response1.status === 200) {
          var data = response1.data.data;
          var clean = data.map((data) => ({
            value: data.value.toString(),
            label: data.firstname,
          }));
          setExecutive(clean);
        }

        const response2 = await handleGetAllManager();
        if (response2.status === 200) {
          var data = response2.data.data;
          var clean = data.map((data) => ({
            value: data.value.toString(),
            label: data.firstname,
          }));
          setManager(clean);
        }

        const response4 = await handleGetOneSite(params.value);
        console.log(response4);
        if (response4.status === 200) {
          var datas = response4.data.data;
          formEdit.setFieldValue(
            "category_id",
            datas.location_category.value.toString()
          );
          formEdit.setFieldValue("value", datas.value);
          formEdit.setFieldValue("location_name", datas.name);
          formEdit.setFieldValue("status", datas.status);
          formEdit.setFieldValue("manager_id", datas.manager_id.toString());
          formEdit.setFieldValue(
            "executive_id",
            datas.site_executives[0].executive_id.toString()
          );

          var finalArray = datas.site_executives.map(function (obj) {
            return JSON.stringify(obj.executive_id);
          });
          setExecutivelist(finalArray);
          formEdit.setFieldValue("executive_id", finalArray);
          let paths = [];
          datas.site_coordinates.forEach(function (path) {
            paths.push({
              lat: parseFloat(path.latitude),
              lng: parseFloat(path.longitude),
            });
          });
          setPaths(paths);
          setCenterLatitude(paths[0].lat);
          setCenterLongitude(paths[0].lng);
          console.log(paths);
        }
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  const handleUpdate = async (e) => {
    setVariables({ ...variables, submitLoading: true });
    const response = await handleEditSite(e);
    // Check for response for actions
    console.log(response.data);
    // return;
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        title: "Success",
        message: "Site updated successfully",
      });
      var datas = response.data[0];
      formEdit.setFieldValue(
        "category_id",
        datas.location_category.value.toString()
      );
      formEdit.setFieldValue("value", datas.value);
      formEdit.setFieldValue("location_name", datas.name);
      formEdit.setFieldValue("status", datas.status);
      formEdit.setFieldValue("coordinates", datas.site_coordinates[0].value);
      let paths = [];
      datas.site_coordinates.forEach(function (path) {
        paths.push({
          lat: parseFloat(path.latitude),
          lng: parseFloat(path.longitude),
        });
      });
      setCoordinates(paths);
    } else {
      notificationHelper({
        color: "red",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  const onPolygonComplete = React.useCallback(function onPolygonComplete(poly) {
    const polyArray = poly.getPath().getArray();
    let paths = [];
    polyArray.forEach(function (path) {
      paths.push({ lat: path.lat(), lng: path.lng() });
    });
    setPaths(paths);
    console.log(paths);
    setCoordinates(paths);
    point(path);
    poly.setMap(null);
  });

  const options = {
    drawingControl: true,
    drawingControlOptions: {
      drawingMode: ["Polygon"],
    },
    PolygonOptions: {
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
      clickable: true,
      draggable: true,
      editable: true,
      zIndex: 1,
    },
  };

  // Call set path with new edited paths
  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map((latlng) => {
          return { lat: latlng.lat(), lng: latlng.lng() };
        });
      console.log(nextPath);
      setPath(nextPath);
      formEdit.setFieldValue("coordinates", nextPath);
      setState(nextPath);
    }
  });

  const onLoad = useCallback((polygon) => {
    polygonRef.current = polygon;
    const path = polygon.getPath();
    listenerRef.current.push(
      path.addListener("set_at", onEdit),
      path.addListener("insert_at", onEdit),
      path.addListener("remove_at", onEdit)
    );
  });

  const onUnmount = useCallback(() => {});

  return isLoaded ? (
    <>
      <div>
        <Skeleton radius="md" visible={variables.skeletonLoading}>
          <BreadCrumb Text="Edit Sites" Title="Geo-fence Sites" />
        </Skeleton>
        <Skeleton radius="md" visible={variables.skeletonLoading}>
          <Card className="border">
            <form
              onSubmit={formEdit.onSubmit((values) => handleUpdate(values))}
            >
              <Grid>
                <Grid.Col md={4} lg={4}>
                  <Grid>
                    <Grid.Col md={6} lg={6}>
                      <Select
                        label="Select Location Category"
                        placeholder="Select Location Category"
                        data={category}
                        searchable
                        required
                        clearable
                        {...formEdit.getInputProps("category_id")}
                      />
                    </Grid.Col>
                    <Grid.Col md={6} lg={6}>
                      <TextInput
                        label="Location name"
                        withAsterisk
                        required
                        value={formEdit.values.location_name}
                        placeholder="Location name"
                        {...formEdit.getInputProps("location_name")}
                      />
                    </Grid.Col>
                    <Grid.Col md={6} lg={6}>
                      <Select
                        label="Status"
                        placeholder="Status"
                        data={[
                          { value: "Active", label: "Active" },
                          { value: "Inactive", label: "Inactive" },
                        ]}
                        required
                        clearable
                        {...formEdit.getInputProps("status")}
                      />
                    </Grid.Col>
                    <Grid.Col md={6} lg={6}>
                      <Select
                        label="Reporting Manager"
                        placeholder="Reporting Manager"
                        data={manager}
                        required
                        clearable
                        {...formEdit.getInputProps("manager_id")}
                      />
                    </Grid.Col>
                    <Grid.Col md={12} lg={12}>
                      <MultiSelect
                        label="Site Executives"
                        placeholder="Site Executives"
                        data={executive}
                        defaultValue={executivelist}
                        required
                        clearButtonProps={{ "aria-label": "Clear selection" }}
                        clearable
                        {...formEdit.getInputProps("executive_id")}
                      />
                    </Grid.Col>
                  </Grid>
                </Grid.Col>
                <Grid.Col md={8} lg={8}>
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={{
                      lat: centerLatitude,
                      lng: centerLongitude,
                    }}
                    zoom={20}
                  >
                    {paths.length === 0 ||
                    paths.length === 1 ||
                    paths.length === 2 ? (
                      <DrawingManager
                        drawingMode={state.drawingMode}
                        editable
                        draggable
                        options={options}
                        onPolygonComplete={onPolygonComplete}
                      />
                    ) : (
                      <Polygon
                        options={{
                          fillColor: "#d70a84",
                          strokeColor: "#59117f",
                          fillOpacity: 0.35,
                          strokeWeight: 2,
                        }}
                        editable
                        path={paths}
                        onMouseUp={onEdit}
                        onDragEnd={onEdit}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                      />
                    )}
                  </GoogleMap>
                </Grid.Col>
                <Grid.Col md={12} lg={12}>
                  <Button
                    fullWidth
                    radius="md"
                    type="submit"
                    size="md"
                    color="zevcore"
                  >
                    Update Geo-fence Site
                  </Button>
                </Grid.Col>
              </Grid>
            </form>
          </Card>
        </Skeleton>
      </div>
    </>
  ) : (
    <></>
  );
}

export default EditSites;
