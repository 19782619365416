/**

    * Application Name: Zevcore unolo Backend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 17.05.2023
    * Last Modified Date: 17.05.2023
    * Developer Name: Anand_Krishna

**/

import React from "react";
import { Navigate } from "react-router-dom";

// Function for protected routes if the user doesnt have a token he ll be redirected to login page
function ProtectedRoute({ children }) {
  const isAuthenticated = localStorage.getItem("isAuthenticated");
  const role = localStorage.getItem("role");
  const location = window.location.href;

  if (isAuthenticated === false || isAuthenticated === null) {
    return <Navigate to="/" state={{ from: location }} replace />;
  } else {
    if (role !== null && role !== "" && typeof role !== "undefined") {
      if (
        location.includes("manager") !== false &&
        role === "Manager" &&
        isAuthenticated === "true"
      ) {
        return children;
      } else if (
        location.includes("admin") !== false &&
        role === "Admin" &&
        isAuthenticated === "true"
      ) {
        return children;
      } else {
        return <Navigate to="/" replace />;
      }
    } else {
      if (
        location.includes("manager") !== false &&
        location.includes("admin") !== false &&
        isAuthenticated === "true"
      ) {
        return children;
      } else {
        return <Navigate to="/" replace />;
      }
    }
  }
}
export default ProtectedRoute;

// function ProtectedRoute({ children }) {

//   const isAuthenticated = localStorage.getItem("isAuthenticated");

//   const location = useLocation();

//   if (isAuthenticated === null) {

//     return <Navigate to="/login" state={{ from: location }} replace />;

//   } else {

//     if (isAuthenticated === "true") {

//       return children;

//     } else {

//       return <Navigate to="/" state={{ from: location }} replace />;

//     }

//   }

// }

// export default ProtectedRoute;
