/**

    * Application Name: Zevcore unolo Backend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 17.05.2023
    * Last Modified Date: 17.05.2023
    * Developer Name: Anand_Krishna

**/

import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/styles/style.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom"; // For import the react dom for navigation components // for import react dom navigation components
import { createBrowserHistory } from "history";

const root = ReactDOM.createRoot(document.getElementById("root"));
const history = createBrowserHistory({ window });
root.render(
  <BrowserRouter history={history}>
    <App />
  </BrowserRouter>
);
