/**

    * Application Name: Zevcore unolo Backend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 17.05.2023
    * Last Modified Date: 17.05.2023
    * Developer Name: Anand_Krishna

**/

import React, { useEffect, useState } from "react";
import {
  ActionIcon,
  Avatar,
  Card,
  Grid,
  Group,
  Loader,
  Skeleton,
  Text,
  Table,
  Anchor,
  Badge,
  Modal,
  useMantineTheme,
  Paper,
  Image,
} from "@mantine/core";
import BreadCrumb from "../../../components/breadcrumbs/BreadCrumb";
import { Eye, ReportAnalytics, Route, Trash } from "tabler-icons-react";
import {
  MeetingImage_Url,
  handleDeleteMeeting,
  handleGetAllData,
} from "./dashboardApi";
import { dataSlice, imageModal } from "../../../services/common";
import { useNavigate } from "react-router-dom";
import { tConvert, toHoursAndMinutes } from "../../../services/url";
import { modals } from "@mantine/modals";

function Dashboard() {
  const [variables, setVariables] = useState({
    skeletonLoading: false,
    submitLoading: false,
    data: [],
    addDrawer: false,
    bulkDrawer: false,
    openEdit: false,
    deleteIndex: 0,
  });
  let navigate = useNavigate();
  const theme = useMantineTheme();
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [activePage, setPage] = useState(1); // For set table active page
  const [total, setTotal] = useState(10); // For set total list show in page
  const [manager, setManager] = useState(0);
  const [sites, setSites] = useState(0);
  const [fieldExecutive, setFieldExecutive] = useState(0);
  const [siteExecutive, setSiteExecutive] = useState(0);

  // Modal Constants

  const [modelUsername, setModalUsername] = useState();
  const [modelSitename, setModalSitename] = useState();
  const [modelStatus, setModalStatus] = useState();
  const [modelStartTime, setModalStartTime] = useState();
  const [modelEndTime, setModalEndTime] = useState();
  const [modelTotalDuration, setModalTotalDuration] = useState();
  const [modelStartImage, setModalStartImage] = useState();
  const [modelEndImage, setModalEndImage] = useState();
  const [modelStartLatitude, setModalStartLatitude] = useState();
  const [modelStartLongitude, setModalStartLongitude] = useState();
  const [modelEndLatitude, setModalEndLatitude] = useState();
  const [modelEndLongitude, setModalEndLongitude] = useState();

  // Modal Constants

  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (mounted) {
        // For get all the tax data list
        const response = await handleGetAllData();
        // On Response setting the data to variable
        if (response.status === 200) {
          setVariables({
            ...variables,
            data: response.data.data[0].reverse(),
            skeletonLoading: false,
          });
          const datas = dataSlice({
            data: response.data.data[0],
            page: 1,
            total: 10,
          });
          setData(datas);
          setManager(response.data.data[1]);
          setFieldExecutive(response.data.data[2]);
          setSiteExecutive(response.data.data[3]);
          setSites(response.data.data[4]);
        }
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  const handleViewModal = (e) => {
    var datas = variables.data.find((img) => img.value === e);
    setModalUsername(datas.user.firstname + datas.user.lastname);
    setModalSitename("");
    setModalStatus(datas.status);
    setModalStartTime(datas.start_time);
    setModalEndTime(datas.end_time);
    setModalTotalDuration("9:00");
    setModalStartImage(datas.image);
    setModalStartLatitude(datas.start_latitude);
    setModalStartLongitude(datas.start_longitude);
    setModalEndLatitude(datas.end_latitude);
    setModalEndLongitude(datas.end_longitude);
    console.log(Number(modelStartLatitude));
    setVariables({ ...variables, deleteIndex: e, viewDrawer: true });
  };

  //For delete confirm modal show Delete
  const openConfirmModalDelete = (e) => {
    var datas = variables.data.find((img) => img.value === e);
    setVariables({ ...variables, deleteIndex: e });
    modals.openConfirmModal({
      title: "Delete Confirmation",
      children: (
        <Text size="sm">
          Are you sure you want to delete the meeting <b>{datas.title}</b> ?
        </Text>
      ),
      labels: { confirm: "Confirm", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => handleConfirmDelete(e),
    });
  };

  //   For delete db data from table and db
  const handleConfirmDelete = async (e) => {
    const response = await handleDeleteMeeting(e);
    // Check the response for notification and actions

    if (response.status === 200) {
      notificationHelper({
        color: "green",
        position: "top-right",
        title: "Success",
        message: "Meeting deleted successfully",
      });
      setVariables({
        ...variables,
        submitLoading: false,
        addDrawer: false,
        data: response.data.data.reverse(),
      });
      const datas = dataSlice({
        data: response.data.data,
        page: activePage,
        total: total,
      });
      setSortedData(datas);
    } else {
      notificationHelper({
        color: "red",
        position: "top-right",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  // Table data arrange by using function and loop throw each data range to table body
  const rows = data.map((row, index) => (
    <tr key={index}>
      <td>{activePage * total - total + index + 1}</td>
      <td style={{ cursor: "pointer" }}>
        <Group spacing="sm">
          {row.image !== "" ? (
            <Avatar
              onClick={() => {
                imageModal({
                  data: MeetingImage_Url + row.image,
                  title: row.title,
                });
              }}
              radius={30}
              size={30}
              src={MeetingImage_Url + row.image}
            />
          ) : (
            <Avatar
              onClick={() => {
                imageModal({
                  data: MeetingImage_Url + row.image,
                  title: row.title,
                });
              }}
              size={20}
              src={MeetingImage_Url + row.image}
            />
          )}

          <div>
            <Text fz="sm" fw={500}>
              <Anchor component="button" size="sm" color="#59117f">
                {row.title}
              </Anchor>
            </Text>
            <Text c="dimmed" size={10}>
              {row.user.firstname} {row.user.lastname}
            </Text>
          </div>
        </Group>
      </td>
      <td>{new Date(row.meeting_date).toLocaleDateString("en-UK")}</td>
      <td>{tConvert(row.start_time).toString()}</td>
      <td>{tConvert(row.end_time).toString()}</td>
      <td>{toHoursAndMinutes(row.total_duration)}</td>
      <td>
        {row.status === "Completed" ? (
          <Badge color="green">{row.status}</Badge>
        ) : (
          <Badge color="orange">{row.status}</Badge>
        )}
      </td>
      <td>
        <Group spacing={0} position="left">
          <ActionIcon onClick={() => handleViewModal(row.value)}>
            <Eye size={18} color="#59117f" />
          </ActionIcon>
          <ActionIcon
            onClick={() =>
              navigate("/admin/field_executive_route/" + row.user.value)
            }
          >
            <Route size={18} color="gray" />
          </ActionIcon>
          <ActionIcon
            onClick={() => openConfirmModalDelete(row.value)}
            color="red"
          >
            <Trash size={18} />
          </ActionIcon>
        </Group>
      </td>
      {/* For action drop down edit and delete the data */}
    </tr>
  ));

  return (
    <div>
      {/* For breadcrumbs */}
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <BreadCrumb Text="Dashboard" />
      </Skeleton>
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <Grid>
          <Grid.Col span={3}>
            <Skeleton radius="md" visible={variables.skeletonLoading}>
              <Card shadow="sm" p="lg" radius="md" withBorder>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ flexDirection: "column", marginTop: 10 }}>
                    <Text size="sm">Total Managers</Text>
                    <div
                      style={{
                        display: "flex",
                        marginTop: 5,
                      }}
                    >
                      <Text size={25} mt={-6}>
                        {manager}
                      </Text>
                    </div>
                  </div>
                  <Avatar color="pink" radius="xl" size={70}>
                    <ReportAnalytics size={36} />
                  </Avatar>
                </div>
              </Card>
            </Skeleton>
          </Grid.Col>
          <Grid.Col span={3}>
            <Skeleton radius="md" visible={variables.skeletonLoading}>
              <Card shadow="sm" p="lg" radius="md" withBorder>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ flexDirection: "column", marginTop: 10 }}>
                    <Text size="sm">Total Sites</Text>
                    <div
                      style={{
                        display: "flex",
                        marginTop: 5,
                      }}
                    >
                      <Text size={25} mt={-6}>
                        {sites}
                      </Text>
                    </div>
                  </div>
                  <Avatar color="pink" radius="xl" size={70}>
                    <ReportAnalytics size={36} />
                  </Avatar>
                </div>
              </Card>
            </Skeleton>
          </Grid.Col>
          <Grid.Col span={3}>
            <Skeleton radius="md" visible={variables.skeletonLoading}>
              <Card shadow="sm" p="lg" radius="md" withBorder>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ flexDirection: "column", marginTop: 10 }}>
                    <Text size="sm">Total Site Executives</Text>
                    <div
                      style={{
                        display: "flex",
                        marginTop: 5,
                      }}
                    >
                      <Text size={25} mt={-6}>
                        {siteExecutive}
                      </Text>
                    </div>
                  </div>
                  <Avatar color="pink" radius="xl" size={70}>
                    <ReportAnalytics size={36} />
                  </Avatar>
                </div>
              </Card>
            </Skeleton>
          </Grid.Col>
          <Grid.Col span={3}>
            <Skeleton radius="md" visible={variables.skeletonLoading}>
              <Card shadow="sm" p="lg" radius="md" withBorder>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ flexDirection: "column", marginTop: 10 }}>
                    <Text size="sm">Total Field Executives</Text>
                    <div
                      style={{
                        display: "flex",
                        marginTop: 5,
                      }}
                    >
                      <Text size={25} mt={-6}>
                        {fieldExecutive}
                      </Text>
                    </div>
                  </div>
                  <Avatar color="pink" radius="xl" size={70}>
                    <ReportAnalytics size={36} />
                  </Avatar>
                </div>
              </Card>
            </Skeleton>
          </Grid.Col>
        </Grid>

        <Grid>
          <Grid.Col span={12}>
            <Skeleton radius="md" visible={variables.skeletonLoading}>
              <Card shadow="sm" p="lg" radius="md" withBorder>
                <Text align="center" mb={10} weight={600}>
                  Latest 10 Meetings List
                </Text>
                <Table verticalSpacing="sm">
                  {/* Table header defines */}
                  <thead>
                    <tr>
                      <th>Sl.No</th>
                      <th>Title</th>
                      <th>Meeting Date</th>
                      <th>Start Time</th>
                      <th>End Time </th>
                      <th>Total Duration </th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  {rows.length > 0 ? (
                    <tbody>{rows}</tbody>
                  ) : (
                    <>
                      {loadingData === true ? (
                        <tbody>
                          <tr>
                            <td colSpan={5}>
                              <div style={{ textAlign: "center" }}>
                                <Loader
                                  size="md"
                                  color="#59117f"
                                  variant="bars"
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td colSpan={5}>
                              <div style={{ textAlign: "center" }}>
                                <Text weight={500} align="center">
                                  Nothing found
                                </Text>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </>
                  )}
                </Table>
              </Card>
            </Skeleton>
          </Grid.Col>
        </Grid>
        {/* meetings Details Modal */}
        <Modal
          opened={variables.viewDrawer}
          onClose={() => setVariables({ ...variables, viewDrawer: false })}
          title={"Meeting Details Modal"}
          size="60%"
          overlayProps={{
            color:
              theme.colorScheme === "dark"
                ? theme.primaryColor[9]
                : theme.primaryColor[2],
            opacity: 0.55,
            blur: 3,
          }}
        >
          <div className="zvcr-scroll">
            <div style={{ padding: "10px" }}>
              <Card>
                <Grid>
                  <Grid.Col md={4} lg={4}>
                    <Paper shadow="xs" p="md">
                      <Text style={{ color: "black", fontSize: 16 }}>
                        Username:{" "}
                      </Text>
                      <Text style={{ color: "gray", fontSize: 14 }}>
                        {modelUsername}
                      </Text>
                    </Paper>
                  </Grid.Col>
                  <Grid.Col md={4} lg={4}>
                    <Paper shadow="xs" p="md">
                      <Text style={{ color: "black", fontSize: 16 }}>
                        Site ID:{" "}
                      </Text>
                      <Text style={{ color: "gray", fontSize: 14 }}>
                        {modelSitename !== "" ? modelSitename : "Not Available"}
                      </Text>
                    </Paper>
                  </Grid.Col>
                  <Grid.Col md={4} lg={4}>
                    <Paper shadow="xs" p="md">
                      <Text style={{ color: "black", fontSize: 16 }}>
                        Status:{" "}
                      </Text>
                      <Text style={{ color: "gray", fontSize: 14 }}>
                        {modelStatus === "Completed" ? (
                          <Badge color="green">{modelStatus}</Badge>
                        ) : (
                          <Badge color="orange">{modelStatus}</Badge>
                        )}
                      </Text>
                    </Paper>
                  </Grid.Col>
                  <Grid.Col md={4} lg={4}>
                    <Paper shadow="xs" p="md">
                      <Text style={{ color: "black", fontSize: 16 }}>
                        Start Time:{" "}
                      </Text>
                      <Text style={{ color: "gray", fontSize: 14 }}>
                        {modelStartTime !== null ? modelStartTime : "--"}
                      </Text>
                    </Paper>
                  </Grid.Col>
                  <Grid.Col md={4} lg={4}>
                    <Paper shadow="xs" p="md">
                      <Text style={{ color: "black", fontSize: 16 }}>
                        End Time:{" "}
                      </Text>
                      <Text style={{ color: "gray", fontSize: 14 }}>
                        {modelEndTime !== null ? modelEndTime : "--"}
                      </Text>
                    </Paper>
                  </Grid.Col>
                  <Grid.Col md={4} lg={4}>
                    <Paper shadow="xs" p="md">
                      <Text style={{ color: "black", fontSize: 16 }}>
                        Total Duration:{" "}
                      </Text>
                      <Text style={{ color: "gray", fontSize: 14 }}>
                        {modelTotalDuration}
                      </Text>
                    </Paper>
                  </Grid.Col>
                  <Grid.Col md={12} lg={12}>
                    <Paper shadow="xs" p="md">
                      <Text style={{ color: "black", fontSize: 16 }}>
                        Meeting Image:{" "}
                      </Text>
                      <Image
                        width={"100%"}
                        height={300}
                        fit="contain"
                        mx="auto"
                        radius="md"
                        src={MeetingImage_Url + modelStartImage}
                        alt="Random image"
                      />
                    </Paper>
                  </Grid.Col>
                </Grid>
              </Card>
            </div>
          </div>
        </Modal>
        {/* meetings Details Modal */}
      </Skeleton>
    </div>
  );
}

export default Dashboard;
