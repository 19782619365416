/**

    * Application Name: Zevcore unolo Backend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 17.05.2023
    * Last Modified Date: 17.05.2023
    * Developer Name: Anand_Krishna

**/

import { url } from "../../../services/url";
import axios from "axios";

// Token
const config = {
  headers: {
    "x-access-token": localStorage.getItem("token"),
  },
};

const fetchCredentials = async () => {
  try {
    // Retrieve the credentials
    const credentials = await localStorage.getItem("token");
    if (credentials) {
      return {
        headers: {
          "x-access-token": credentials,
        },
      };
    } else {
      console.log("No credentials stored");
    }
  } catch (error) {
    console.log("Keychain couldn't be accessed!", error);
  }
  return false;
};

const fieldExecutiveUrl = {
  field_executive_Url: `${url.apiUrl}/field_executive`,
  field_executiveStatus_Url: `${url.apiUrl}/field_executive_status`,
  field_executiveReset_Url: `${url.apiUrl}/field_executive_reset`,
  field_executiveUpload_Url: `${url.apiUrl}/field_executive_upload`,
  sites_Url: `${url.apiUrl}/sites`,
  manager_Url: `${url.apiUrl}/manager`,
};

export const FE_image_Url = `${url.baseUrl}public/images/executives/`;

// For handle get Location Type data list
export const handleGetFieldExecutive = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(
      fieldExecutiveUrl.field_executive_Url,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle get all sites data list
export const handleGetAllSites = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(fieldExecutiveUrl.sites_Url, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle get all manager data list
export const handleGetAllManager = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(fieldExecutiveUrl.manager_Url, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add Location Type data list
export const handleAddFieldExecutive = async (request) => {
  try {
    const config = await fetchCredentials();
    console.log(config);
    const response = await axios.post(
      fieldExecutiveUrl.field_executive_Url,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit Location Type data list
export const handleEditFieldExecutive = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      fieldExecutiveUrl.field_executive_Url,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle field executive image upload
export const handleUploadFieldExecutiveImage = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      fieldExecutiveUrl.field_executiveUpload_Url,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete Location Type data list
export const handleDeleteFieldExecutive = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      fieldExecutiveUrl.field_executive_Url + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle field executive status
export const handleFieldExecutiveStatus = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      fieldExecutiveUrl.field_executiveStatus_Url,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle field executive status
export const handleFieldExecutiveResetDevice = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      fieldExecutiveUrl.field_executiveReset_Url,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
