/**

    * Application Name: Zevcore unolo Backend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 17.05.2023
    * Last Modified Date: 17.05.2023
    * Developer Name: Anand_Krishna

**/

import { url } from "../../../services/url";
import axios from "axios";

export const Manager_image_Url = `${url.baseUrl}public/images/managers/`;

const fetchCredentials = async () => {
  try {
    // Retrieve the credentials
    const credentials = await localStorage.getItem("token");
    if (credentials) {
      return {
        headers: {
          "x-access-token": credentials,
        },
      };
    } else {
      console.log("No credentials stored");
    }
  } catch (error) {
    console.log("Keychain couldn't be accessed!", error);
  }
  return false;
};

const managerUrl = {
  manager_Url: `${url.apiUrl}/manager`,
  managerStatus_Url: `${url.apiUrl}/manager_status`,
  managerUpload_Url: `${url.apiUrl}/manager_upload`,
  site_Url: `${url.apiUrl}/sites`,
};

// For handle get Location Type data list
export const handleGetAllManager = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(managerUrl.manager_Url, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle get Location Type data list
export const handleGetAllSites = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.get(managerUrl.site_Url, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle add Location Type data list
export const handleAddManager = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.post(managerUrl.manager_Url, request, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle edit Location Type data list
export const handleEditManager = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(managerUrl.manager_Url, request, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle manager image upload
export const handleUploadManagerImage = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      managerUrl.managerUpload_Url,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle delete Location Type data list
export const handleDeleteManager = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.delete(
      managerUrl.manager_Url + "/" + request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// For handle manager status
export const handleManagerStatus = async (request) => {
  try {
    const config = await fetchCredentials();
    const response = await axios.patch(
      managerUrl.managerStatus_Url,
      request,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
