import React, { useState, useEffect, useRef, useCallback } from "react";
import GeoMaps from "./GeoMaps";
import {
  Button,
  Card,
  Select,
  MultiSelect,
  Grid,
  Skeleton,
  TextInput,
} from "@mantine/core";
import BreadCrumb from "../../../components/breadcrumbs/BreadCrumb";
import { useForm } from "@mantine/form"; // Mantine form import
import {
  handleAddSite,
  handleGetAllManager,
  handleGetFieldExecutive,
  handleGetLocationType,
} from "./geofencingApi";

import notificationHelper from "../../../services/notification";

function AddSites() {
  const [state, setState] = useState([]);
  const { paths } = state;

  const [variables, setVariables] = useState({
    skeletonLoading: false,
  });

  const [category, setCategory] = useState([]);
  const [executive, setExecutive] = useState([]);
  const [manager, setManager] = useState([]);

  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (mounted) {
        // for fetch location category
        const response = await handleGetLocationType();
        if (response.status === 200) {
          var data = response.data.data;
          var clean = data.map((data) => ({
            value: data.value.toString(),
            label: data.name,
          }));
          setCategory(clean);
        }
        const response1 = await handleGetFieldExecutive();
        if (response1.status === 200) {
          var data = response1.data.data;
          var clean = data.map((data) => ({
            value: data.value.toString(),
            label: data.firstname,
          }));
          setExecutive(clean);
        }
        const response2 = await handleGetAllManager();
        //console.log(response2.data.data);
        if (response2.status === 200) {
          var data = response2.data.data;
          var clean = data.map((data) => ({
            value: data.value.toString(),
            label: data.firstname,
          }));
          setManager(clean);
        }
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  // For form validation
  const form = useForm({
    initialValues: {
      location_name: "",
      category_id: "",
      status: "Active",
      executive_id: [],
      manager_id: "",
      coordinates: "",
    },
    validate: {
      location_name: (value) =>
        value.length < 1 ? "Location name is required" : null,
    },
  });

  const handleAdd = async (e, frm) => {
    setVariables({ ...variables, submitLoading: true });
    const response = await handleAddSite(e);
    // Check for response for actions
    if (response.status === 200) {
      notificationHelper({
        color: "green",
        title: "Success",
        message: "Site added successfully",
      });
      form.reset();
      setVariables({
        ...variables,
        submitLoading: false,
        data: response.data.data,
      });
    } else {
      notificationHelper({
        color: "red",
        title: "Failed! Please enter correct details",
        message: response.data.message,
      });
      setVariables({ ...variables, submitLoading: false });
    }
  };

  const setPoints = useCallback((points) => {
    debugger;
    console.log(points);
    form.setFieldValue("coordinates", points);
  });

  return (
    <div>
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <BreadCrumb Text="Add Sites" Title="Geo-fence Sites" />
      </Skeleton>
      <Skeleton radius="md" visible={variables.skeletonLoading}>
        <Card className="border">
          <form onSubmit={form.onSubmit((values) => handleAdd(values, form))}>
            <Grid>
              <Grid.Col md={4} lg={4}>
                <Grid>
                  <Grid.Col md={6} lg={6}>
                    <Select
                      label="Select Location Category"
                      placeholder="Select Location Category"
                      data={category}
                      searchable
                      required
                      clearable
                      {...form.getInputProps("category_id")}
                    />
                  </Grid.Col>
                  <Grid.Col md={6} lg={6}>
                    <TextInput
                      label="Location name"
                      withAsterisk
                      required
                      value={form.values.location_name}
                      placeholder="Location name"
                      {...form.getInputProps("location_name")}
                    />
                  </Grid.Col>
                  <Grid.Col md={6} lg={6}>
                    <Select
                      label="Status"
                      placeholder="Status"
                      data={[
                        { value: "Active", label: "Active" },
                        { value: "Inactive", label: "Inactive" },
                      ]}
                      required
                      clearable
                      {...form.getInputProps("status")}
                    />
                  </Grid.Col>
                  <Grid.Col md={6} lg={6}>
                    <Select
                      label="Reporting Manager"
                      placeholder="Reporting Manager"
                      data={manager}
                      required
                      clearable
                      {...form.getInputProps("manager_id")}
                    />
                  </Grid.Col>
                  <Grid.Col md={12} lg={12}>
                    <MultiSelect
                      label="Field Executives"
                      placeholder="Field Executives"
                      data={executive}
                      required
                      clearable
                      searchable
                      {...form.getInputProps("executive_id")}
                    />
                  </Grid.Col>
                </Grid>
              </Grid.Col>
              <Grid.Col md={8} lg={8}>
                <Card>
                  <GeoMaps setPoints={setPoints} />
                </Card>
              </Grid.Col>
              <Grid.Col md={12} lg={12}>
                <Button
                  fullWidth
                  radius="md"
                  type="submit"
                  size="md"
                  color="zevcore"
                >
                  Add Geo-fence Site
                </Button>
              </Grid.Col>
            </Grid>
          </form>
        </Card>
      </Skeleton>
    </div>
  );
}

export default AddSites;
