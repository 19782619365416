/**

    * Application Name: Zevcore unolo Backend
    * Application Version: 1.0
    * Author: Zevcore Private Limited
    * Created Date: 17.05.2023
    * Last Modified Date: 17.05.2023
    * Developer Name: Anand_Krishna

**/

import React from "react";
import { Route, Routes } from "react-router-dom"; // for import react dom navigation component
import { Notifications } from "@mantine/notifications";

// Protected Routes
import ProtectedRoute from "./components/protectedRoutes/ProtectedRoute";

// Error Page
import ErrorBoundary from "./components/errors/Error";

// Sidebar
import Sidebar from "./components/sidebar/Sidebar";

// For authentication
import Login from "./pages/auth/Login";
import { ColorSchemeProvider, MantineProvider } from "@mantine/core";

// Admin Pages
import Dashboard from "./pages/adminDashboard/dashboard/Dashboard";
import AddSites from "./pages/adminDashboard/geofencing/AddSites";
import ViewSites from "./pages/adminDashboard/geofencing/ViewSites";
import EditSites from "./pages/adminDashboard/geofencing/EditSites";
import LiveMap from "./pages/adminDashboard/geofencing/LiveMap";
import Profile from "./pages/adminDashboard/settings/Profile";
import NotificationList from "./pages/adminDashboard/settings/NotificationList";
import Password from "./pages/adminDashboard/settings/Password";
import Executives from "./pages/adminDashboard/field_executive/Executives";
import GeneralExecutive from "./pages/adminDashboard/general_executive/GeneralExecutive";
import SiteExecutiveAttendance from "./pages/adminDashboard/attendance/SiteExecutiveAttendance";
import FieldExecutiveAttendance from "./pages/adminDashboard/attendance/FieldExecutiveAttendance";
import DisconnectionLogs from "./pages/adminDashboard/attendance/DisconnectionLogs";
import FieldExecutiveRoute from "./pages/adminDashboard/attendance/FieldExecutiveRoute";
import Meetings from "./pages/adminDashboard/meetings/Meetings";
// Site Attendance Report
import WeeklySiteAttendanceReport from "./pages/adminDashboard/attendanceReports/WeeklySiteAttendanceReport";
import MonthlySiteAttendanceReport from "./pages/adminDashboard/attendanceReports/MonthlySiteAttendanceReport";
import YearlySiteAttendanceReport from "./pages/adminDashboard/attendanceReports/YearlySiteAttendanceReport";
// Site Attendance Report
// Field Attendance Report
import WeeklyFieldAttendanceReport from "./pages/adminDashboard/attendanceReports/WeeklyFieldAttendanceReport";
import MonthlyFieldAttendanceReport from "./pages/adminDashboard/attendanceReports/MonthlyFieldAttendanceReport";
import YearlyFieldAttendanceReport from "./pages/adminDashboard/attendanceReports/YearlyFieldAttendanceReport";
// Field Attendance Report
// Meeting reports
import WeeklyMeetingReport from "./pages/adminDashboard/meetingReports/WeeklyMeetingReport";
import MonthlyMeetingReport from "./pages/adminDashboard/meetingReports/MonthlyMeetingReport";
import YearlyMeetingReport from "./pages/adminDashboard/meetingReports/YearlyMeetingReport";
// Meeting reports
import Manage from "./pages/adminDashboard/settings/Manage";
import LocationType from "./pages/adminDashboard/geofencing/locationType/LocationType";
import Manager from "./pages/adminDashboard/managers/Manager";
import { useLocalStorage } from "@mantine/hooks";

// Manager Pages
import ManagerDashboard from "./pages/managerDashboard/dashboard/ManagerDashboard";
import ManagerAttendance from "./pages/managerDashboard/attendance/ManagerAttendance";
import ManagerMeetings from "./pages/managerDashboard/meetings/ManagerMeetings";
import ManagerGeoMap from "./pages/managerDashboard/geofencing/ManagerGeoMap";
import ManagerProfile from "./pages/managerDashboard/settings/ManagerProfile";
import ManagerSidebar from "./components/sidebar/ManagerSidebar";
import ManagerAttendanceReport from "./pages/managerDashboard/reports/ManagerAttendanceReport";
import ManagerMeetingReport from "./pages/managerDashboard/reports/ManagerMeetingReport";

function App() {
  // For get the dark light toggle
  const toggleColorScheme = (value) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));
  // Get the last mode
  const [colorScheme, setColorScheme] = useLocalStorage({
    key: "mantine-color-scheme",
    defaultValue: "light",
  });
  // Change the color if u want to '#043c64' Carefully wile choosing dark color only
  const [color] = useLocalStorage({
    key: "color",
    defaultValue: "#59117f",
  });

  // For make color lighter by 10 colors level
  const LightenDarkenColor = (col, amt) => {
    var usePound = false;
    if (col[0] === "#") {
      col = col.slice(1);
      usePound = true;
    }
    var num = parseInt(col, 16);
    var r = (num >> 16) + amt;
    if (r > 255) r = 255;
    else if (r < 0) r = 0;
    var b = ((num >> 8) & 0x00ff) + amt;
    if (b > 255) b = 255;
    else if (b < 0) b = 0;
    var g = (num & 0x0000ff) + amt;
    if (g > 255) g = 255;
    else if (g < 0) g = 0;
    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
  };
  return (
    <ColorSchemeProvider
      colorScheme={colorScheme}
      toggleColorScheme={toggleColorScheme}
    >
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        withCSSVariables
        theme={{
          colors: {
            zevcore: [
              LightenDarkenColor(color, 140),
              LightenDarkenColor(color, 130),
              LightenDarkenColor(color, 120),
              LightenDarkenColor(color, 110),
              LightenDarkenColor(color, 100),
              LightenDarkenColor(color, 90),
              LightenDarkenColor(color, 50),
              LightenDarkenColor(color, 80),
              LightenDarkenColor(color, 50),
              LightenDarkenColor(color, 20),
            ],
          },
          primaryColor: "zevcore",
          "::-webkit-scrollbar": {
            backgroundColor: LightenDarkenColor(color, 120),
            width: "5px",
            height: "10px",
            borderRadius: 5,
            fontFamily: "Poppins",
          },
          "::-webkit-scrollbar-thumb": {
            backgroundColor: LightenDarkenColor(color, 120),
            borderRadius: 5,
            // "#D50000"
          },
          fontFamily: "Poppins",
          fontFamilyMonospace: "Poppins",
          headings: { fontFamily: "Poppins" },
          fontWeight: 200,
          colorScheme,
        }}
      >
        <Notifications position="top-right" autoClose={3000} />
        <ErrorBoundary>
          <Routes>
            <Route path="/" element={<Login />} />
            {/* Admin Routes */}
            <Route element={<Sidebar />}>
              <Route
                exact
                path="/admin/Dashboard"
                element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/admin/location_category"
                element={
                  <ProtectedRoute>
                    <LocationType />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/admin/add_sites"
                element={
                  <ProtectedRoute>
                    <AddSites />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/admin/view_sites"
                element={
                  <ProtectedRoute>
                    <ViewSites />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/admin/edit_sites/:value"
                element={
                  <ProtectedRoute>
                    <EditSites />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/admin/live_sites/:value"
                element={
                  <ProtectedRoute>
                    <LiveMap />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/admin/profile"
                element={
                  <ProtectedRoute>
                    <Profile />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/admin/notification_lists"
                element={
                  <ProtectedRoute>
                    <NotificationList />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/admin/update_password"
                element={
                  <ProtectedRoute>
                    <Password />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/admin/executives"
                element={
                  <ProtectedRoute>
                    <Executives />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/admin/general_executives"
                element={
                  <ProtectedRoute>
                    <GeneralExecutive />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/admin/managers"
                element={
                  <ProtectedRoute>
                    <Manager />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/admin/site_executive_attendance"
                element={
                  <ProtectedRoute>
                    <SiteExecutiveAttendance />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/admin/field_executive_attendance"
                element={
                  <ProtectedRoute>
                    <FieldExecutiveAttendance />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/admin/field_executive_route/:value"
                element={
                  <ProtectedRoute>
                    <FieldExecutiveRoute />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/admin/disconnected_logs/:value"
                element={
                  <ProtectedRoute>
                    <DisconnectionLogs />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/admin/meetings"
                element={
                  <ProtectedRoute>
                    <Meetings />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/admin/weekly_site_attendance_report"
                element={
                  <ProtectedRoute>
                    <WeeklySiteAttendanceReport />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/admin/monthly_site_attendance_report"
                element={
                  <ProtectedRoute>
                    <MonthlySiteAttendanceReport />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/admin/yearly_site_attendance_report"
                element={
                  <ProtectedRoute>
                    <YearlySiteAttendanceReport />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/admin/weekly_field_attendance_report"
                element={
                  <ProtectedRoute>
                    <WeeklyFieldAttendanceReport />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/admin/monthly_field_attendance_report"
                element={
                  <ProtectedRoute>
                    <MonthlyFieldAttendanceReport />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/admin/yearly_field_attendance_report"
                element={
                  <ProtectedRoute>
                    <YearlyFieldAttendanceReport />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/admin/weekly_meetings_report"
                element={
                  <ProtectedRoute>
                    <WeeklyMeetingReport />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/admin/monthly_meetings_report"
                element={
                  <ProtectedRoute>
                    <MonthlyMeetingReport />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/admin/yearly_meetings_report"
                element={
                  <ProtectedRoute>
                    <YearlyMeetingReport />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/admin/settings"
                element={
                  <ProtectedRoute>
                    <Manage />
                  </ProtectedRoute>
                }
              />
            </Route>
            {/* Admin Routes */}
            {/* Manager Routes */}
            <Route element={<ManagerSidebar />}>
              <Route
                exact
                path="/manager/Dashboard"
                element={
                  <ProtectedRoute>
                    <ManagerDashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/manager/Attendance"
                element={
                  <ProtectedRoute>
                    <ManagerAttendance />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/manager/view_map"
                element={
                  <ProtectedRoute>
                    <ManagerGeoMap />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/manager/attendance_report"
                element={
                  <ProtectedRoute>
                    <ManagerAttendanceReport />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/manager/meetings_report"
                element={
                  <ProtectedRoute>
                    <ManagerMeetingReport />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/manager/Profile"
                element={
                  <ProtectedRoute>
                    <ManagerProfile />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/manager/Meetings"
                element={
                  <ProtectedRoute>
                    <ManagerMeetings />
                  </ProtectedRoute>
                }
              />
            </Route>
            {/* Manager Routes */}
          </Routes>
        </ErrorBoundary>
      </MantineProvider>
    </ColorSchemeProvider>
  );
}

export default App;
