import { Skeleton } from "@mantine/core";
import React, { useState, useCallback, useRef, useEffect } from "react";
import ManagerBreadCrumb from "../../../components/breadcrumbs/ManagerBreadCrumb";
import {
  DrawingManager,
  GoogleMap,
  Marker,
  Polygon,
  useJsApiLoader,
} from "@react-google-maps/api";
import { handleGetManagerSite } from "./GeoMapApi";

const containerStyle = {
  width: "100%",
  height: "800px",
};

const libraries = ["places", "drawing"];

function ManagerGeoMap() {
  const [variables, setVariables] = useState({
    skeletonLoading: false,
    submitLoading: false,
  });

  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      const response = await handleGetManagerSite(2);
      console.log(response.data);

      // setCategory(datas.location_category.name);
      // setSiteName(datas.name);
      // setManager(datas.manager.firstname);
      // setExecutivelist(datas.site_executives);
      // let paths = [];
      // datas.site_coordinates.forEach(function (path) {
      //   paths.push({
      //     lat: parseFloat(path.latitude),
      //     lng: parseFloat(path.longitude),
      //   });
      // });
      // setPaths(paths);
      // console.log(paths);
    };
    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBMWoaIEUJybmZFkVn7poh96I0d0XhNN4A",
    libraries,
  });

  const [path, setPath] = useState(0);
  const [point, setPoint] = useState();
  const [coordinates, setCoordinates] = useState([
    { lat: 12.348180799736632, lng: 76.63000917420501 },
    { lat: 12.348585613993674, lng: 76.62989383921737 },
    { lat: 12.348782780581438, lng: 76.62984690055961 },
    { lat: 12.348990427624555, lng: 76.629826783992 },
    { lat: 12.34902448958444, lng: 76.62994211897964 },
    { lat: 12.349027109734926, lng: 76.63007354722137 },
    { lat: 12.348686489940851, lng: 76.63031896934623 },
    { lat: 12.34830394718206, lng: 76.63050538287277 },
    { lat: 12.348229272674208, lng: 76.63026800737495 },
  ]);
  const [state, setState] = useState({
    drawingMode: "polygon",
  });

  const [center, setCenter] = useState({
    lat: 12.3484343,
    lng: 76.6301862,
  });

  const options = {
    drawingControl: true,
    drawingControlOptions: {
      drawingMode: ["Polygon"],
    },
    PolygonOptions: {
      strokeColor: "#59117f",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#d70a84",
      fillOpacity: 0.35,
      clickable: true,
      draggable: true,
      editable: true,
      zIndex: 1,
    },
  };

  const onPolygonComplete = React.useCallback(function onPolygonComplete(poly) {
    const polyArray = poly.getPath().getArray();
    let paths = [];
    polyArray.forEach(function (path) {
      paths.push({ lat: path.lat(), lng: path.lng() });
    });
    setPath(paths);
    console.log(paths);
    setCoordinates(paths);
    point(path);
    poly.setMap(null);
  });

  // Define refs for polygon instance and listeners
  const polygonRef = useRef(null);
  const listenerRef = useRef([]);
  const autocompleteRef = useRef();
  const [searchResult, setSearchResult] = useState("");

  // Call set path with new edited paths
  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map((latlng) => {
          return { lat: latlng.lat(), lng: latlng.lng() };
        });
      console.log(nextPath);
      setPath(nextPath);
      point(nextPath);
    }
  });

  const onLoad = useCallback((polygon) => {
    polygonRef.current = polygon;
    const path = polygon.getPath();
    listenerRef.current.push(
      path.addListener("set_at", onEdit),
      path.addListener("insert_at", onEdit),
      path.addListener("remove_at", onEdit)
    );
  });

  const onUnmount = useCallback(() => {});

  return (
    <div>
      <Skeleton
        height="100%"
        width="100%"
        radius="md"
        visible={variables.skeletonLoading}
      >
        <ManagerBreadCrumb Text="Geo Map" titleLink="/manager/GeoMap" />
      </Skeleton>

      <Skeleton visible={variables.skeletonLoading}>
        {isLoaded ? (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={18}
          >
            {coordinates.length === 0 ||
            coordinates.length === 1 ||
            coordinates.length === 2 ? (
              <DrawingManager
                drawingMode={state.drawingMode}
                editable
                draggable
                options={options}
                onPolygonComplete={onPolygonComplete}
              />
            ) : (
              <Polygon
                options={{
                  fillColor: "#d70a84",
                  strokeColor: "#59117f",
                  fillOpacity: 0.35,
                  strokeWeight: 2,
                }}
                editable
                path={coordinates}
                onMouseUp={onEdit}
                onDragEnd={onEdit}
                onLoad={onLoad}
                onUnmount={onUnmount}
              />
            )}
            <Marker co opacity={0.8} position={center} />
          </GoogleMap>
        ) : null}
      </Skeleton>
    </div>
  );
}

export default ManagerGeoMap;
